unit PzsDatenmodul;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, WEBLib.Dialogs, XData.Web.Client,
  WEBLib.StdCtrls, Bcl.Utils,  CP.Func.AES, Data.DB, XData.Web.JsonDataset, XData.Web.Dataset;

type
  TdmPzs = class(TDataModule)
    XDataWebClient: TXDataWebClient;
    tabAnwAbw: TXDataWebDataSet;
    tabAnwAbwANWABW_NR: TIntegerField;
    tabAnwAbwANWABW_BEZ: TStringField;
    tabAnwAbwANWABW_TYP: TIntegerField;
    tabAnwAbwANWABW_KURZ: TStringField;
    tabAnwAbwANWABW_FARBE: TStringField;
    procedure WebDataModuleCreate(Sender: TObject);

  private

  public
    function  Get_AnwAbw_Bez( aID : Integer ) : String;
    [async] procedure SetInCbx_Zeitmodell( aCbx : TComboBox; aID : String ) ; async;
    procedure Clear_CBX_und_Objekte( aComboBox : TComboBox );
    [async] function  Fill_AnwAbw( aCbx : TComboBox;  bNurStempel : Boolean ) : String ; async;
    [async] procedure FillCBX_Mit_AnwAbw( aCbx : TComboBox; bNurStempel : Boolean ); async;
    [async] procedure FillCbx_Mit_Zeitmodell( aCbx : TComboBox ) ; async;

    function Test : String ;
  protected procedure LoadDFMValues; override; end;


var dmPzs: TdmPzs;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

uses UnitMain, UnitLogin ;

{$R *.dfm}

//================================================================================================//

function TdmPzs.Test : String ;
begin
  Showmessage('in TdmPzs');
end;

procedure TdmPzs.WebDataModuleCreate(Sender: TObject);
begin
  XDataWebClient.Connection := frmMainForm.XDataConn;
  tabAnwAbw.Connection := frmMainForm.XDataConn;
end;

//================================================================================================//

procedure TdmPzs.Clear_CBX_und_Objekte( aComboBox : TComboBox );
var i : Integer ;
    aObj : TObject;
begin

  For i := 0 to aComboBox.Items.Count-1 Do
    Begin
      aObj := TObject( aComboBox.Items.Objects[i]) ;
      aObj.Free;
    End;
  aComboBox.Items.Clear ;

end;


//================================================================================================//

function TdmPzs.Fill_AnwAbw( aCbx : TComboBox;  bNurStempel : Boolean ) : String ;
var sSQL, sDaten, sTmp : String;
     i : Integer;
     Blob: JSValue;
     aSL : TStringList;
     aArray : TArray<string>;
     Response: TXDataClientResponse;
begin

//  frmMainForm.LogAction('Start - TdmPzs.AnwAbw_Laden',False);
//
//  sSQL := 'SELECT ANWABW_NR, ANWABW_BEZ, ANWABW_TYP, ANWABW_ZEICHEN, ANWABW_FARBE '
//        +' FROM ANWABW ORDER BY ANWABW_BEZ ';
//
//  XDataWebClient.Connection := frmMainForm.XDataConn;
//
//  Response := await( XDataWebClient.RawInvokeAsync('ISystemService.GetDaten', [ sSQL, 'CSV',0,0 ] ) );
//
//  Blob := Response.Result;
//  asm sDaten = await Blob.text(); end;
//
//  //------------------------ Daten jetzt noch entschlüsseln
//  frmMainForm.edtTemp.Text := sDaten;
//
//
//  TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(frmMainForm.edtTemp.Text),
//     procedure(const AEncrypted: string)
//      begin
//
//        aSL := TStringList.Create;
//        aSL.Text := AEncrypted; // mLog.Lines.Text;
//
//        tabAnwAbw.Close;
//        tabAnwAbw.Open;
//
//        For i := 2 to aSL.Count -1 Do
//            Begin
//             sTmp := aSL[i];
//              // If i=1 then frmMainForm.LogAction( IntToStr(i)+'..lade...:'+sTmp,False);
//              aArray := sTmp.Split([';']);
//              tabAnwAbw.Append;
//              tabAnwAbw.FieldByName('ANWABW_NR').AsInteger   := StrToIntDef(aArray[0],0);
//              tabAnwAbw.FieldByName('ANWABW_BEZ').AsString   := aArray[1];
//              tabAnwAbw.FieldByName('ANWABW_TYP').AsInteger  := StrToIntDef(aArray[2],0);
//              tabAnwAbw.FieldByName('ANWABW_KURZ').AsString  := aArray[3];
//              tabAnwAbw.FieldByName('ANWABW_FARBE').AsString := aArray[4];
//              tabAnwAbw.Post;
//            End;
//
//        tabAnwAbw.First;
//        aSL.Free;
//
//        frmMainForm.LogAction('Ende  - TdmPzs.AnwAbw_Laden',False);
//
//        FillCBX_Mit_AnwAbw( aCbx, bNurStempel );
//
//      end,
//    procedure(const AError: string)
//     begin
//      ShowMessage(AError);
//     end );

end;

//================================================================================================//

procedure TdmPzs.FillCBX_Mit_AnwAbw( aCbx : TComboBox; bNurStempel : Boolean );
var //aAbw : TAbw;
    iAbwNr : Integer;
begin

//  frmMainForm.LogAction( 'Start TdmPzs.FillCBX_Mit_AnwAbw '+aCbx.Name, False );
//
//
//  If not tabAnwAbw.Active then
//    Begin
//      frmMainForm.LogAction( '     Tab nicht offen FillCBX_Mit_AnwAbw '+aCbx.Name, False );
//      Exit;
//    End;
//
//  Clear_CBX_und_Objekte( aCbx );
//
//  If bNurstempel then
//    Begin
//      If tabAnwAbw.Locate('ANWABW_NR',3,[]) then
//        aCbx.Items.Add( tabAnwAbw.FieldByName('ANWABW_BEZ').AsString  );
//
//      If tabAnwAbw.Locate('ANWABW_NR',1,[]) then
//        aCbx.Items.Add( tabAnwAbw.FieldByName('ANWABW_BEZ').AsString  );
//
//      If tabAnwAbw.Locate('ANWABW_NR',2,[]) then
//        aCbx.Items.Add( tabAnwAbw.FieldByName('ANWABW_BEZ').AsString  );
//
//      If tabAnwAbw.Locate('ANWABW_NR',4,[]) then
//        aCbx.Items.Add( tabAnwAbw.FieldByName('ANWABW_BEZ').AsString  );
//    End
//  Else
//    Begin
//       tabAnwAbw.First;
//       While not tabAnwAbw.Eof Do        //1,2,3,4,6,103
//         Begin
//           iAbwNr := tabAnwAbw.FieldByName('ANWABW_NR').AsInteger;
//           If not (iAbwNr in [1,2,3,4,6,103] ) then
//             Begin
//               aAbw := TAbw.Create;
//               aAbw.AbwNr    := iAbwNr;
//               aAbw.AbwLang  := tabAnwAbw.FieldByName('ANWABW_BEZ').AsString;
//               aAbw.AbwKurz  := tabAnwAbw.FieldByName('ANWABW_KURZ').AsString;
//               aAbw.AbwFarbe := tabAnwAbw.FieldByName('ANWABW_FARBE').AsString;
//               aCbx.Items.AddObject(aAbw.AbwLang, aAbw );
//             End;
//           tabAnwAbw.Next;
//         End;
//
//    End;
//
//  If aCbx.Items.Count > 0 then aCbx.Itemindex := 0;
//
//  frmMainForm.LogAction('Ende TdmPzs.FillCBX_Mit_AnwAbw '+aCbx.Name, False);

end;

//================================================================================================//

function TdmPzs.Get_AnwAbw_Bez( aID : Integer ) : String;
begin

  Result := '';

  If tabAnwAbw.Locate('ANWABW_NR', aID, [] ) then
   Result := tabAnwAbw.FieldByName('ANWABW_BEZ').AsString;

end;


//================================================================================================//

procedure TdmPzs.SetInCbx_Zeitmodell( aCbx : TComboBox; aID : String ) ;
var i : Integer;
begin

  //Showmessage( 'SetIn...Anz: '+IntToStr(aCbx.Items.Count) );

//  For i := 0 to aCbx.Items.Count-1 do
//   If TWebCoreObj_ZM( aCbx.Items.Objects[i]).WMOD_NUMMER = aID then
//    Begin
//
//      aCbx.ItemIndex := i ;
//      Break ;
//    End;
//
//  If (aCbx.ItemIndex = -1)  and (aCbx.Items.Count > 0) then aCbx.ItemIndex := 0 ;

end;

//================================================================================================//

procedure TdmPzs.FillCbx_Mit_Zeitmodell( aCbx : TComboBox ) ;
var sSQL, sDaten, sTmp : String;
     i : Integer;
     Blob: JSValue;
     aSL : TStringList;
     aArray : TArray<string>;
     Response: TXDataClientResponse;
//     aObj : TWebCoreObj_ZM;
begin

//  Clear_CBX_und_Objekte( aCbx );
//
//  frmMainForm.LogAction('Start - FillCBX_Mit_Zeitmodell',False);
//
//  sSQL := 'SELECT WMOD_NUMMER, WMOD_NAME FROM WMOD_DAT WHERE FIR_MAND=1 AND IIF(DEAKTIV IS NULL, 0, DEAKTIV)=0 '
//         +' ORDER BY WMOD_NAME ';
//  Response := await( XDataWebClient.RawInvokeAsync('ISystemService.GetDaten', [ sSQL, 'CSV',0,0 ] ) );
//
//  Blob := Response.Result;
//  asm sDaten = await Blob.text(); end;
//
//  //------------------------ Daten jetzt noch entschlüsseln
//  frmMainForm.edtTemp.Text := sDaten;
//
//  TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(frmMainForm.edtTemp.Text),
//     procedure(const AEncrypted: string)
//      begin
//
//        aSL := TStringList.Create;
//        aSL.Text := AEncrypted;
//        For i := 2 to aSL.Count -1 Do
//            Begin
//             sTmp := aSL[i];
//              aArray := sTmp.Split([';']);
//              aObj := TWebCoreObj_ZM.Create;
//              aObj.WMOD_NUMMER := aArray[0];
//              aObj.WMOD_NAME   := aArray[1];
//              aCbx.Items.AddObject(aObj.WMOD_NAME, aObj );
//            End;
//        aSL.Free;
//        frmMainForm.LogAction('Ende  - FillCBX_Mit_Zeitmodell',False);
//      end,
//    procedure(const AError: string)
//     begin
//      ShowMessage(AError);
//     end );

end;


//================================================================================================//



procedure TdmPzs.LoadDFMValues;
begin
  inherited LoadDFMValues;

  XDataWebClient := TXDataWebClient.Create(Self);
  tabAnwAbw := TXDataWebDataSet.Create(Self);
  tabAnwAbwANWABW_NR := TIntegerField.Create(Self);
  tabAnwAbwANWABW_BEZ := TStringField.Create(Self);
  tabAnwAbwANWABW_TYP := TIntegerField.Create(Self);
  tabAnwAbwANWABW_KURZ := TStringField.Create(Self);
  tabAnwAbwANWABW_FARBE := TStringField.Create(Self);

  XDataWebClient.BeforeLoadDFMValues;
  tabAnwAbw.BeforeLoadDFMValues;
  tabAnwAbwANWABW_NR.BeforeLoadDFMValues;
  tabAnwAbwANWABW_BEZ.BeforeLoadDFMValues;
  tabAnwAbwANWABW_TYP.BeforeLoadDFMValues;
  tabAnwAbwANWABW_KURZ.BeforeLoadDFMValues;
  tabAnwAbwANWABW_FARBE.BeforeLoadDFMValues;
  try
    Name := 'dmPzs';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    Height := 374;
    Width := 501;
    XDataWebClient.SetParentComponent(Self);
    XDataWebClient.Name := 'XDataWebClient';
    XDataWebClient.Left := 116;
    XDataWebClient.Top := 80;
    tabAnwAbw.SetParentComponent(Self);
    tabAnwAbw.Name := 'tabAnwAbw';
    tabAnwAbw.EntitySetName := 'ANWABW';
    tabAnwAbw.Left := 232;
    tabAnwAbw.Top := 80;
    tabAnwAbwANWABW_NR.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_NR.Name := 'tabAnwAbwANWABW_NR';
    tabAnwAbwANWABW_NR.FieldName := 'ANWABW_NR';
    tabAnwAbwANWABW_BEZ.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_BEZ.Name := 'tabAnwAbwANWABW_BEZ';
    tabAnwAbwANWABW_BEZ.FieldName := 'ANWABW_BEZ';
    tabAnwAbwANWABW_TYP.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_TYP.Name := 'tabAnwAbwANWABW_TYP';
    tabAnwAbwANWABW_TYP.FieldName := 'ANWABW_TYP';
    tabAnwAbwANWABW_KURZ.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_KURZ.Name := 'tabAnwAbwANWABW_KURZ';
    tabAnwAbwANWABW_KURZ.FieldName := 'ANWABW_KURZ';
    tabAnwAbwANWABW_KURZ.Size := 5;
    tabAnwAbwANWABW_FARBE.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_FARBE.Name := 'tabAnwAbwANWABW_FARBE';
    tabAnwAbwANWABW_FARBE.FieldName := 'ANWABW_FARBE';
    tabAnwAbwANWABW_FARBE.Size := 30;
  finally
    XDataWebClient.AfterLoadDFMValues;
    tabAnwAbw.AfterLoadDFMValues;
    tabAnwAbwANWABW_NR.AfterLoadDFMValues;
    tabAnwAbwANWABW_BEZ.AfterLoadDFMValues;
    tabAnwAbwANWABW_TYP.AfterLoadDFMValues;
    tabAnwAbwANWABW_KURZ.AfterLoadDFMValues;
    tabAnwAbwANWABW_FARBE.AfterLoadDFMValues;
  end;
end;

end.

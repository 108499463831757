unit CP.Func.AES;

interface

uses
  System.SysUtils, WEBLib.Utils;

type
  TAESSuccessBinProc = reference to procedure(const ABytes: TBytes);
  TAESSuccessProc = reference to procedure(const AData: string);

  TAESFunc = record
  strict private const
    cBlockMinSize = 16;
    cSuffix = ' b';
  strict private
    class function CalculateKey(const AKey: string): string; static;
{$IFDEF WEBLIB}class function CalculateKeyWeb(const AKey: string): string; static;{$ENDIF}
  public
{$IFNDEF WEBLIB}class function Decrypt(const AKey: string; AValue: string): string; static;{$ENDIF}
{$IFDEF WEBLIB}class procedure Decrypt(const AKey: string; const AValue: TBytes; const ASuccess: TAESSuccessProc; const AError: TProcString); overload; static;{$ENDIF}
{$IFNDEF WEBLIB}class function Encrypt(const AKey, AValue: string): string; overload; static;{$ENDIF}
{$IFDEF WEBLIB}class procedure Encrypt(const AKey: string; const AValue: string; const ASuccess: TAESSuccessBinProc; const AError: TProcString); overload; static;{$ENDIF}
  end;

implementation

uses
{$IFDEF WEBLIB}
  WEBLib.Crypto, Web, JS, Bcl.Utils,
{$ELSE}
  MiscObj, AESObj,
{$ENDIF}
  System.Classes;

{$IFDEF WEBLIB}
type
  TAESEvents = class(TObject)
  strict private
    FAES: TAESEncryption;
    FData: TJSArrayBuffer;
    FEncrypted: TAESSuccessBinProc;
    FError: TProcString;
    FKey: string;
    FDecrypted: TAESSuccessProc;
    FValue: string;
  strict protected
    procedure OnError(AError: string);
    procedure OnEncrypted(ABuffer: TJSArrayBuffer);
    procedure OnKeyCreated(ASender: TObject);
    procedure OnKeyImported(ASender: TObject);
    procedure OnDecrypted(AData: string);
  public
    constructor Create(const AAES: TAESEncryption; const ASuccess: TAESSuccessProc; const AError: TProcString; const AKey: string; const AData: TJSArrayBuffer); overload;
    constructor Create(const AAES: TAESEncryption; const ASuccess: TAESSuccessBinProc; const AError: TProcString; const AKey: string; const AValue: string); overload;
  end;

{ TAESEvents }

constructor TAESEvents.Create(const AAES: TAESEncryption; const ASuccess: TAESSuccessProc; const AError: TProcString; const AKey: string; const AData: TJSArrayBuffer);
begin
  inherited Create;
  FAES := AAES;
  FData := AData;
  FError := AError;
  FKey := AKey;
  FDecrypted := ASuccess;
  FAES.OnError := OnError;
  FAES.OnDecryptedString := OnDecrypted;
  FAES.OnKeyCreated := OnKeyCreated;
  FAES.OnKeyImported := OnKeyImported;
end;

constructor TAESEvents.Create(const AAES: TAESEncryption; const ASuccess: TAESSuccessBinProc; const AError: TProcString; const AKey: string; const AValue: string);
begin
  inherited Create;
  FAES := AAES;
  FValue := AValue;
  FError := AError;
  FKey := AKey;
  FEncrypted := ASuccess;
  FAES.OnError := OnError;
  FAES.OnEncrypted := OnEncrypted;
  FAES.OnKeyCreated := OnKeyCreated;
  FAES.OnKeyImported := OnKeyImported;
end;

procedure TAESEvents.OnError(AError: string);
begin
  if Assigned(FError) then
    FError(AError);
end;

procedure TAESEvents.OnKeyCreated(ASender: TObject);
begin
  FAES.ImportKey(FKey);
end;

procedure TAESEvents.OnKeyImported(ASender: TObject);
begin
  if Assigned(FData) then
    FAES.Decrypt(FData, drtString)
  else
    FAES.Encrypt(FValue);
end;

procedure TAESEvents.OnDecrypted(AData: string);
begin
  if Assigned(FDecrypted) then
    FDecrypted(AData);
end;

procedure TAESEvents.OnEncrypted(ABuffer: TJSArrayBuffer);
begin
  if Assigned(FEncrypted) then
    FEncrypted(TCustomMemoryStream.MemoryToBytes(ABuffer));
end;

{$ENDIF}

{ TAESFunc }

class function TAESFunc.CalculateKey(const AKey: string): string;
const
  cKeyLength = 256 div 8;
  cSpace = ' ';
begin
  Result := AKey;
  while Result.Length < cKeyLength do
    Result := Result + cSpace;

  if Result.Length > cKeyLength then
    Result := Result.Substring(0, cKeyLength);
end;

{$IFDEF WEBLIB}class function TAESFunc.CalculateKeyWeb(const AKey: string): string;
const
  cKey ='{"alg":"A256CBC","ext":true,"k":"%s","key_ops":["encrypt", "decrypt"],"kty":"oct"}';
begin
  Result := TBclUtils.EncodeBase64Url(BytesOf(CalculateKey(AKey + cSuffix)));
  Result := string.Format(cKey, [Result]);
end;{$ENDIF}

{$IFNDEF WEBLIB}class function TAESFunc.Decrypt(const AKey: string; AValue: string): string;
var
  lAES: TAESEncryption;
begin
  lAES := TAESEncryption.Create;
  try
    lAES.AType := atCBC;
    lAES.KeyLength := kl256;
    lAES.Key := CalculateKey(AKey + cSuffix);
    lAES.PaddingMode := TPaddingMode.PKCS7;
    lAES.IVMode := TIVMode.rand;
    lAES.outputFormat := TConvertType.base64url;

    Result := lAES.Decrypt(AValue);
  finally
    lAES.Free;
  end;
end;{$ENDIF}

{$IFDEF WEBLIB}class procedure TAESFunc.Decrypt(const AKey: string; const AValue: TBytes; const ASuccess: TAESSuccessProc; const AError: TProcString);
var
  lAES: TAESEncryption;
begin
  lAES := TAESEncryption.Create(aetCBC, akl256);
  TAESEvents.Create(lAES, ASuccess, AError, CalculateKeyWeb(AKey), TCustomMemoryStream.BytesToMemory(AValue));
end;{$ENDIF}

{$IFNDEF WEBLIB}class function TAESFunc.Encrypt(const AKey, AValue: string): string;
var
  lAES: TAESEncryption;
begin
  lAES := TAESEncryption.Create;
  try
    lAES.AType := atCBC;
    lAES.KeyLength := kl256;
    lAES.Key := CalculateKey(AKey + cSuffix);
    lAES.PaddingMode := TPaddingMode.PKCS7;
    lAES.IVMode := TIVMode.rand;
    lAES.outputFormat := TConvertType.base64url;
    Result := lAES.Encrypt(AValue);
  finally
    lAES.Free;
  end;
end;{$ENDIF}

{$IFDEF WEBLIB}class procedure TAESFunc.Encrypt(const AKey: string; const AValue: string; const ASuccess: TAESSuccessBinProc; const AError: TProcString);
var
  lAES: TAESEncryption;
begin
  lAES := TAESEncryption.Create(aetCBC, akl256);
  TAESEvents.Create(lAES, ASuccess, AError, CalculateKeyWeb(AKey), AValue);
end;{$ENDIF}

end.

program SpediFucs;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  UnitMain in 'units\UnitMain.pas'{*.html},
  UnitLogin in 'units\UnitLogin.pas'{*.html},
  Tau in 'units\Tau.pas'{*.html},
  CP.Func.AES in '..\WebServer\CP.Func.AES.pas',
  Mitarbeiter in 'units\Mitarbeiter.pas',
  MitarbeiterEdit in 'units\MitarbeiterEdit.pas',
  AbwVerwaltung in 'units\AbwVerwaltung.pas'{*.html},
  PzsDatenmodul in 'units\PzsDatenmodul.pas',
  Buchungen in 'units\Buchungen.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TfrmMainForm, frmMainForm);
  Application.CreateForm(TfrmBuchungen, frmBuchungen);
  Application.Run;

end.

unit UnitMain;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.WebCtrls, System.DateUtils, WEBLib.ExtCtrls, XData.Web.Connection,
  XData.Web.Client, jsdelphisystem, WEBLib.Storage,  Bcl.Utils, CP.Func.AES,
  WEBLib.REST, WEBLib.JSON, Vcl.Menus, WEBLib.Menus, VCL.TMSFNCButton, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset;

type
  TfrmMainForm = class(TForm)
    XDataConn: TXDataWebConnection;
    tmrJWTRenewal: TTimer;
    tmrJWTRenewalWarning: TTimer;
    WebHttpRequest1: THttpRequest;
    tmrCapture: TTimer;
    pnlMenu: TPanel;
    pnlMain: TPanel;
    mMainMenu: TMainMenu;
    mnStammdaten: TMenuItem;
    mnMitarbeiter: TMenuItem;
    mnVerwaltung: TMenuItem;
    mnSystem: TMenuItem;
    Abmelden1: TMenuItem;
    Passwortndern1: TMenuItem;
    N1: TMenuItem;
    pnlStatus: TPanel;
    lbBenutzer: TLabel;
    pnlLog: TPanel;
    mLog: TMemo;
    btnShowLog: TButton;
    mnBuchungen: TMenuItem;
    lbWebTitel: TLabel;
    XDataWebClient: TXDataWebClient;
    edtTemp: TEdit;
    Loganzeigen1: TMenuItem;
    WebSplitter1: TSplitter;
    tabAnwAbw: TXDataWebDataSet;
    tabAnwAbwANWABW_NR: TIntegerField;
    tabAnwAbwANWABW_BEZ: TStringField;
    tabAnwAbwANWABW_TYP: TIntegerField;
    tabAnwAbwANWABW_KURZ: TStringField;
    tabAnwAbwANWABW_FARBE: TStringField;
    mnTau: TMenuItem;
    WebButton1: TButton;

    procedure btnShowLogClick(Sender: TObject);
    procedure XDataConnRequest(Args: TXDataWebConnectionRequest);
    procedure Toast(Header: String; Body: String; Timeout: Integer);
    procedure ProcessJWT(aJWT: String);
    procedure WebFormKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure tmrJWTRenewalWarningTimer(Sender: TObject);
    procedure WebFormClick(Sender: TObject);
    procedure FinalRequest;

    [async] procedure LogAction(Action: String; Extend: Boolean); async;
    [async] procedure WebFormCreate(Sender: TObject); async;
    [async] procedure Logout(Reason: String); async;
    [async] procedure LoadForm(Form: String); async;
    [async] procedure LoadSubForm(SubForm: String; NewInstance: Boolean); async;
    [async] procedure XDataConnect; async;
    [async] procedure tmrJWTRenewalTimer(Sender: TObject); async;
    [async] function XDataLogin(Username: String; Password: String):String; async;
    [async] function JSONRequest(Endpoint: String; Params: Array of JSValue):String; async;

    //procedure Clear_CBX_und_Objekte( aComboBox : TComboBox );
    //function Get_AnwAbw_Bez( aID : Integer ) : String;


    procedure WebFormHashChange(Sender: TObject; oldURL, newURL: string);
    function CaptureState:JSValue;
    procedure UpdateNav;
    procedure NavHistoryBack;
    procedure NavHistoryForward;
    procedure tmrCaptureTimer(Sender: TObject);
    procedure RecordSession;
    [async] procedure PlaybackSession; async;
    procedure btnViewerCloseClick(Sender: TObject);
    procedure divViewerImageClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure XDataConnConnect(Sender: TObject);
    procedure mnMitarbeiterClick(Sender: TObject);
    procedure mnBuchungenClick(Sender: TObject);
    procedure mnKostenstellenClick(Sender: TObject);
    procedure Abmelden1Click(Sender: TObject);
    procedure Loganzeigen1Click(Sender: TObject);
    procedure Abwesenheiten1Click(Sender: TObject);
    procedure mnTauClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);

  private


  public
    App_Name: String;
    App_Short: String;
    App_Version: String;
    App_Release: String;
    App_Start: TDateTime;
    App_Start_UTC: TDateTime;
    App_Session: String;

    App_TZ: String;
    App_TZOffset: Integer;
    App_LogDateTimeFormat: String;
    App_DisplayDateTimeFormat: String;
    App_LogDateFormat: String;
    App_DisplayDateFormat: String;
    App_LogTimeFormat: String;
    App_DisplayTimeFormat: String;

    g_Server_URL: String;
    g_RecAnzahl : Integer;

    LoggedIn: Boolean;
    ActivityDetected: Boolean;
    IconSet: String;

    ActionLog: TStringList;
    ActionLogCurrent: TStringList;
    LogVisible: Boolean;

    CurrentForm: TForm;
    CurrentFormName: String;
    CurrentFormNiceName: String;
    CurrentFormShortName: String;
    CurrentFormIcon: String;

    CurrentSubForm: TForm;
    CurrentSubFormName: String;
    CurrentSubFormNiceName: String;
    CurrentSubFormShortName: String;
    CurrentSubFormIcon: String;

    JWT: String;
    JWT_Expiry: TDateTime;
    Remember: Boolean;

    MandantName : String;
    BenutzerLftID   : String;
    BenutzerLftName : String;
    BenutzerMaVorname: String;
    BenutzerMaZuname: String;
    BenutzerMaNr: String;
    User_EMail: String;
    User_Photo: String;
    User_Account: String;

    User_Roles: TStringList;
    Roles: String;
    Role_Administrator: Boolean;

    ToastCount: Integer;

    // Back/Forward History
    StartPosition: Integer;
    Position: Integer;
    URL: String;

    // Screen Recording
    RecordingSession: Boolean;
    CaptureData: JSValue;

    //[async] function AnwAbw_Laden : String;
    //[async] procedure FillCBX_Mit_AnwAbw( aCbx : TWebComboBox; aDataSet : TXDataWebDataSet; bNurStempel : Boolean );

    procedure Set_BenutzerAktiv( bAktiv : Boolean );
    procedure StopLinkerRemoval(P: Pointer);
    procedure PreventCompilerHint(I: integer); overload;
    procedure PreventCompilerHint(S: string); overload;
    procedure PreventCompilerHint(J: JSValue); overload;
    procedure PreventCompilerHint(H: TJSHTMLElement); overload;
  protected procedure LoadDFMValues; override; end;

var frmMainForm: TfrmMainForm;

implementation

{$R *.dfm}


uses UnitLogin, Mitarbeiter, AbwVerwaltung, PzsDatenmodul, Tau;



//================================================================================================//

procedure TfrmMainForm.WebFormCreate(Sender: TObject);
var
  i : Int64;
  n : Integer;
  ConfigResponse: TJSXMLHttpRequest;
  ConfigData: TJSONObject;
  ConfigURL, sTmp : String;
  aSL : TStringList;
const c_Code = '1234567890123456';
begin

  // Application Information
  App_Name := 'SpediFucs';
  App_Short := 'SpediFucs';
  App_Version := '1.0';
  App_Release := '2024-May-28';
  App_Start := Now();
  App_Start_UTC := TTimeZone.Local.ToUniversalTime(Now);

  // MainForm Options
  frmMainForm.Caption := App_Name;
//  MainForm.divHost.ElementHandle.style.setProperty('opacity','0');


  // Overide some locale options?
  FormatSettings.ShortDateFormat := 'dd.mm.yyyy' ;
  FormatSettings.LongDateFormat  := 'dd.mm.yyyy HH:nn' ;
  FormatSettings.DateSeparator   := '.';
  FormatSettings.TimeSeparator	 := ':';


  asm
    this.App_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.App_TZOffset = new Date().getTimezoneOffset();
  end;

  // These are intended for use with Delphi's FormatDateTime
  App_LogDateTimeFormat     :=  'dd.MM.yyyy HH:nn:zzz'; // 'yyyy-MM-dd HH:nn:ss.zzz';
  App_DisplayDateTimeFormat :=  'dd.MM.yyyy HH:nn'; // 'yyyy-MMM-dd HH:nn';
  App_LogDateFormat         :=  'dd.MM.yyyy';  //'yyyy-MM-dd';
  App_DisplayDateFormat     :=  'dd.MM.yyyy';  // 'yyyy-MMM-dd';
  App_LogTimeFormat         :=  'HH:nn:ss.zzz';
  App_DisplayTimeFormat     :=  'HH:nn:ss';


  // These are intended for use with Luxon's toFormat
  asm
    window.LogDateTimeFormat = this.App_LogDateTimeFormat.replace('nn','mm');
    window.DisplayDateTimeFormat = this.App_DisplayDateTimeFormat.replace('nn','mm');
    window.LogDateFormat = this.App_LogDateFormat.replace('nn','mm');
    window.DisplayDateFormat = this.App_DisplayDateFormat.replace('nn','mm');
    window.LogTimeFormat = this.App_LogTimeFormat.replace('nn','mm');
    window.DisplayTimeFormat = this.App_DisplayTimeFormat.replace('nn','mm');
  end;


  // Load Icon Set
//  IconSet := document.documentElement.getAttribute('iconset');
//  if IconSet = '' then IconSet := 'default';
//  DMIcons.InitializeIcons(IconSet);
//  btnViewerClose.Caption := DMIcons.Icon('Close');


  // Application State
  LoggedIn := true;
  LogVisible := true;
  RecordingSession := False;


  // JWT Handling
  JWT := '';
  JWT_Expiry := App_Start_UTC;
  Remember := True;

  // User Information
  BenutzerMaVorname := '';
  BenutzerMaZuname  := '';
  BenutzerMaNr :=  '';
  User_EMail   := '';
  User_Account := '';
  BenutzerLftID := '';
  BenutzerLftName := '';

  // Role Information
  User_Roles := TStringList.Create;
  Role_Administrator := False;
  Roles := '';

  // Log what we're doing in the application
  ActionLog := TStringList.Create;
  ActionLog.Delimiter := chr(10);
  ActionLogCurrent := TStringList.Create;
  ActionLogCurrent.Delimiter := chr(10);
  CurrentFormName := 'Module / Dashboard - Page';
  LogAction('============================================================', False);

  // Form Management
  CurrentForm := nil;
  CurrentFormName := 'Initializing';
  CurrentFormIcon := '';
  CurrentSubForm := nil;
  CurrentSubFormName := '';
  CurrentSubFormIcon := '';

  // Forms and SubForms that we've defined so far in this project
  asm {
    window.ValidForms = [
      'AdministratorForm',
      'LoginForm'
    ];
    window.ValidSubForms = [
      'AdministratorSub',
      'ChatStatisticsSub',
      'UserActionsSub',
      'UserProfileSub'
    ]
  } end;

  // Create an App Session key - just a custom Base48-encoded timestamp
  // https://github.com/marko-36/base29-shortener
  App_Session := '';
  i := DateTimeToUnix(App_Start_UTC);

  asm
    // Encode Integer (eg: Unix Timestamp) into String
    const c = ['B','b','C','c','D','d','F','f','G','g','H','h','J','j','K','k','L','M','m','N','n','P','p','Q','q','R','r','S','s','T','t','V','W','w','X','x','Z','z','0','1','2','3','4','5','6','7','8','9'];
    var sLen = Math.floor(Math.log(i)/Math.log(c.length)) +1;
    for(var ex=sLen-1; ex>-1; --ex){
      this.App_Session += c[Math.floor(i / Math.pow(c.length,ex))];
      i = [i % Math.pow(c.length,ex)];
    }

    // Decode String into Integer
    // var s = this.App_Session;
    // i = 0;
    // for (var ex=0; ex<s.length; ++ex){
    //   i += c.indexOf(s.substring(ex,ex+1)) * Math.pow(c.length,s.length-1-ex);
    // }
    // return i
  end;



  // Application Details
  LogAction('Application Startup', False);
  LogAction(' -> '+App_Name, False);
  LogAction(' -> Version '+App_Version, False);
  LogAction(' -> Release '+App_Release, False);
  LogAction(' -> App Started: '+FormatDateTime(App_LogDateTimeFormat, App_Start)+' '+App_TZ, False);
  LogAction(' -> App Started: '+FormatDateTime(App_LogDateTimeFormat, App_Start_UTC)+' UTC', False);
  LogAction(' -> App Session: '+App_Session, False);
  LogAction('============================================================', False);
  LogAction(' ', False);


  // Setup global sleep function :)
  asm window.sleep = async function(msecs) {return new Promise((resolve) => setTimeout(resolve, msecs)); } end;

  // console.image function
  asm
    function getBox(width, height) {
      return {
        string: "+",
        style: "font-size: 1px; padding: " + Math.floor(height/4) + "px " + Math.floor(width/2) + "px; line-height: " + height/2 + "px;"
    }}

    console.image = function(url, scale) {
      scale = scale || 1;
      var img = new Image();
      img.onload = function() {
        var dim = getBox(this.width * scale, this.height * scale);
        console.log("%c" + dim.string, dim.style + "background: url(" + url + "); background-size: " + (this.width * scale) + "px " + (this.height * scale) + "px; color: transparent;");
      };
      img.src = url;
    }
  end;

  // Figure out what our server connection might be
  g_Server_URL := '';
  g_RecAnzahl  := 50;


  try
    asm ConfigURL = window.location.origin+(window.location.pathname.split('/').slice(0,-1).join('/')+'/SpediFucsWebSrv.config').replace('/\/\//g','/'); end;
    LogAction(  'Lade Konfiguration aus: '+ConfigURL, False);
    console.log('Lade Konfiguration aus: '+ConfigURL);

    WebHTTPRequest1.URL := ConfigURL;
    ConfigResponse := await( TJSXMLHttpRequest, WebHTTPRequest1.Perform() );

    If String(COnfigResponse.Response) <> '' then
    begin
//      ConfigData := TJSONObject.ParseJSONValue(String(ConfigResponse.Response)) as TJSONObject;

      sTmp := String(COnfigResponse.Response);
      //LogAction(sTmp, False);

      n := Pos('##',sTmp);
      sTmp := Copy(sTmp,1, n-1);


      TAESFunc.Decrypt(c_Code, TBclUtils.DecodeBase64Url(sTmp),
        procedure(const AEncrypted: string)
          begin
            aSL := TStringList.Create;
            aSL.Text := AEncrypted; // mLog.Lines.Text;
            //For n := 0 to aSL.Count -1 Do
            //    Begin
            //     sTmp := aSL[n];
            //      LogAction(sTmp,False);
            //    End;
            g_Server_URL := aSL.Values['XDATA_SRV'] ;

            sTmp := aSL.Values['REC_ANZ'] ;
            If sTmp <>'' then
            Try
              g_RecAnzahl := StrToIntDef(sTmp, 50);
            Except
              LogAction('REC_ANZ - FEHLER: NICHT NUMMERISCH',False);
            End;

            LogAction('Einstellungen sind geladen - XDATA_SRV: '+aSL.Values['XDATA_SRV'], False );
            LogAction('Einstellungen sind geladen - REC_ANZ: '+sTmp, False );
            aSL.Free;

           if (g_Server_URL = '') then
            begin
              g_Server_URL := 'http://localhost:2001/tms/xdata';
              LogAction('Server (Config Missing-Using Default): '+g_Server_URL, False);
              console.log('Server (Config Missing-Using Default): '+g_Server_URL);
            end;

            LogAction(' ', False);
            console.log(' ');

            // Connect to XData - it will finish on its own time but give it a moment to connect
            XDataConnect;


          end,
        procedure(const AError: string)
         begin
          LogAction('Einstellungen Laden Fehler: '+AError, False );
         end)


    end;
  except on E:Exception do
    begin
    end
  end;



//  asm await sleep(100); end;

  // Launch Login
  CurrentFormName := 'LoginForm';

  LoadForm('LoginForm');

  // What to do if the browser closes unexpectedly
  asm {
    window.addEventListener('beforeunload', async function (e) {

      pas.UnitMain.MainForm.FinalRequest();

      // Option 1:
      // This logs out user when tab is closed
      // Highest security
      // pas.UnitMain.MainForm.Logout('Browser Closed');

      // Option 2:
      // Do nothing
      // Lower security but more convenient
      // - don't have to login as often.
      // - survives browser restart.

      // Option 3:
      // This enables annoying browser dialog
      // Can be used with either of the above options
      //      e.preventDefault();
      //      e.returnValue = '';

    });

  } end;


  // Set our current state as the state we want to go back to
  Position := window.history.length;
  StartPosition := window.history.length;
  URL := window.location.href;
  window.history.pushState(CaptureState, '', URL);
  Position := window.history.length;
  window.history.pushState(CaptureState, '', URL);

  // What to do when we hit back/forward button
//  asm
//    window.addEventListener('popstate', function(popstateEvent)  {
//      pas.UnitMain.MainForm.RevertState(popstateEvent.state);
//    });
//  end;


  // Lazy Load images via Vanilla Lazy Load
  asm
    // This sets up our image lazy loading system.  Just need to add "lazy" as
    // a class to an <img> tag and, oddly, to make sure that when adding it via
    // innerHTML, that it is enclosed in a <div> tag
    window.lazyLoadInstance = new LazyLoad();
    var observer = new MutationObserver(function(mutations) {
      var image_count = 0;
      mutations.forEach(
        function(mutation) {
          for (var m = 0; m < mutation.addedNodes.length; m++) {
                if (typeof mutation.addedNodes[m].getElementsByClassName !== 'function') {return;}
                    image_count += mutation.addedNodes[m].getElementsByClassName('lazy').length;
      }});
      if (image_count > 0) {
        window.lazyLoadInstance.update();
    }});
    var config = { childList: true, subtree: true };
    observer.observe(document.documentElement, config);
  end;

  PreventCompilerHint(i);


end;

//================================================================================================//

procedure TfrmMainForm.WebFormHashChange(Sender: TObject; oldURL, newURL: string);
//var
//  NewForm: String;
//  NewSubForm: String;
begin
//  asm
//    if (newURL.split('#').length == 2) {
//      if (newURL.split('#')[1].split('/').length == 2) {
//        NewForm = newURL.split('#')[1].split('/')[0];
//        NewSubForm = newURL.split('#')[1].split('/')[1];
//      }
//    }
//  end;
//
//  if (NewForm <> CurrentFormName) and (NewForm <> '')
//  then LoadForm(NewForm);
//
//  if (NewSubForm <> CurrentSubFormName) and (NewSubForm <> '')
//  then LoadSubForm(NewSubForm, False);

end;


//================================================================================================//

procedure TfrmMainForm.Set_BenutzerAktiv( bAktiv : Boolean );
begin

  mnStammdaten.Enabled := bAktiv;
  mnVerwaltung.Enabled := bAktiv;
  mnSystem.Enabled     := bAktiv;

  pnlMain.Visible := bAktiv;

end;


//================================================================================================//

//procedure TfrmMainForm.Clear_CBX_und_Objekte( aComboBox : TWebComboBox );
//var i : Integer ;
//    aObj : TObject;
//begin
//
//  For i := 0 to aComboBox.Items.Count-1 Do
//    Begin
//      aObj := TObject( aComboBox.Items.Objects[i]) ;
//      aObj.Free;
//    End;
//  aComboBox.Items.Clear ;

//end;


////================================================================================================//
//
//procedure TfrmMainForm.FillCBX_Mit_AnwAbw( aCbx : TWebComboBox; aDataSet : TXDataWebDataSet; bNurStempel : Boolean );
//var aAbw : TAbw;
//    iAbwNr : Integer;
//begin
//
//  LogAction( 'Start FillCBX_Mit_AnwAbw '+aCbx.Name, False );
//
//  If not aDataSet.Active then
//    Begin
//      LogAction( '     Tab nicht offen FillCBX_Mit_AnwAbw '+aCbx.Name, False );
//      Exit;
//    End;
//
//  Clear_CBX_und_Objekte( aCbx );
//
//  If bNurstempel then
//    Begin
//      If aDataSet.Locate('ANWABW_NR',3,[]) then
//        aCbx.Items.Add( aDataSet.FieldByName('ANWABW_BEZ').AsString  );
//
//      If aDataSet.Locate('ANWABW_NR',1,[]) then
//        aCbx.Items.Add( aDataSet.FieldByName('ANWABW_BEZ').AsString  );
//
//      If aDataSet.Locate('ANWABW_NR',2,[]) then
//        aCbx.Items.Add( aDataSet.FieldByName('ANWABW_BEZ').AsString  );
//
//      If aDataSet.Locate('ANWABW_NR',4,[]) then
//        aCbx.Items.Add( aDataSet.FieldByName('ANWABW_BEZ').AsString  );
//    End
//  Else
//    Begin
//       aDataSet.First;
//       While not aDataSet.Eof Do        //1,2,3,4,6,103
//         Begin
//           iAbwNr := aDataSet.FieldByName('ANWABW_NR').AsInteger;
//           If not (iAbwNr in [1,2,3,4,6,103] ) then
//             Begin
//               aAbw := TAbw.Create;
//               aAbw.AbwNr    := iAbwNr;
//               aAbw.AbwLang  := aDataSet.FieldByName('ANWABW_BEZ').AsString;
//               aAbw.AbwKurz  := aDataSet.FieldByName('ANWABW_KURZ').AsString;
//               aAbw.AbwFarbe := aDataSet.FieldByName('ANWABW_FARBE').AsString;
//               aCbx.Items.AddObject(aAbw.AbwLang, aAbw );
//             End;
//           aDataSet.Next;
//         End;
//
//    End;
//
//  If aCbx.Items.Count > 0 then aCbx.Itemindex := 0;
//
//  LogAction('Ende FillCBX_Mit_AnwAbw '+aCbx.Name, False);
//
//end;

////================================================================================================//
//
//
//function TfrmMainForm.Get_AnwAbw_Bez( aID : Integer ) : String;
//begin
//
//  Result := '';
//
//  If tabAnwAbw.Locate('ANWABW_NR', aID, [] ) then
//   Result := tabAnwAbw.FieldByName('ANWABW_BEZ').AsString;
//
//end;


//================================================================================================//

procedure TfrmMainForm.Abwesenheiten1Click(Sender: TObject);
begin
  Showmessage('Das Formular ist nicht implementiert');
end;

//================================================================================================//

//function TfrmMainForm.AnwAbw_Laden : String ;
//var sSQL, sDaten, sTmp : String;
//     i : Integer;
//     Blob: JSValue;
//     aSL : TStringList;
//     aArray : TArray<string>;
//     Response: TXDataClientResponse;
//begin
//
//      LogAction('Start - AnwAbw_Laden',False);
//
//      sSQL := 'SELECT ANWABW_NR, ANWABW_BEZ, ANWABW_TYP, ANWABW_ZEICHEN, ANWABW_FARBE '
//            +' FROM ANWABW ORDER BY ANWABW_BEZ ';
//
//      Response := await( XDataWebClient.RawInvokeAsync('ISystemService.GetDaten', [ sSQL, 'CSV',0,0 ] ) );
//
//      Blob := Response.Result;
//      asm sDaten = await Blob.text(); end;
//
//      //------------------------ Daten jetzt noch entschlüsseln
//      edtTemp.Text := sDaten;
//
//      TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(edtTemp.Text),
//         procedure(const AEncrypted: string)
//          begin
//
//            aSL := TStringList.Create;
//            aSL.Text := AEncrypted; // mLog.Lines.Text;
//
//            tabAnwAbw.Close;
//            tabAnwAbw.Open;
//
//            For i := 1 to aSL.Count -1 Do
//                Begin
//                 sTmp := aSL[i];
//                  aArray := sTmp.Split([';']);
//                  tabAnwAbw.Append;
//                  tabAnwAbw.FieldByName('ANWABW_NR').AsInteger   := StrToIntDef(aArray[0],0);
//                  tabAnwAbw.FieldByName('ANWABW_BEZ').AsString   := aArray[1];
//                  tabAnwAbw.FieldByName('ANWABW_TYP').AsInteger  := StrToIntDef(aArray[2],0);
//                  tabAnwAbw.FieldByName('ANWABW_KURZ').AsString  := aArray[3];
//                  tabAnwAbw.FieldByName('ANWABW_FARBE').AsString := aArray[4];
//                  tabAnwAbw.Post;
//                End;
//
//            tabAnwAbw.First;
//            aSL.Free;
//
//            LogAction('Ende  - AnwAbw_Laden',False);
//
//          end,
//        procedure(const AError: string)
//         begin
//          ShowMessage(AError);
//         end );
//
//end;

//================================================================================================//

procedure TfrmMainForm.WebButton1Click(Sender: TObject);
begin

  dmPzs.Test;

end;

procedure TfrmMainForm.WebButton3Click(Sender: TObject);
begin
 LoadForm('Buchungen');
end;

//================================================================================================//

procedure TfrmMainForm.WebFormClick(Sender: TObject);
begin
  if (CurrentFormName <> 'LoginForm') then ActivityDetected := True;
end;

//================================================================================================//

procedure TfrmMainForm.FinalRequest;
begin
  LogAction('Application Unloaded',False);
  LogAction('Session Duration: '+FormatDateTime('h"h "m"m "s"s"', Now - App_Start), False);
  JSONRequest('ISystemService.Renew',[App_Session, ActionLogCurrent.Text]);
end;

//================================================================================================//

procedure TfrmMainForm.WebFormKeyDown(Sender: TObject; var Key: Word;    Shift: TShiftState);
begin
  if (Key = VK_F4) then Logout('F4');
end;

//================================================================================================//

procedure TfrmMainForm.WebFormResize(Sender: TObject);
begin
   mLog.Height := self.Height - 80;
end;

//================================================================================================//

procedure TfrmMainForm.WebFormShow(Sender: TObject);
begin
  dmPzs := TdmPzs.Create(nil);
end;

//================================================================================================//

procedure TfrmMainForm.LoadForm( Form: String );
var ElapsedTime: TDateTime;
    ValidForm: Boolean;
    sMsg : String;

  procedure AfterCreate(AForm: TObject);
  begin
    LogAction('Load Form: '+Form+' Loaded ('+IntToStr(MillisecondsBetween(Now, ElapsedTime)-500)+'ms)', False);
  end;

begin
  // Time this action
  ElapsedTime := Now;

  // Big change
  LogAction(' ', False);

  sMsg := '1. LoadForm: '+Form;
  If Assigned(CurrentForm) then sMsg := sMsg + ' - CurrentForm: '+CurrentFormName;
  MLog.Lines.Add(sMsg  );


  // Remove old Form
  if Assigned(CurrentForm) then
  begin
    LogAction('Drop Form: '+CurrentFormName, False);
    MLog.Lines.Add('Drop Form: '+CurrentFormName);
    CurrentForm.Close;
    asm
      //divHost.replaceChildren();
    end;
  end;

  sMsg := '2. LoadForm: '+Form;
  If Assigned(CurrentForm) then sMsg := sMsg + ' - CurrentForm: '+CurrentFormName;
  MLog.Lines.Add(sMsg  );

  // Das neue Formular merken
  CurrentFormName := Form;
  LogAction('Load Form: '+Form, False);
  if (Form <> 'LoginForm') then
  begin
    TLocalStorage.SetValue('Login.CurrentForm', Form);
  end;

  sMsg := '3. LoadForm: '+Form;
  If Assigned(CurrentForm) then sMsg := sMsg + ' - CurrentForm: '+CurrentFormName;
  MLog.Lines.Add(sMsg  );


  // Formular erzeugen
  if (Form = 'LoginForm') then
  begin
    CurrentFormNiceName  := 'Login';
    CurrentFormShortName := 'Login';
    CurrentForm := TLoginForm.CreateNew( @AfterCreate);
  end
  else if (Form = 'MainForm') then
  begin
    CurrentFormNiceName  := 'Main';
    CurrentFormShortName := 'Main';
  end

  else if (Form = 'Mitarbeiter') then
  begin
    CurrentFormNiceName := 'Mitarbeiter';
    CurrentFormShortName := 'Mitarbeiter';
    CurrentForm := TfrmMitarbeiter.CreateNew( pnlMain.ElementID, @AfterCreate);
  end

  else if (Form = 'Tau') then
  begin
    CurrentFormNiceName := 'Tau';
    CurrentFormShortName := 'Tau';
    CurrentForm := TfrmTau.CreateNew( pnlMain.ElementID, @AfterCreate);
  end

  else if (Form = 'Buchungen') then
  begin
    CurrentFormNiceName := 'Buchungen';
    CurrentFormShortName := 'Buchungen';
//    CurrentForm := TfrmBuchungen.CreateNew( pnlMain.ElementID, @AfterCreate);
  end


  else if (Form = 'Abschluss') then
  begin
    CurrentFormNiceName := 'Abschluss';
    CurrentFormShortName := 'Abschluss';
//    CurrentForm := TfrmAbschluss.CreateNew( pnlMain.ElementID, @AfterCreate);
  end


  else if (Form = 'AbwVerwaltung') then
  begin
    CurrentFormNiceName := 'Abwesenheitsverwaltung';
    CurrentFormShortName := 'Abwesenheitsverwaltung';
    CurrentForm := TfrmAbwVerwaltung.CreateNew( pnlMain.ElementID, @AfterCreate);
  end;


  sMsg := '4. LoadForm: '+Form;
  If Assigned(CurrentForm) then sMsg := sMsg + ' - CurrentForm: '+CurrentFormName;
  MLog.Lines.Add(sMsg  );

end;

//================================================================================================//

procedure TfrmMainForm.LoadSubForm(SubForm: String; NewInstance: Boolean);
var
  ElapsedTime: TDateTime;
  ValidSubForm: Boolean;
  divSubForm: TJSElement;

  procedure AfterSubCreate(AForm: TObject);
  begin
    LogAction('Load SubForm: '+SubForm+' Loaded ('+IntToStr(MillisecondsBetween(Now, ElapsedTime)-500)+'ms)', False);

    document.getElementById('labelDashboard').innerHTML := CurrentSubFormIcon+CurrentSubFormNiceName;
    document.getElementById('bcDashboard').innerHTML := CurrentFormIcon+CurrentFormNiceName;
    document.getElementById('bcCurrent').innerHTML := CurrentSubFormIcon+CurrentSubFormNiceName;

    URL := '#'+CurrentFormName+'/'+CurrentSubFormName;
    window.history.replaceState(frmMainForm.CaptureState, '', URL);
    UpdateNav;
  end;

begin
  // Time this action
  ElapsedTime := Now;

  // Is this a valid SubForm?
  ValidSubForm := False;
  asm if (window.ValidSubForms.includes(SubForm)) { ValidSubForm = true; } end;
  if not(ValidSubForm) then
  begin
    LogAction('SubForm Not Found: '+SubForm, False);
    //Toast(DMIcons.Icon('Bug_Menu')+'ERROR: SubForm Error', 'SubForm Not Found: <br />[ '+SubForm+' ]',15000);
    exit;
  end;

  // Hide the old SubForm
  divSubForm := document.getElementById('divSubForm');
  asm divSubForm.style.setProperty('opacity','0','important'); end;
  asm await sleep(500); end;

  // Remove the old SubForm
  if Assigned(CurrentSubForm) then
  begin
    // Save to History
    if NewInstance then
    begin
      frmMainForm.Position := frmMainForm.Position + 1;
      window.history.pushState(frmMainForm.CaptureState, '', frmMainForm.URL);
    end;

    LogAction('Drop SubForm: '+CurrentSubFormName, False);
    CurrentSubForm.Close;
    asm
      document.getElementById('divSubForm').replaceChildren();
    end;
  end;


  // Note the new SubForm
  CurrentSubFormName := SubForm;
  asm
    document.getElementById('divSubForm').className = 'app-main '+SubForm;
  end;
  LogAction('Load SubForm: '+SubForm, False);

  // Launch SubForm
if (SubForm = 'AdministratorSub') then
  begin
//    CurrentSubFormNiceName := 'Admininistrator Dashboard';
//    CurrentSubFormShortName := 'AdminDash';
//    CurrentSubFormIcon := DMIcons.Icon('Administrator_Menu');
//    CurrentSubForm := TAdministratorSubForm.CreateNew(divSubForm.id, @AfterSubCreate);
  end;

  TLocalStorage.SetValue('Login.CurrentSubForm', SubForm);

end;

//================================================================================================//

procedure TfrmMainForm.LogAction(Action: String; Extend: Boolean);
var FilterAction: String;
    Module: String;
begin

  FilterAction := StringReplace(Action, chr(10), '', [rfReplaceAll]);
  FilterAction := StringReplace(FilterAction, chr(13), '', [rfReplaceAll]);
  FilterAction := StringReplace(FilterAction, '"', '''', [rfReplaceAll]);

  Module := StringReplace(CurrentFormName,'Form','',[]);
  if (CurrentSubFormName <> '')
  then Module := Module +'-'+StringReplace(CurrentSubFormName,'Sub','',[]);
  Module := Module.PadRight(30);

  // Log the action to a TStringList
  ActionLog.Add(FormatDateTime(App_LogDateTimeFormat,  TTimeZone.Local.ToUniversalTime(Now))+' UTC  ['+Module+']  '+FilterAction);
  ActionLogCurrent.Add(FormatDateTime(App_LogDateTimeFormat,  TTimeZone.Local.ToUniversalTime(Now))+' UTC  ['+Module+']  '+FilterAction);

  // Log to Console
//  console.Log(FilterAction);

  // Be mindful that generating log entries can reset the ActivityDetected state
  If Extend then ActivityDetected := True;

end;

//================================================================================================//

procedure TfrmMainForm.Loganzeigen1Click(Sender: TObject);
begin
  pnlLog.Visible := not pnlLog.Visible;

  btnShowLogClick(Sender);

end;

//================================================================================================//

procedure TfrmMainForm.Logout(Reason: String);
begin
  // Make sure these don't fire
  tmrJWTRenewal.Enabled := False;
  tmrJWTRenewalWarning.Enabled := False;

  mLog.Lines.Add('Logout start');

  if CurrentFormName <> 'Login' then
  begin
    mLog.Lines.Add('1 Logout');

    LogAction('Logout: '+Reason, False);
    LogAction('Session Duration: '+FormatDateTime('h"h "m"m "s"s"', Now - App_Start), False);
    //Toast('Logout','Processing. Please wait.',1000);

    mLog.Lines.Add('2 Logout');

    await(JSONRequest('ISystemService.Logout',[App_Session, ActionLogCurrent.Text]));

    mLog.Lines.Add('3 Logout');

    JWT := '';
    TLocalStorage.SetValue('Login.CurrentForm','Login');
    TLocalStorage.RemoveKey('Login.JWT');
    TLocalStorage.RemoveKey('Login.Expiry');

    mLog.Lines.Add('4 Logout');

//    asm
//      window.history.replaceState(null,null,window.location.href.split('#')[0]);
//      await sleep(1000);
//      divHost.style.setProperty('opacity','0');
//      await sleep(1000);
//    end;

    mLog.Lines.Add('5 Logout');
    window.location.reload(true);
  end;

  mLog.Lines.Add('Logout end');

end;

procedure TfrmMainForm.mnKostenstellenClick(Sender: TObject);
begin
  LoadForm('Kostenstellen');
end;

procedure TfrmMainForm.mnMitarbeiterClick(Sender: TObject);
begin
//  LoadForm('Mitarbeiter');
end;

//================================================================================================//

procedure TfrmMainForm.NavHistoryBack;
begin
  window.history.back;
end;

procedure TfrmMainForm.NavHistoryForward;
begin
  window.history.forward;
end;

//================================================================================================//

procedure TfrmMainForm.ProcessJWT(aJWT: String);
var JWTClaims: TJSONObject;
     i : Integer;
begin

// LogAction( aJWT, False );



  JWT := aJWT;

  // Roles Disabled by Default
  Role_Administrator := False;

  // Get JSON Claims from JWT
  JWTClaims := TJSONObject.ParseJSONValue(Window.atob(Copy(JWT, Pos('.',JWT)+1, LastDelimiter('.',JWT)-Pos('.',JWT)-1))) as TJSONObject;

  // Extract user information
  BenutzerMaVorname  :=  (JWTClaims.GetValue('fnm') as TJSONString).Value;
  BenutzerMaNr       :=  (JWTClaims.GetValue('mnm') as TJSONString).Value;
  BenutzerMaZuname   :=  (JWTClaims.GetValue('lnm') as TJSONString).Value;
  BenutzerLftID      :=  (JWTClaims.GetValue('lft') as TJSONString).Value;
  BenutzerLftName    :=  (JWTClaims.GetValue('lfn') as TJSONString).Value;
  MandantName        :=  (JWTClaims.GetValue('man') as TJSONString).Value;

  User_EMail   :=  (JWTClaims.GetValue('eml') as TJSONString).Value;
  User_Account :=  (JWTClaims.GetValue('anm') as TJSONString).Value;


  lbBenutzer.Caption := ' << '+ BenutzerMaZuname+', '+ BenutzerMaVorname +' >>';
  lbWebTitel.Caption := 'SpediFucs - '+ MandantName;


  // If roles have changed since logging in, then inform the user
//  if (CurrentFormName <> 'Login')
//     and (User_Roles.CommaText <> (JWTClaims.GetValue('rol') as TJSONString).Value)
//     and (User_Roles.CommaText <> '')
//  then Toast('Updated Roles', 'The roles for this account have been updated. Please Logout and Login again to access them.', 15000);
//  User_Roles.CommaText :=  (JWTClaims.GetValue('rol') as TJSONString).Value;
//  Roles := (JWTClaims.GetValue('rol') as TJSONString).Value;

  // Set renewal to one minute before expiration
  JWT_Expiry := UnixToDateTime((JWTClaims.GetValue('exp') as TJSONNumber).AsInt);
  tmrJWTRenewal.Enabled := False;
  tmrJWTRenewal.Interval := MillisecondsBetween(JWT_Expiry, TTimeZone.Local.ToUniversalTime(Now)) - 30000;
  tmrJWTRenewalWarning.Interval := MillisecondsBetween(JWT_Expiry, TTimeZone.Local.ToUniversalTime(Now)) - 90000;

  // If it has already expired, then not much point in continuing?
  if tmrJWTRenewal.Interval <= 0 then
  begin
    Logout('JWT Expired')
  end
  else
  begin
    tmrJWTRenewal.Enabled := True;
    tmrJWTRenewalWarning.Enabled := True;

    if Remember then
    begin
      TLocalStorage.SetValue('Login.JWT', frmMainForm.JWT);
      TLocalStorage.SetValue('Login.Expiry', FloatToStr(frmMainForm.JWT_Expiry));
    end;

    // Extract Roles
    Role_Administrator := False;
    i := 0;
    while i < User_Roles.Count do
    begin
      if User_Roles[i] = '1' then Role_Administrator := True;
      i := i + 1;
    end;

    LogAction('Processing Token', False);
    //LogAction( frmMainForm.JWT, False );
    LogAction(' -> Name: '+BenutzerMaVorname+' '+BenutzerMaZuname, False);
    LogAction(' -> EMail: '+User_Email, False);
    LogAction(' -> Roles: '+User_Roles.CommaText, False);
    LogAction(' -> Administrator: '+BoolToStr(Role_Administrator,True), False);
    LogAction(' -> Expires: '+(JWTClaims.GetValue('unt') as TJSONString).Value,False);
    LogAction(' -> Remaining: '+FormatDateTime('n"m "s"s"',(JWT_Expiry - TTimeZone.Local.ToUniversalTime(Now))),False);
    LogAction('Token Processed', False);

  end;

end;

//================================================================================================//

procedure TfrmMainForm.RecordSession;
begin
  if RecordingSession = False then
  begin
    RecordingSession := True;
    asm
      var Icon = pas.UnitIcons.DMIcons.Lookup;
      btnRecording.innerHTML = Icon['Record']+'00:00:00';
      btnRecord.innerHTML = Icon['Record']+'Stop Recording';
      btnRecording.classList.remove('d-none');
      pas.UnitMain.MainForm.CaptureData = [];
    end;
    frmMainForm.tmrCapture.Enabled := True;
  end
  else
  begin
    RecordingSession := False;
    asm
      var Icon = pas.UnitIcons.DMIcons.Lookup;
      btnRecording.classList.add('d-none');
      btnRecord.innerHTML = Icon['Record']+'Start Recording';
    end;
    frmMainForm.tmrCapture.Enabled := False;
  end;
end;

//================================================================================================//

procedure TfrmMainForm.tmrCaptureTimer(Sender: TObject);
begin
  asm
    // Maximum capture - 600 frames (10m @ 1 fps)
    if (pas.UnitMain.MainForm.CaptureData.length < 600) {
      modernScreenshot.domToPng(document.querySelector('#divHost'), {scale:1.0, width: Math.ceil(window.innerWidth / 2) * 2, height: Math.ceil(window.innerHeight / 2) * 2 }).then(dataURI => {
//        console.image(dataURI,0.5);
        pas.UnitMain.MainForm.CaptureData.push(dataURI);
        var icon = pas.UnitIcons.DMIcons.Lookup;
        var rectime = new Date(1000 * pas.UnitMain.MainForm.CaptureData.length).toISOString().substr(11, 8);
        btnRecording.innerHTML = icon['Record']+rectime;
      });
    }
  end;
end;

//================================================================================================//

procedure TfrmMainForm.tmrJWTRenewalTimer(Sender: TObject);
var
  ResponseString: String;
  ActionLogSend: String;
begin
  tmrJWTRenewal.Enabled := False;

  ActionLogSend := ActionLogCurrent.Text;
  ActionLogCurrent.Text := '';

  // Renew JWT if there has been activity of some kind
  if ActivityDetected then
  begin
    ResponseString := await(JSONRequest('ISystemService.Renew',[App_Session, ActionLogSend]));
    if Copy(ResponseString,1,6) =  'Bearer' then
    begin
      LogAction('Login Renewed', False);
      ProcessJWT(ResponseString);
      ActivityDetected := False;
    end
    else
    begin
    // Otherwise perform an automatic logout of this session
      LogAction('Login NOT Renewed', False);
      Logout('NoRenewal');
    end
  end
  else
  begin
    Logout('Inactivity');
  end;
end;

//================================================================================================//

procedure TfrmMainForm.tmrJWTRenewalWarningTimer(Sender: TObject);
begin
  tmrJWTRenewalWarning.Enabled := False;
  if not(ActivityDetected)
  then Toast('Auto Logout','No activity has been detected.<br />Auto Logout in $S seconds.', 60000);
end;

//================================================================================================//

procedure TfrmMainForm.Toast(Header, Body: String; Timeout: Integer);
begin
  // Want ID to be unique
  ToastCount := ToastCount + 1;

  asm
    // Create Toast
    var toast = document.createElement('div');
    toast.className = 'toast';
    toast.setAttribute('id','toast'+this.ToastCount);
    toast.setAttribute('role','alert');
    toast.setAttribute('aria-live','assertive');
    toast.setAttribute('aria-atomic','true');
    toast.setAttribute('data-bs-delay', Timeout);
    toast.setAttribute('countdown',parseInt(2*Timeout/1000));

    // Create Toast Header
    var toasth = document.createElement('div');
    toasth.className = 'toast-header bg-danger text-white pb-3 position-relative';
    toasth.innerHTML = '<div style="position:absolute; border-radius: var(--custom-rounding); z-index: 2; display:block; top:37px; left:4px; width:98%; height:5px; background: var(--bs-danger);"></div>'+
                       '<div style="position:absolute; border-radius: var(--custom-rounding); z-index: 1; display:block; top:36px; left:3px; width:98%; height:7px; background: var(--bs-dark);"></div>'+
                       '<strong class="me-auto">'+Header+'</strong>'+
                       '<small class="text-light">just now</small>'+
                       '<button type="button" onclick="pas.UnitMain.MainForm.ActivityDetected = true;"; class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>';

    // Create Toast Body
    var toastb = document.createElement('div');
    toastb.className = 'toast-body';
    toastb.innerHTML = Body.replace('$S',parseInt(Timeout/1000));

    // Make Toast
    toast.appendChild(toasth);
    toast.appendChild(toastb);
    divToasts.appendChild(toast);

    // Add countdown timer
    const toastc = setInterval(function() {
      if (((Timeout == 60000) && (pas.UnitMain.MainForm.ActivityDetected == true)) || ((toast.getAttribute('countdown') | 0) <= 0)) {
        clearInterval(toastc);
        toast.remove();
      }
      else {
        toast.setAttribute('countdown',toast.getAttribute('countdown')-1);
        toast.lastElementChild.innerHTML = Body.replace('$S',parseInt(toast.getAttribute('countdown')/2));
        toast.firstElementChild.firstElementChild.style.setProperty('width', parseInt(98*toast.getAttribute('countdown')/(Timeout/500))+'%');
      }
    },500);

    // Show Toast
    var newtoast = new bootstrap.Toast(toast).show();

  end;
end;

//================================================================================================//

procedure TfrmMainForm.UpdateNav;
begin
  asm
//console.log('Position = '+this.Position);
//console.log('Start = '+this.StartPosition);
//console.log('History = '+window.history.length);
//console.log(' ');

    var backbtns = document.getElementsByClassName('nav-history-back');
    if (this.Position <= (this.StartPosition+1)) {
      for (var i = 0; i < backbtns.length; i++) {
        backbtns[i].setAttribute('disabled','');
      }
    } else {
      for (var i = 0; i < backbtns.length; i++) {
        backbtns[i].removeAttribute('disabled');
      }
    }

    var forwardbtns = document.getElementsByClassName('nav-history-forward');
    if (this.Position == window.history.length) {
      for (var i = 0; i < forwardbtns.length; i++) {
        forwardbtns[i].setAttribute('disabled','');
      }
    } else {
      for (var i = 0; i < forwardbtns.length; i++) {
        forwardbtns[i].removeAttribute('disabled');
      }
    }
  end;
end;

//================================================================================================//

procedure TfrmMainForm.Abmelden1Click(Sender: TObject);
begin
  Logout('Browser geschlossen' );
end;

procedure TfrmMainForm.btnShowLogClick(Sender: TObject);
begin

  mLog.Lines.Text :=  ActionLog.Text;

//  mLog.Lines.Add( self.CurrentFormName );
//  mLog.Lines.Add( self.CurrentFormNiceName );
//  mLog.Lines.Add( self.CurrentFormShortName );

end;

//================================================================================================//

procedure TfrmMainForm.btnViewerCloseClick(Sender: TObject);
begin
  window.history.back;
end;

procedure TfrmMainForm.mnTauClick(Sender: TObject);
begin
  LoadForm('Tau');
end;

procedure TfrmMainForm.mnBuchungenClick(Sender: TObject);
begin
  LoadForm('Buchungen');
end;

//================================================================================================//

function TfrmMainForm.CaptureState: JSValue;
begin
  Result := nil;
  // Return state of some kind
  asm
    Result = {
      "Position": this.Position,
      "URL": this.URL,
      "Form": this.CurrentFormName,
      "SubForm": this.CurrentSubFormName,
    }
  end;
end;

//================================================================================================//

procedure TfrmMainForm.divViewerImageClick(Sender: TObject);
begin
  window.history.back;
end;

//================================================================================================//

function TfrmMainForm.JSONRequest(Endpoint: String; Params: array of JSValue): String;
var
  ClientConn: TXDataWebClient;
  Response: TXDataClientResponse;
  Blob: JSValue;
  ErrorCode: String;
  ErrorMessage: String;
  Elapsed: TDateTime;
begin
  Elapsed := Now;
  Result := '';
  LogAction('Requested: '+Endpoint, False);

  await(XDataConnect);
  if (XdataConn.Connected) then
  begin
    try
      ClientConn := TXDataWebClient.Create(nil);
      ClientConn.Connection := XDataConn;
      Response := await(ClientConn.RawInvokeAsync(Endpoint, Params));

      Blob := Response.Result;
      asm Result = await Blob.text(); end;

    except on E: Exception do
      begin
        // Get the error message we created in XData
        asm {
          var ErrorDetail = JSON.parse( await E.FErrorResult.FResponse.$o.FXhr.response.text() );
          ErrorCode = ErrorDetail.error.code;
          ErrorMessage = ErrorDetail.error.message;
        } end;

        // Log the error, but leave out the URI (because it includes the password)
        LogAction('ERROR Request Exception Received From'+Endpoint, False);
        LogAction(' --> ['+E.ClassName+']', False);
        LogAction(' --> '+Copy(E.Message,1,Pos('Uri:',E.Message)-2), False);
        LogAction(' --> '+Copy(E.Message,Pos('Status code:',E.Message),16), False);
        LogAction(' --> '+ErrorCode, False);
        LogAction(' --> '+ErrorMessage, False);

        // Will tamp these down a bit once we get a better feel for the kinds of errors
        // that come up regularly.
        Toast('Unexpected Error',
          '[ '+E.ClassName+' ] '+Endpoint+'<br />'+
          Copy(E.Message,1,Pos('Uri:',E.Message)-2)+'<br />'+
          Copy(E.Message,Pos('Status code:',E.Message),16)+'<br />'+
          ErrorCode+'<br />'+
          ErrorMessage
        ,45000);

      end;
    end;
  end;

  LogAction('Responded: '+Endpoint+' ('+IntToStr(MillisecondsBetween(Now, Elapsed))+'ms)', False);
  PreventCompilerHint(Blob);
end;

//================================================================================================//

procedure TfrmMainForm.XDataConnConnect(Sender: TObject);
begin
 mLog.Lines.Add('XDataConnect=True');

end;

//================================================================================================//

procedure TfrmMainForm.XDataConnect;
var  ElapsedTime: TDateTime;
begin

  ElapsedTime := Now;

  mLog.Lines.Add('XDataConnect');

  if not(XDataConn.Connected) then
  begin

    // Should be updated to point at our XData server, wherever it may be
    XDataConn.URL := g_Server_URL;

    // Try and establish a connection to the server
    try
      LogAction('Connecting to: '+XDataConn.URL, False);
      await(XDataConn.OpenAsync);
      LogAction('Connection Established: ('+IntToStr(MillisecondsBetween(Now, ElapsedTime))+'ms)', False);
    except on E: Exception do
      begin
        LogAction('Connection Failed: '+XDataConn.URL, False);
        LogAction(' --> ['+E.ClassName+']', False);
        LogAction(' --> '+E.Message, False);
      end;
    end;
  end;
end;

//================================================================================================//

procedure TfrmMainForm.XDataConnRequest(Args: TXDataWebConnectionRequest);
begin
  Args.Request.Headers.SetValue('Authorization', JWT);
end;

//================================================================================================//

function TfrmMainForm.XDataLogin( Username, Password: String ):String;
var
  Response: TXDataClientResponse;
  ClientConn: TXDataWebClient;
  Blob: JSValue;
  NewJWT: String;
  ElapsedTime: TDateTime;
  ErrorCode: String;
  ErrorMessage: String;
begin

  ElapsedTime := Now;
  NewJWT := '';
  ErrorCode := '';
  ErrorMessage := '';

  LogAction(' ', False);
  LogAction('Attempting Login', False);

//Showmessage('XDataLogin');

  // Call it again in case it has been disconnected
  await( XDataConnect );



  If (XDataConn.Connected) then
  begin
    try
      ClientConn := TXDataWebClient.Create(nil);
      ClientConn.Connection := XDataConn;

      Response := await(ClientConn.RawInvokeAsync('ISystemService.Login', [
                          Username, Password, 'Testing', App_TZ ]));

      Blob := Response.Result;
      asm NewJWT = await Blob.text(); end;

    except on E: Exception do
      begin
        // Get the error message we created in XData
        asm {
          var ErrorDetail = JSON.parse( await E.FErrorResult.FResponse.$o.FXhr.response.text() );
          ErrorCode = ErrorDetail.error.code;
          ErrorMessage = ErrorDetail.error.message;
        } end;

        // Log the error, but leave out the URI (because it includes the password)
        LogAction('Login Exception:', False);
        LogAction(' --> ['+E.ClassName+']', False);
        LogAction(' --> '+Copy(E.Message,1,Pos('Uri:',E.Message)-2), False);
        LogAction(' --> '+Copy(E.Message,Pos('Status code:',E.Message),16), False);
        LogAction(' --> '+ErrorCode, False);
        LogAction(' --> '+ErrorMessage, False);
      end;
    end;
  end;

  // We've got a JWT
  if Pos('Bearer ', NewJWT) = 1 then
  begin
    // How long did it take?
    LogAction('Login Successful ('+IntToStr(MilliSecondsBetween(Now, ElapsedTime))+'ms)', False);

    // Do stuff with the JWT
    ProcessJWT(NewJWT);

    // All done
    Result := 'Success';
    LoggedIn := True;

  end
  else
  begin
    LoggedIn := False;
    Result := ErrorCode+' / '+ErrorMessage;
  end;

  PreventCompilerHint(Blob);
end;

//================================================================================================//

procedure TfrmMainForm.PlaybackSession;
var
  FrameCount: Integer;
  PlaybackRate: Double;
begin

  asm FrameCount = pas.UnitMain.MainForm.CaptureData.length; end;

  if FrameCount = 0 then
  begin

  end
  else
  begin

    PlaybackRate := 1;

    asm
      // Load and initialize ffmpeg
      const { createFFmpeg, fetchFile } = FFmpeg;
      const ffmpeg = createFFmpeg({
//        log: true,
        mainName: 'main',
        corePath: 'https://cdn.jsdelivr.net/npm/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js'
      });
      await ffmpeg.load();


      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      }

      ffmpeg.setProgress(async function({ ratio })  {
        console.log('FFmpeg Progress: '+ratio);
      });


      const image2video = async () => {

        // Add image files to ffmpeg internal filesystem
        FrameCount = pas.UnitMain.MainForm.CaptureData.length;
        for (let i = 0; i < FrameCount; i += 1) {
          const num = `000${i}`.slice(-4);
          ffmpeg.FS('writeFile', 'tmp.'+num+'.png', await fetchFile(dataURLtoFile(pas.UnitMain.MainForm.CaptureData[i],'frame-'+i+'.png')));
        }

        // Perform the conversion the conversion
        await ffmpeg.run('-framerate', String(PlaybackRate), '-pattern_type', 'glob', '-i', '*.png','-c:v', 'libx264', '-pix_fmt', 'yuv420p', 'out.mp4');

        // Get the resulting video file
        const data = ffmpeg.FS('readFile', 'out.mp4');

        // Delete image files from ffmpeg internal filesystem
        for (let i = 0; i < FrameCount; i += 1) {
          const num = `000${i}`.slice(-4);
          ffmpeg.FS('unlink', 'tmp.'+num+'.png');
        }
        ffmpeg.exit;

        // Create a place to show the video
        const video = document.createElement('video');

        // Load the video file into the page element
        video.src = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));

        // Set some attributes
        video.id = "video";
        video.controls = true;
        video.loop = true;

        // Set some styles
        video.style.setProperty('position','fixed');
        video.style.setProperty('left','0px');
        video.style.setProperty('top','0px');
        video.style.setProperty('width','100%');
        video.style.setProperty('height','100%');
        video.style.setProperty('z-index','1000000');

        // Add to the page
        document.body.appendChild(video);

        // Load and start playing the video
        video.load();
        video.play();

        // Wait for a double-click to unload the video
        video.addEventListener('dblclick',function() {
          video.pause();
          video.removeAttribute('src');
          video.load();
          video.src = '';
          video.srcObject = null;
          video.remove()
        });

      }

      image2video();

    end;
  end;
end;

procedure TfrmMainForm.StopLinkerRemoval(P: Pointer);                          begin end;
procedure TfrmMainForm.PreventCompilerHint(H: TJSHTMLElement);       overload; begin end;
procedure TfrmMainForm.PreventCompilerHint(I: integer);              overload; begin end;
procedure TfrmMainForm.PreventCompilerHint(J: JSValue);              overload; begin end;
procedure TfrmMainForm.PreventCompilerHint(S: string);               overload; begin end;


procedure TfrmMainForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  pnlMenu := TPanel.Create(Self);
  pnlMain := TPanel.Create(Self);
  edtTemp := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  pnlStatus := TPanel.Create(Self);
  lbWebTitel := TLabel.Create(Self);
  lbBenutzer := TLabel.Create(Self);
  pnlLog := TPanel.Create(Self);
  mLog := TMemo.Create(Self);
  btnShowLog := TButton.Create(Self);
  WebSplitter1 := TSplitter.Create(Self);
  XDataConn := TXDataWebConnection.Create(Self);
  tmrJWTRenewal := TTimer.Create(Self);
  tmrJWTRenewalWarning := TTimer.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);
  tmrCapture := TTimer.Create(Self);
  mMainMenu := TMainMenu.Create(Self);
  mnStammdaten := TMenuItem.Create(Self);
  mnMitarbeiter := TMenuItem.Create(Self);
  mnVerwaltung := TMenuItem.Create(Self);
  mnTau := TMenuItem.Create(Self);
  mnBuchungen := TMenuItem.Create(Self);
  mnSystem := TMenuItem.Create(Self);
  Passwortndern1 := TMenuItem.Create(Self);
  N1 := TMenuItem.Create(Self);
  Loganzeigen1 := TMenuItem.Create(Self);
  Abmelden1 := TMenuItem.Create(Self);
  XDataWebClient := TXDataWebClient.Create(Self);
  tabAnwAbw := TXDataWebDataSet.Create(Self);
  tabAnwAbwANWABW_NR := TIntegerField.Create(Self);
  tabAnwAbwANWABW_BEZ := TStringField.Create(Self);
  tabAnwAbwANWABW_TYP := TIntegerField.Create(Self);
  tabAnwAbwANWABW_KURZ := TStringField.Create(Self);
  tabAnwAbwANWABW_FARBE := TStringField.Create(Self);

  pnlMenu.BeforeLoadDFMValues;
  pnlMain.BeforeLoadDFMValues;
  edtTemp.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  pnlStatus.BeforeLoadDFMValues;
  lbWebTitel.BeforeLoadDFMValues;
  lbBenutzer.BeforeLoadDFMValues;
  pnlLog.BeforeLoadDFMValues;
  mLog.BeforeLoadDFMValues;
  btnShowLog.BeforeLoadDFMValues;
  WebSplitter1.BeforeLoadDFMValues;
  XDataConn.BeforeLoadDFMValues;
  tmrJWTRenewal.BeforeLoadDFMValues;
  tmrJWTRenewalWarning.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  tmrCapture.BeforeLoadDFMValues;
  mMainMenu.BeforeLoadDFMValues;
  mnStammdaten.BeforeLoadDFMValues;
  mnMitarbeiter.BeforeLoadDFMValues;
  mnVerwaltung.BeforeLoadDFMValues;
  mnTau.BeforeLoadDFMValues;
  mnBuchungen.BeforeLoadDFMValues;
  mnSystem.BeforeLoadDFMValues;
  Passwortndern1.BeforeLoadDFMValues;
  N1.BeforeLoadDFMValues;
  Loganzeigen1.BeforeLoadDFMValues;
  Abmelden1.BeforeLoadDFMValues;
  XDataWebClient.BeforeLoadDFMValues;
  tabAnwAbw.BeforeLoadDFMValues;
  tabAnwAbwANWABW_NR.BeforeLoadDFMValues;
  tabAnwAbwANWABW_BEZ.BeforeLoadDFMValues;
  tabAnwAbwANWABW_TYP.BeforeLoadDFMValues;
  tabAnwAbwANWABW_KURZ.BeforeLoadDFMValues;
  tabAnwAbwANWABW_FARBE.BeforeLoadDFMValues;
  try
    Name := 'frmMainForm';
    Width := 924;
    Height := 570;
    Caption := 'PeZeSys';
    Color := 16378331;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    Menu := mMainMenu;
    ParentFont := False;
    Visible := True;
    SetEvent(Self, 'OnClick', 'WebFormClick');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnHashChange', 'WebFormHashChange');
    SetEvent(Self, 'OnKeyDown', 'WebFormKeyDown');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    pnlMenu.SetParentComponent(Self);
    pnlMenu.Name := 'pnlMenu';
    pnlMenu.Left := 0;
    pnlMenu.Top := 40;
    pnlMenu.Width := 924;
    pnlMenu.Height := 40;
    pnlMenu.Align := alTop;
    pnlMenu.ChildOrder := 3;
    pnlMenu.Color := 16574684;
    pnlMenu.TabOrder := 0;
    pnlMain.SetParentComponent(Self);
    pnlMain.Name := 'pnlMain';
    pnlMain.Left := 0;
    pnlMain.Top := 80;
    pnlMain.Width := 395;
    pnlMain.Height := 470;
    pnlMain.Align := alClient;
    pnlMain.ChildOrder := 2;
    pnlMain.Color := 16574684;
    pnlMain.TabOrder := 1;
    edtTemp.SetParentComponent(pnlMain);
    edtTemp.Name := 'edtTemp';
    edtTemp.Left := 37;
    edtTemp.Top := 6;
    edtTemp.Width := 121;
    edtTemp.Height := 22;
    edtTemp.ChildOrder := 23;
    edtTemp.HeightPercent := 100.000000000000000000;
    edtTemp.Text := 'edtTemp';
    edtTemp.Visible := False;
    edtTemp.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(pnlMain);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 123;
    WebButton1.Top := 191;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Anchors := [akTop,akRight];
    WebButton1.Caption := 'WebButton1';
    WebButton1.ChildOrder := 1;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    pnlStatus.SetParentComponent(Self);
    pnlStatus.Name := 'pnlStatus';
    pnlStatus.Left := 0;
    pnlStatus.Top := 0;
    pnlStatus.Width := 924;
    pnlStatus.Height := 40;
    pnlStatus.Align := alTop;
    pnlStatus.ChildOrder := 11;
    pnlStatus.Color := 16370864;
    pnlStatus.TabOrder := 2;
    lbWebTitel.SetParentComponent(pnlStatus);
    lbWebTitel.Name := 'lbWebTitel';
    lbWebTitel.Left := 77;
    lbWebTitel.Top := 0;
    lbWebTitel.Width := 847;
    lbWebTitel.Height := 40;
    lbWebTitel.Align := alClient;
    lbWebTitel.Alignment := taCenter;
    lbWebTitel.AutoSize := False;
    lbWebTitel.Caption := 'SpediFucs';
    lbWebTitel.Font.Charset := DEFAULT_CHARSET;
    lbWebTitel.Font.Color := clNavy;
    lbWebTitel.Font.Height := -21;
    lbWebTitel.Font.Name := 'Tahoma';
    lbWebTitel.Font.Style := [fsBold];
    lbWebTitel.HeightPercent := 100.000000000000000000;
    lbWebTitel.Layout := tlCenter;
    lbWebTitel.ParentFont := False;
    lbWebTitel.WidthPercent := 100.000000000000000000;
    lbBenutzer.SetParentComponent(pnlStatus);
    lbBenutzer.Name := 'lbBenutzer';
    lbBenutzer.Left := 0;
    lbBenutzer.Top := 0;
    lbBenutzer.Width := 77;
    lbBenutzer.Height := 40;
    lbBenutzer.Align := alLeft;
    lbBenutzer.Alignment := taRightJustify;
    lbBenutzer.Caption := ' Benutzer: --';
    lbBenutzer.Font.Charset := ANSI_CHARSET;
    lbBenutzer.Font.Color := clNavy;
    lbBenutzer.Font.Height := -13;
    lbBenutzer.Font.Name := 'Segoe UI';
    lbBenutzer.Font.Style := [fsBold];
    lbBenutzer.HeightPercent := 100.000000000000000000;
    lbBenutzer.Layout := tlCenter;
    lbBenutzer.ParentFont := False;
    lbBenutzer.WidthPercent := 100.000000000000000000;
    pnlLog.SetParentComponent(Self);
    pnlLog.Name := 'pnlLog';
    pnlLog.Left := 400;
    pnlLog.Top := 80;
    pnlLog.Width := 524;
    pnlLog.Height := 470;
    pnlLog.Align := alRight;
    pnlLog.ChildOrder := 11;
    pnlLog.Color := 16574684;
    pnlLog.TabOrder := 3;
    pnlLog.Visible := False;
    mLog.SetParentComponent(pnlLog);
    mLog.Name := 'mLog';
    mLog.Left := 0;
    mLog.Top := 40;
    mLog.Width := 524;
    mLog.Height := 430;
    mLog.Align := alBottom;
    mLog.Color := 14545151;
    mLog.Font.Charset := DEFAULT_CHARSET;
    mLog.Font.Color := clWindowText;
    mLog.Font.Height := -12;
    mLog.Font.Name := 'Tahoma';
    mLog.Font.Style := [];
    mLog.HeightPercent := 100.000000000000000000;
    mLog.ParentFont := False;
    mLog.SelLength := 0;
    mLog.SelStart := 0;
    mLog.WidthPercent := 100.000000000000000000;
    btnShowLog.SetParentComponent(pnlLog);
    btnShowLog.Name := 'btnShowLog';
    btnShowLog.Left := 3;
    btnShowLog.Top := 6;
    btnShowLog.Width := 90;
    btnShowLog.Height := 30;
    btnShowLog.Caption := 'ShowLog';
    btnShowLog.ChildOrder := 1;
    btnShowLog.HeightStyle := ssAuto;
    btnShowLog.HeightPercent := 100.000000000000000000;
    btnShowLog.WidthPercent := 100.000000000000000000;
    SetEvent(btnShowLog, Self, 'OnClick', 'btnShowLogClick');
    WebSplitter1.SetParentComponent(Self);
    WebSplitter1.Name := 'WebSplitter1';
    WebSplitter1.Left := 395;
    WebSplitter1.Top := 80;
    WebSplitter1.Width := 5;
    WebSplitter1.Height := 470;
    WebSplitter1.Align := alRight;
    WebSplitter1.ChildOrder := 4;
    WebSplitter1.Color := clBtnFace;
    XDataConn.SetParentComponent(Self);
    XDataConn.Name := 'XDataConn';
    XDataConn.URL := 'http://92.211.154.249:6000/tms/xdata';
    SetEvent(XDataConn, Self, 'OnConnect', 'XDataConnConnect');
    SetEvent(XDataConn, Self, 'OnRequest', 'XDataConnRequest');
    XDataConn.Left := 208;
    XDataConn.Top := 135;
    tmrJWTRenewal.SetParentComponent(Self);
    tmrJWTRenewal.Name := 'tmrJWTRenewal';
    tmrJWTRenewal.Enabled := False;
    SetEvent(tmrJWTRenewal, Self, 'OnTimer', 'tmrJWTRenewalTimer');
    tmrJWTRenewal.Left := 144;
    tmrJWTRenewal.Top := 304;
    tmrJWTRenewalWarning.SetParentComponent(Self);
    tmrJWTRenewalWarning.Name := 'tmrJWTRenewalWarning';
    tmrJWTRenewalWarning.Enabled := False;
    SetEvent(tmrJWTRenewalWarning, Self, 'OnTimer', 'tmrJWTRenewalWarningTimer');
    tmrJWTRenewalWarning.Left := 288;
    tmrJWTRenewalWarning.Top := 392;
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    WebHttpRequest1.Left := 280;
    WebHttpRequest1.Top := 303;
    tmrCapture.SetParentComponent(Self);
    tmrCapture.Name := 'tmrCapture';
    tmrCapture.Enabled := False;
    SetEvent(tmrCapture, Self, 'OnTimer', 'tmrCaptureTimer');
    tmrCapture.Left := 152;
    tmrCapture.Top := 376;
    mMainMenu.SetParentComponent(Self);
    mMainMenu.Name := 'mMainMenu';
    mMainMenu.Appearance.HamburgerMenu.Caption := 'Menu';
    mMainMenu.Appearance.SubmenuIndicator := '&#9658;';
    mMainMenu.Container := pnlMenu;
    mMainMenu.Font.Charset := DEFAULT_CHARSET;
    mMainMenu.Font.Color := clWindowText;
    mMainMenu.Font.Height := -11;
    mMainMenu.Font.Name := 'Tahoma';
    mMainMenu.Font.Style := [];
    mMainMenu.ParentFont := False;
    mMainMenu.Left := 72;
    mMainMenu.Top := 120;
    mnStammdaten.SetParentComponent(mMainMenu);
    mnStammdaten.Name := 'mnStammdaten';
    mnStammdaten.Caption := 'Stammdaten';
    mnStammdaten.Enabled := False;
    mnMitarbeiter.SetParentComponent(mnStammdaten);
    mnMitarbeiter.Name := 'mnMitarbeiter';
    mnMitarbeiter.Caption := 'Mitarbeiter';
    mnMitarbeiter.Enabled := False;
    SetEvent(mnMitarbeiter, Self, 'OnClick', 'mnMitarbeiterClick');
    mnVerwaltung.SetParentComponent(mMainMenu);
    mnVerwaltung.Name := 'mnVerwaltung';
    mnVerwaltung.Caption := 'Verwaltung';
    mnVerwaltung.Enabled := False;
    mnTau.SetParentComponent(mnVerwaltung);
    mnTau.Name := 'mnTau';
    mnTau.Caption := 'Transportauftr'#228'ge';
    SetEvent(mnTau, Self, 'OnClick', 'mnTauClick');
    mnBuchungen.SetParentComponent(mnVerwaltung);
    mnBuchungen.Name := 'mnBuchungen';
    mnBuchungen.Caption := 'Temp';
    SetEvent(mnBuchungen, Self, 'OnClick', 'mnBuchungenClick');
    mnSystem.SetParentComponent(mMainMenu);
    mnSystem.Name := 'mnSystem';
    mnSystem.Caption := 'System';
    mnSystem.Enabled := False;
    Passwortndern1.SetParentComponent(mnSystem);
    Passwortndern1.Name := 'Passwortndern1';
    Passwortndern1.Caption := 'Passwort '#228'ndern';
    N1.SetParentComponent(mnSystem);
    N1.Name := 'N1';
    N1.Caption := '-';
    Loganzeigen1.SetParentComponent(mnSystem);
    Loganzeigen1.Name := 'Loganzeigen1';
    Loganzeigen1.Caption := 'Log anzeigen';
    SetEvent(Loganzeigen1, Self, 'OnClick', 'Loganzeigen1Click');
    Abmelden1.SetParentComponent(mMainMenu);
    Abmelden1.Name := 'Abmelden1';
    Abmelden1.Caption := 'Abmelden';
    SetEvent(Abmelden1, Self, 'OnClick', 'Abmelden1Click');
    XDataWebClient.SetParentComponent(Self);
    XDataWebClient.Name := 'XDataWebClient';
    XDataWebClient.Connection := XDataConn;
    XDataWebClient.Left := 444;
    XDataWebClient.Top := 160;
    tabAnwAbw.SetParentComponent(Self);
    tabAnwAbw.Name := 'tabAnwAbw';
    tabAnwAbw.EntitySetName := 'ANWABW';
    tabAnwAbw.Connection := XDataConn;
    tabAnwAbw.Left := 448;
    tabAnwAbw.Top := 232;
    tabAnwAbwANWABW_NR.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_NR.Name := 'tabAnwAbwANWABW_NR';
    tabAnwAbwANWABW_NR.FieldName := 'ANWABW_NR';
    tabAnwAbwANWABW_BEZ.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_BEZ.Name := 'tabAnwAbwANWABW_BEZ';
    tabAnwAbwANWABW_BEZ.FieldName := 'ANWABW_BEZ';
    tabAnwAbwANWABW_TYP.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_TYP.Name := 'tabAnwAbwANWABW_TYP';
    tabAnwAbwANWABW_TYP.FieldName := 'ANWABW_TYP';
    tabAnwAbwANWABW_KURZ.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_KURZ.Name := 'tabAnwAbwANWABW_KURZ';
    tabAnwAbwANWABW_KURZ.FieldName := 'ANWABW_KURZ';
    tabAnwAbwANWABW_KURZ.Size := 5;
    tabAnwAbwANWABW_FARBE.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_FARBE.Name := 'tabAnwAbwANWABW_FARBE';
    tabAnwAbwANWABW_FARBE.FieldName := 'ANWABW_FARBE';
    tabAnwAbwANWABW_FARBE.Size := 30;
  finally
    pnlMenu.AfterLoadDFMValues;
    pnlMain.AfterLoadDFMValues;
    edtTemp.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    pnlStatus.AfterLoadDFMValues;
    lbWebTitel.AfterLoadDFMValues;
    lbBenutzer.AfterLoadDFMValues;
    pnlLog.AfterLoadDFMValues;
    mLog.AfterLoadDFMValues;
    btnShowLog.AfterLoadDFMValues;
    WebSplitter1.AfterLoadDFMValues;
    XDataConn.AfterLoadDFMValues;
    tmrJWTRenewal.AfterLoadDFMValues;
    tmrJWTRenewalWarning.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
    tmrCapture.AfterLoadDFMValues;
    mMainMenu.AfterLoadDFMValues;
    mnStammdaten.AfterLoadDFMValues;
    mnMitarbeiter.AfterLoadDFMValues;
    mnVerwaltung.AfterLoadDFMValues;
    mnTau.AfterLoadDFMValues;
    mnBuchungen.AfterLoadDFMValues;
    mnSystem.AfterLoadDFMValues;
    Passwortndern1.AfterLoadDFMValues;
    N1.AfterLoadDFMValues;
    Loganzeigen1.AfterLoadDFMValues;
    Abmelden1.AfterLoadDFMValues;
    XDataWebClient.AfterLoadDFMValues;
    tabAnwAbw.AfterLoadDFMValues;
    tabAnwAbwANWABW_NR.AfterLoadDFMValues;
    tabAnwAbwANWABW_BEZ.AfterLoadDFMValues;
    tabAnwAbwANWABW_TYP.AfterLoadDFMValues;
    tabAnwAbwANWABW_KURZ.AfterLoadDFMValues;
    tabAnwAbwANWABW_FARBE.AfterLoadDFMValues;
  end;
end;

end.


{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDateTimePicker;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, Types, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCTypes, WEBLib.TMSFNCDatePicker,
  WEBLib.TMSFNCDigitalTimePicker, WEBLib.TMSFNCAnalogTimePicker, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCUtils, WEBLib.TMSFNCStyles;

  const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 3; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.1.0 : New : Property AllowNumericNullValue added
  //         : New : Method Clear added
  //         : Fixed : Issue with OnDateTimeSelected event
  //v1.0.1.1 : Fixed : Issue with incorrect time selection
  //v1.0.1.2 : Fixed : Issue with control color in disabled state
  //v1.0.1.3 : Fixed : Issue with OnDateTimeSelected event after dropdown close

type
  TTMSFNCDateTimePickerMode = (dtpmAnalog, dtpmDigital);

  TTMSFNCCustomDateTimePickerDateTimeChangedEvent = procedure(Sender: TObject; ADateTime: TDateTime) of object;

  TTMSFNCCustomDateTimePicker = class(TTMSFNCCustomControl, ITMSFNCStylesManager)
  private
    FDatePicker: TTMSFNCDatePicker;
    FDigitalTimePicker: TTMSFNCDigitalTimePicker;
    FOnDateTimeChanged: TTMSFNCCustomDateTimePickerDateTimeChangedEvent;
    FEditable: Boolean;
    FTimePickerMode: TTMSFNCDateTimePickerMode;
    FCloseOnSelection: Boolean;
    FAnalogTimePicker: TTMSFNCAnalogTimePicker;
    FAllowNumericNullValue: Boolean;
    procedure SetSelectedDateTime(const Value: TDateTime);
    procedure SetEditable(const Value: Boolean);
    procedure SetTimePickerMode(const Value: TTMSFNCDateTimePickerMode);
    procedure SetCloseOnSelection(const Value: Boolean);
    procedure SetAllowNumericNullValue(const Value: Boolean);
    function GetSelectedDateTime: TDateTime;
  protected
    procedure Loaded; override;
    {$IFDEF FMXLIB}
    procedure SetEnabled(const Value: Boolean); override;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure SetEnabled(Value: Boolean); override;
    {$ENDIF}
    procedure UpdateControlAfterResize; override;
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure DateSelected(Sender: TObject; ADate: TDate);
    procedure TimeSelected(Sender: TObject; ATime: TTime);
    procedure DoDateTimeChanged(ADateTime: TDateTime); virtual;
    procedure ChangeMode(AMode: TTMSFNCDateTimePickerMode);
    function GetVersion: string; override;
    function GetSubComponentArray: TTMSFNCStylesManagerComponentArray;
    property AllowNumericNullValue: Boolean read FAllowNumericNullValue write SetAllowNumericNullValue default True;
    property Editable: Boolean read FEditable write SetEditable default False;
    property CloseOnSelection: Boolean read FCloseOnSelection write SetCloseOnSelection default True;
    property TimePickerMode: TTMSFNCDateTimePickerMode read FTimePickerMode write SetTimePickerMode default dtpmDigital;
    property SelectedDateTime: TDateTime read GetSelectedDateTime write SetSelectedDateTime;
    property OnDateTimeChanged: TTMSFNCCustomDateTimePickerDateTimeChangedEvent read FOnDateTimeChanged write FOnDateTimeChanged;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Clear;
    property DatePicker: TTMSFNCDatePicker read FDatePicker;
    property AnalogTimePicker: TTMSFNCAnalogTimePicker read FAnalogTimePicker;
    property DigitalTimePicker: TTMSFNCDigitalTimePicker read FDigitalTimePicker;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCDateTimePicker = class(TTMSFNCCustomDateTimePicker)
    property AllowNumericNullValue;
    property Editable;
    property CloseOnSelection;
    property TimePickerMode;
    property SelectedDateTime;
    property OnDateTimeChanged;
  end;

implementation

{ TTMSFNCCustomDateTimePicker }

procedure TTMSFNCCustomDateTimePicker.ChangeMode(
  AMode: TTMSFNCDateTimePickerMode);
var
  t: TTime;
begin
  if AMode = dtpmAnalog then
  begin
    t := 0;
    if Assigned(FDigitalTimePicker) then
    begin
      t := FDigitalTimePicker.SelectedTime;
      FDigitalTimePicker.Parent := nil;
    end;

    if not Assigned(FAnalogTimePicker) then
      FAnalogTimePicker := TTMSFNCAnalogTimePicker.Create(Self);

    FAnalogTimePicker.Parent := Self;
    {$IFDEF FMXLIB}
    FAnalogTimePicker.Stored := False;
    {$ENDIF}
    FAnalogTimePicker.Width := Round(Width / 2);
    FAnalogTimePicker.ControlAlignment := caRight;
    FAnalogTimePicker.SelectedTime := t;
    FAnalogTimePicker.Editable := FEditable;
    FAnalogTimePicker.CloseOnSelection := FCloseOnSelection;
    FAnalogTimePicker.OnTimeSelected := TimeSelected;
  end
  else if AMode = dtpmDigital then
  begin
    t := 0;
    if Assigned(FAnalogTimePicker) then
    begin
      t := FAnalogTimePicker.SelectedTime;
      FAnalogTimePicker.Parent := nil;
    end;

    if not Assigned(FDigitalTimePicker) then
      FDigitalTimePicker := TTMSFNCDigitalTimePicker.Create(Self);

    FDigitalTimePicker.Parent := Self;
    {$IFDEF FMXLIB}
    FDigitalTimePicker.Stored := False;
    {$ENDIF}
    FDigitalTimePicker.Width := Round(Width / 2);
    FDigitalTimePicker.ControlAlignment := caRight;
    FDigitalTimePicker.SelectedTime := t;
    FDigitalTimePicker.Editable := FEditable;
    FDigitalTimePicker.CloseOnSelection := FCloseOnSelection;
    FDigitalTimePicker.OnTimeSelected := TimeSelected;
  end;
end;

procedure TTMSFNCCustomDateTimePicker.Clear;
begin
  FDatePicker.Clear;

  if Assigned(FDigitalTimePicker) then
    FDigitalTimePicker.Clear;

  if Assigned(FAnalogTimePicker) then
    FAnalogTimePicker.Clear;
end;

constructor TTMSFNCCustomDateTimePicker.Create(AOwner: TComponent);
begin
  inherited;
  Width := 180;
  Height := 22;
  FEditable := False;
  FCloseOnSelection := True;
  FAllowNumericNullValue := True;
  FTimePickerMode := dtpmDigital;
  Fill.Color := MakeGraphicsColor(169, 169, 169);
  Stroke.Color := MakeGraphicsColor(169, 169, 169);

  FDatePicker := TTMSFNCDatePicker.Create(Self);
  FDatePicker.Parent := Self;
  {$IFDEF FMXLIB}
  FDatePicker.Stored := False;
  {$ENDIF}
  FDatePicker.ControlAlignment := caLeft;
  FDatePicker.Editable := FEditable;

  FDigitalTimePicker := TTMSFNCDigitalTimePicker.Create(Self);
  FDigitalTimePicker.Parent := Self;
  {$IFDEF FMXLIB}
  FDigitalTimePicker.Stored := False;
  {$ENDIF}
  FDigitalTimePicker.ControlAlignment := caRight;
  FDigitalTimePicker.Editable := FEditable;

  FAnalogTimePicker := nil;

  FDatePicker.OnDateSelected := DateSelected;
  FDigitalTimePicker.OnTimeSelected := TimeSelected;
end;

procedure TTMSFNCCustomDateTimePicker.DateSelected(Sender: TObject;
  ADate: TDate);
begin
  DoDateTimeChanged(SelectedDateTime);
//  if (FTimePickerMode = dtpmDigital) and Assigned(FDigitalTimePicker) then
//  begin
    //SelectedDateTime := ADate + FDigitalTimePicker.SelectedTime;
//    DoDateTimeChanged(SelectedDateTime);
//  end
//  else if (FTimePickerMode = dtpmAnalog) and Assigned(FAnalogTimePicker) then
//  begin
    //SelectedDateTime := ADate + FAnalogTimePicker.SelectedTime;
//    DoDateTimeChanged(SelectedDateTime);
//  end;
end;

destructor TTMSFNCCustomDateTimePicker.Destroy;
begin
  FDatePicker.Free;

  if Assigned(FDigitalTimePicker) then
    FDigitalTimePicker.Free;

  if Assigned(FAnalogTimePicker) then
    FAnalogTimePicker.Free;

  inherited;
end;

procedure TTMSFNCCustomDateTimePicker.DoDateTimeChanged(ADateTime: TDateTime);
begin
  if Assigned(OnDateTimeChanged) then
    OnDateTimeChanged(Self, ADateTime);
end;

function TTMSFNCCustomDateTimePicker.GetSelectedDateTime: TDateTime;
begin
  Result := 0.0;
  if Assigned(FDatePicker) then
  begin
    if Assigned(FDigitalTimePicker) then
      Result := FDatePicker.SelectedDate + FDigitalTimePicker.SelectedTime
    else if Assigned(FAnalogTimePicker) then
      Result := FDatePicker.SelectedDate + FAnalogTimePicker.SelectedTime
  end;
end;

function TTMSFNCCustomDateTimePicker.GetSubComponentArray: TTMSFNCStylesManagerComponentArray;
var
  arr: TTMSFNCStylesManagerComponentArray;
  m: TTMSFNCDateTimePickerMode;
begin
  SetLength(arr, 3);
  if not Assigned(FAnalogTimePicker) then
  begin
    m := TimePickerMode;
    TimePickerMode := dtpmAnalog;
    TimePickerMode := m;
  end;

  arr[0] := FDatePicker;
  arr[1] := FDigitalTimePicker;
  arr[2] := FAnalogTimePicker;

  Result := arr;
end;

function TTMSFNCCustomDateTimePicker.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomDateTimePicker.Loaded;
begin
  inherited;
  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION >= 33}
  if Assigned(FDigitalTimePicker) then
    FDigitalTimePicker.ScaleForPPI(CurrentPPI);

  if Assigned(FAnalogTimePicker) then
    FAnalogTimePicker.ScaleForPPI(CurrentPPI);

  FDatePicker.ScaleForPPI(CurrentPPI);
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}

end;

procedure TTMSFNCCustomDateTimePicker.SetAdaptToStyle(const Value: Boolean);
begin
  inherited;
  if Assigned(FDatePicker) then
    FDatePicker.AdaptToStyle := Value;

  if Assigned(FDigitalTimePicker) then
    FDigitalTimePicker.AdaptToStyle := Value;

  if Assigned(FAnalogTimePicker) then
    FAnalogTimePicker.AdaptToStyle := Value;
end;

procedure TTMSFNCCustomDateTimePicker.SetAllowNumericNullValue(
  const Value: Boolean);
begin
  if FAllowNumericNullValue <> Value then
  begin
    FAllowNumericNullValue := Value;

    if Assigned(FDatePicker) then
      FDatePicker.AllowNumericNullValue := Value;

    if Assigned(FDigitalTimePicker) then
      FDigitalTimePicker.AllowNumericNullValue := Value;

    if Assigned(FAnalogTimePicker) then
      FAnalogTimePicker.AllowNumericNullValue := Value;
  end;
end;

procedure TTMSFNCCustomDateTimePicker.SetCloseOnSelection(const Value: Boolean);
begin
  if FCloseOnSelection <> Value then
  begin
    FCloseOnSelection := Value;

    if Assigned(FDatePicker) then
      FDatePicker.CloseOnSelection := Value;

    if Assigned(FDigitalTimePicker) then
      FDigitalTimePicker.CloseOnSelection := Value;

    if Assigned(FAnalogTimePicker) then
      FAnalogTimePicker.CloseOnSelection := Value;
  end;
end;

procedure TTMSFNCCustomDateTimePicker.SetEditable(const Value: Boolean);
begin
  if FEditable <> Value then
  begin
    FEditable := Value;
    if Assigned(FDatePicker) then
      FDatePicker.Editable := Value;

    if Assigned(FDigitalTimePicker) then
      FDigitalTimePicker.Editable := Value;

    if Assigned(FAnalogTimePicker) then
      FAnalogTimePicker.Editable := Value;
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomDateTimePicker.SetEnabled(const Value: Boolean);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomDateTimePicker.SetEnabled(Value: Boolean);
{$ENDIF}
begin
  inherited;
  if Assigned(FDatePicker) then
    FDatePicker.Enabled := Value;

  if Assigned(FDigitalTimePicker) then
    FDigitalTimePicker.Enabled := Value;

  if Assigned(FAnalogTimePicker) then
    FAnalogTimePicker.Enabled := Value;

  Invalidate;
end;

procedure TTMSFNCCustomDateTimePicker.SetSelectedDateTime(
  const Value: TDateTime);
var
  tpv: TDateTime;
begin
  if Assigned(FDatePicker) then
  begin
    tpv := 0.0;
    FDatePicker.SelectedDate := Int(Value);

    if Assigned(FDigitalTimePicker) then
    begin
      tpv := Frac(Value);
      FDigitalTimePicker.SelectedTime := Frac(tpv);
    end;

    if Assigned(FAnalogTimePicker) then
    begin
      tpv := Frac(Value);
      FAnalogTimePicker.SelectedTime := Frac(tpv);
    end;

    if (FDatePicker.SelectedDate + tpv) <> Value then
      DoDateTimeChanged(Value);
  end;
end;

procedure TTMSFNCCustomDateTimePicker.SetTimePickerMode(
  const Value: TTMSFNCDateTimePickerMode);
begin
  if FTimePickerMode <> Value then
  begin
    FTimePickerMode := Value;
//    if IsDesignTime then
      ChangeMode(Value);
  end;
end;

procedure TTMSFNCCustomDateTimePicker.TimeSelected(Sender: TObject;
  ATime: TTime);
begin
  //SelectedDateTime := FDatePicker.SelectedDate + ATime;
  DoDateTimeChanged(SelectedDateTime);
end;

procedure TTMSFNCCustomDateTimePicker.UpdateControlAfterResize;
begin
  inherited;
  if Assigned(FDatePicker) then
    FDatePicker.Width := Round(Width / 2);

  if Assigned(FDigitalTimePicker) then
    FDigitalTimePicker.Width := Round(Width / 2);

  if Assigned(FAnalogTimePicker) then
    FAnalogTimePicker.Width := Round(Width / 2);
end;

end.

unit Tau;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  Vcl.Graphics, WEBLib.Buttons, WEBLib.Grids, Vcl.Grids, WEBLib.DBCtrls, XData.Web.Connection,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl, VCL.TMSFNCCustomPicker,
  VCL.TMSFNCComboBox, Vcl.Imaging.pngimage, XData.Web.Client, Bcl.Utils, CP.Func.AES,
  VCL.TMSFNCToolBar   ;

type
  TfrmTau = class(TForm)
    WebPanel1: TPanel;
    lbMitarbeiter: TLabel;
    WebPanel2: TPanel;
    XDataWebClient: TXDataWebClient;
    edtTemp: TEdit;
    tabTau: TXDataWebDataSet;
    tabTauMA_NR: TIntegerField;
    tabTauMA_NAME: TStringField;
    tabTauMA_VNAME: TStringField;
    dsTau: TDataSource;
    btnNavPrev: TTMSFNCToolBarButton;
    btnNavNext: TTMSFNCToolBarButton;
    btnNavInfo: TTMSFNCToolBarButton;

    DataSource1: TDataSource;
    tabTauBELADUNG_AM: TStringField;
    tabTauENTLADUNG_AM: TStringField;
    tabTauBELADESTELLE: TStringField;
    tabTauENTLADESTELLE: TStringField;
    tabTauBELADE_NAME: TStringField;
    tabTauENTLADE_NAME: TStringField;
    grdTau: TDBGrid;
    tabTauANZ_DOK: TStringField;
    procedure tabMaDatMA_GEBDATGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure grdMaDatFixedCellClick(Sender: TObject; ACol, ARow: LongInt);
    procedure edtFilterChange(Sender: TObject);
    [async] procedure btnEditClick(Sender: TObject); async;
    [async] procedure Daten_Laden( aID : Integer ); async;
    procedure WebButton1Click(Sender: TObject);
    procedure XDataWebClientLoad(Response: TXDataClientResponse);
    procedure dsMaDatDataChange(Sender: TObject; Field: TField);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure Do_DatenLaden(Sender: TObject);
    procedure btnNavInfoClick(Sender: TObject);
    [async] procedure grdTau2DblClick(Sender: TObject); async;
    [async] procedure btnTestClick(Sender: TObject); async;

  private
    bFirst : Boolean;
    iMaRec_Von : Integer;
    iMaRec_Bis : Integer;

    sSortFeldName : String;

  public


  protected procedure LoadDFMValues; override; end;

var frmTau: TfrmTau;

implementation

{$R *.dfm}

uses MitarbeiterEdit, UnitMain,  PzsDatenmodul;

//================================================================================================//

procedure TfrmTau.btnNavInfoClick(Sender: TObject);
var iID : Integer;
begin

  iMaRec_Von := 1;
  iMaRec_Bis := frmMainForm.g_RecAnzahl;

  iID := tabTau.FieldByName('ID_TAU').AsInteger;
  Daten_Laden(iID);

end;

//================================================================================================//
procedure TfrmTau.Daten_Laden( aID : Integer );
var Response: TXDataClientResponse;
    ClientConn: TXDataWebClient;
    Blob: JSValue;
    sDaten, sSQL, sTmp, sKopfZeile: String;
    aSL : TStringList;
    aStream : TStream;
    aTMP :  TJSObject;
    i : Integer;
    aArray : TArray<string>;
begin

  frmMainForm.LogAction('Start: Ma_Laden', false );

  btnNavInfo.Enabled := False;

  ClientConn := TXDataWebClient.Create(nil);
  ClientConn.Connection := frmMainForm.XDataConn;

       //             0         1            2             3              4             5                6
  sSQL := 'SELECT T.ID_TAU, T.REF_NR, T.KND_AUFTR_NR, T.BELADUNG_AM, T.ENTLADUNG_AM, T.FRACHT_FREMD, T.PAL_TAUSCH, '
         +'   '
         +' (SELECT RNGREF FROM LFTRNG R WHERE T.ID_LFTRNG=R.ID_LFTRNG ) as LFT_RNG, '   //  7
         +' (SELECT ADR_TEXT  FROM GET_ADRESSE( BELADUNG_ID_ADR)) AS BELADESTELLE, '     //  8
         +' (SELECT ADR_TEXT  FROM GET_ADRESSE( ENTLADUNG_ID_ADR)) AS ENTLADESTELLE, '   //  9
         +' (SELECT ADR_NAME1 FROM GET_ADRESSE( BELADUNG_ID_ADR))  AS BELADE_NAME, '      // 10
         +' (SELECT ADR_NAME1 FROM GET_ADRESSE( ENTLADUNG_ID_ADR)) AS ENTLADE_NAME, '    //  11
         +' (SELECT COUNT(ID_DOKUMENT) FROM DOKUMENT D WHERE D.ID_DOK_BEZ=T.ID_DOK_BEZ) AS ANZ_DOK, '
         +' (SELECT FIRST 1 KENNZEICHEN FROM  TOUR_POS TP, TOUR R, FAHRZEUG F WHERE TP.ID_TAU=T.ID_TAU  AND TP.ID_TOUR=R.ID_TOUR AND R.ID_FAHRZEUG1=F.ID_FAHRZEUG) as FZG1, '
         +' (SELECT FIRST 1 KENNZEICHEN FROM  TOUR_POS TP, TOUR R, FAHRZEUG F WHERE TP.ID_TAU=T.ID_TAU  AND TP.ID_TOUR=R.ID_TOUR AND R.ID_FAHRZEUG2=F.ID_FAHRZEUG) as FZG2, '
         +' (SELECT NAME1 FROM ADRESSE A WHERE K.ID_ADRESSE=A.ID_ADRESSE) as Kunde, '   // 15
         +' (SELECT BEZEICHNUNG FROM UEBENE1 U1 WHERE U1.ID_UEBENE1=T.ID_UEBENE1) as UEBENE1, '    // 16
         +' (SELECT TOURNAME FROM TOUR_NAME N WHERE N.ID_TOUR_NAME=T.ID_TOUR_NAME ) as TOURNAME, '  // 17
         +' (SELECT NAME1 FROM LIEFERANT L, ADRESSE A  WHERE L.ID_ADRESSE=A.ID_ADRESSE AND T.ID_LFT_FREMD=L.ID_LIEFERANT) as LFT_FREMD, '



         +'  T.ID_LFT_FREMD,  T.ID_KUNDE, T.ID_DOK_BEZ  '
         + ' FROM TAU T, KUNDE K WHERE T.ID_KUNDE=K.ID_KUNDE ';

   If frmMainForm.BenutzerLftID <> '' then  sSQL := sSQL + ' AND T.ID_LFT_FREMD = '+ frmMainForm.BenutzerLftID;

  sSQL := sSQL + ' ORDER BY T.BELADUNG_AM DESC ';

  Response :=
   await( ClientConn.RawInvokeAsync('ISystemService.GetDaten', [ sSQL, 'CSV',iMaRec_Von,iMaRec_Bis] ) );


  Blob := Response.Result;
  asm sDaten = await Blob.text(); end;

  ClientConn.Free;


  //------------------------ Daten jetzt noch entschlüsseln
  edtTemp.Text := sDaten;

  frmMainForm.LogAction('   1 :  TfrmTau.Daten_Laden',false);

  TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(edtTemp.Text),
    procedure(const AEncrypted: string)
      begin

        aSL := TStringList.Create;
        aSL.Text := AEncrypted; // mLog.Lines.Text;


        tabTau.Close;
        tabTau.Open;

        sKopfZeile := aSL[0];
        aArray := sKopfZeile.Split([';']);
        btnNavInfo.Text := aArray[3]+'-'+aArray[4]+' ['+aArray[2]+']';


        For i := 2 to aSL.Count -1 Do
            Begin
             sTmp := aSL[i];
              aArray := sTmp.Split([';']);
              tabTau.Append;
              tabTau.FieldByName('ID_TAU').AsInteger     := StrToIntDef(aArray[0],0);
              tabTau.FieldByName('REF_NR').AsString  := aArray[1];
              tabTau.FieldByName('KND_AUFTR_NR').AsString  := aArray[2];
              tabTau.FieldByName('BELADUNG_AM').AsString   := aArray[3];
              tabTau.FieldByName('ENTLADUNG_AM').AsString  := aArray[4];
              tabTau.FieldByName('BELADESTELLE').AsString  := aArray[8];
              tabTau.FieldByName('ENTLADESTELLE').AsString := aArray[9];
              tabTau.FieldByName('BELADE_NAME').AsString  := aArray[10];
              tabTau.FieldByName('ENTLADE_NAME').AsString := aArray[11];
              tabTau.FieldByName('ANZ_DOK').AsString := aArray[12];

              tabTau.Post;
            End;

        aSL.Free;

        If aID = 0 then tabTau.First;
        If aID > 0 then
          If not tabTau.Locate('ID_TAU',aID,[]) then tabTau.First;

        btnNavNext.Enabled := (tabTau.RecordCount=frmMainForm.g_RecAnzahl );
        btnNavInfo.Enabled := True;

        frmMainForm.LogAction('Ende:  TfrmTau.Daten_Laden',False);

      end,
    procedure(const AError: string)
     begin
      ShowMessage(AError);
     end);

end;

//================================================================================================//

procedure TfrmTau.Do_DatenLaden(Sender: TObject);
begin

  If Sender = btnNavNext then
    Begin
     iMaRec_Von := iMaRec_Von + frmMainForm.g_RecAnzahl;
    End
  Else
    Begin
     iMaRec_Von := iMaRec_Von - frmMainForm.g_RecAnzahl;
     If iMaRec_Von < 1 then iMaRec_Von := 1;
    End;
  iMaRec_Bis := iMaRec_Von + frmMainForm.g_RecAnzahl-1;

  Daten_Laden(0);

end;

//================================================================================================//

procedure TfrmTau.dsMaDatDataChange(Sender: TObject; Field: TField);
begin

end;

//================================================================================================//

procedure TfrmTau.edtFilterChange(Sender: TObject);
begin


end;

//================================================================================================//

procedure TfrmTau.grdMaDatFixedCellClick(Sender: TObject; ACol, ARow: LongInt);
var sFeldName : String;
begin

  If aRow > 0 then Exit;

  sSortFeldName := TGridColumn( grdTau.Columns[aCol] ).DataField ;

//  Daten_Laden( tabMaDatMA_NR.AsInteger ) ;

end;

//================================================================================================//

procedure TfrmTau.grdTau2DblClick(Sender: TObject);
 var sMaName, sZuname, sVorname, sZeitStempel : String;
     dtZP : TDateTime;
     iID, iMaNr, iGrund : Integer;
     Response: TXDataClientResponse;
begin

//  iMaNr  := tabMitarbeiterMA_NR.AsInteger;
//
//
//  If iMaNr=0 then Exit;
//
//  sMaName :=  tabMitarbeiterMA_NAME.AsString+', '
//            + tabMitarbeiterMA_VNAME.AsString;
////
//  frmMitarbeiterEdit := TfrmMitarbeiterEdit.Create(Self);
//  frmMitarbeiterEdit.Caption := 'Mitarbeiter bearbeiten: '+sMaName;
//  frmMitarbeiterEdit.iMaNr  := iMaNr;
//  frmMitarbeiterEdit.Popup  := True;
//  frmMitarbeiterEdit.Border := fbDialogSizeable;
//
////  await( frmMainForm.AnwAbw_Laden );
//
//  // used to manage Back button handling to close subform
//  window.location.hash := 'subform';
//
//frmMainForm.LogAction('Klick 1',False);
//
//  // load file HTML template + controls
//  await( TfrmMitarbeiterEdit, frmMitarbeiterEdit.Load() );
//
//frmMainForm.LogAction('Klick 2',False);
//
//  await( frmMitarbeiterEdit.Daten_Ins_Formular );
//
//frmMainForm.LogAction('Klick 3',False);

//  Try
    // excute form and wait for close
//    await( TModalResult, frmMitarbeiterEdit.Execute);
//
//    // Änderungen speichern
//    If frmBuchungEdit.ModalResult = mrOK then
//      Begin
//        dtZP := Trunc(frmBuchungEdit.edtDatum.Date) + Frac( frmBuchungEdit.edtZeit.DateTime );
//        await(tabZeitBuchung.Edit);
//        tabZeitBuchung.FieldByName('BU_TISTAMP').AsString := DateTimeToStr(dtZP);
//
//        Case frmBuchungEdit.cbxGrund.ItemIndex Of
//          0 : iGrund := 3;
//          1 : iGrund := 1;
//          2 : iGrund := 2;
//          3 : iGrund := 4;
//        End;
//        tabZeitBuchung.FieldByName('ANWABW_NR').AsInteger := iGrund;
//        tabZeitBuchung.FieldByName('ANWABW_BEZ').AsString := frmMainForm.Get_AnwAbw_Bez(iGrund);
//
//        await(tabZeitBuchung.Post);
//
//        //in die Datenbank übernehmen
//        Response := await( XDataWebClient.RawInvokeAsync('ISystemService.BuchungUpd', [ iID, iGrund, DateTimeToStr(dtZP) ] ));
//
//      End;
//
//  Finally
//    frmMitarbeiterEdit.Free;
//    mLog.Lines.Add('frmMitarbeiterEdit.free')
//  End;

end;

//================================================================================================//

procedure TfrmTau.tabMaDatMA_GEBDATGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin

//  If TDateTimeField(Sender).AsDateTime=0 then Text := ''
//  Else Text := '  '+DateTimeToStr(TDateTimeField(Sender).AsDateTime);

end;

//================================================================================================//

procedure TfrmTau.btnTestClick(Sender: TObject);
begin


end;

//================================================================================================//

procedure TfrmTau.WebButton1Click(Sender: TObject);
begin



end;

//================================================================================================//

procedure TfrmTau.WebButton3Click(Sender: TObject);
begin

end;

//================================================================================================//

procedure TfrmTau.WebFormCreate(Sender: TObject);
begin

  bFirst := False;

  XDataWebClient.Connection   := frmMainForm.XDataConn;

  iMaRec_Von := 1;
  iMaRec_Bis := frmMainForm.g_RecAnzahl;

end;

//================================================================================================//

procedure TfrmTau.WebFormShow(Sender: TObject);
begin
  Daten_Laden(0);
end;

//================================================================================================//

procedure TfrmTau.XDataWebClientLoad(Response: TXDataClientResponse);
var Mab: TJSObject;
begin

//  If Response.RequestId = 'list' then
//    mLog.Lines.Text := TJSJson.stringify(Response.Result)
//  Else
//    Begin
//      // Both lines below are equivalent.
//      Mab := TJSObject(Response.Result);
//      Mab := Response.ResultAsObject;
//
//      mLog.Lines.Add( Response.ResponseText );
//
//    End;
end;

//================================================================================================//

procedure TfrmTau.btnEditClick(Sender: TObject);
 var sMaName : String;
begin

//  sMaName :=  tabMitarbeiter.FieldbyName('MA_Name').AsString+', '
//            + tabMitarbeiter.FieldbyName('MA_VName').AsString;
//
//  frmMitarbeiterEdit := TfrmMitarbeiterEdit.Create(Self);
//  frmMitarbeiterEdit.Caption := 'Mitarbeiter bearbeiten: '+sMaName;
//  frmMitarbeiterEdit.Popup := True;
//  frmMitarbeiterEdit.Border := fbDialog;
//
//  // used to manage Back button handling to close subform
//  //  window.location.hash := 'subform';
//
//  // load file HTML template + controls
//  await( TfrmMitarbeiterEdit, frmMitarbeiterEdit.Load() );
//
//  // init control after loading
//  frmMitarbeiterEdit.edtZuname.Text  := tabMitarbeiter.FieldbyName('MA_Name').AsString;
//  frmMitarbeiterEdit.edtVorname.Text := tabMitarbeiter.FieldbyName('MA_VNAME').AsString;
//
//  try
//    // excute form and wait for close
//    await( TModalResult, frmMitarbeiterEdit.Execute);
//
//    If frmMitarbeiterEdit.ModalResult = mrOK then
//      Begin
//        await(tabMitarbeiter.Edit);
//        tabMitarbeiter.FieldByName('MA_Name').AsString  := frmMitarbeiterEdit.edtZuname.Text;
//        tabMitarbeiter.FieldByName('MA_VNAME').AsString := frmMitarbeiterEdit.edtVorname.Text;
//        await(tabMitarbeiter.Post);
//        //await(tabMaDat.ApplyUpdates);
//      End;
//
//  Finally
//    frmMitarbeiterEdit.Free;
//  End;
//
//
// Daten_Laden(0);


end;

//================================================================================================//

procedure TfrmTau.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  lbMitarbeiter := TLabel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  edtTemp := TEdit.Create(Self);
  btnNavPrev := TTMSFNCToolBarButton.Create(Self);
  btnNavNext := TTMSFNCToolBarButton.Create(Self);
  btnNavInfo := TTMSFNCToolBarButton.Create(Self);
  grdTau := TDBGrid.Create(Self);
  XDataWebClient := TXDataWebClient.Create(Self);
  tabTau := TXDataWebDataSet.Create(Self);
  tabTauMA_NAME := TStringField.Create(Self);
  tabTauBELADUNG_AM := TStringField.Create(Self);
  tabTauENTLADUNG_AM := TStringField.Create(Self);
  tabTauBELADE_NAME := TStringField.Create(Self);
  tabTauBELADESTELLE := TStringField.Create(Self);
  tabTauENTLADE_NAME := TStringField.Create(Self);
  tabTauENTLADESTELLE := TStringField.Create(Self);
  tabTauMA_VNAME := TStringField.Create(Self);
  tabTauANZ_DOK := TStringField.Create(Self);
  tabTauMA_NR := TIntegerField.Create(Self);
  dsTau := TDataSource.Create(Self);
  DataSource1 := TDataSource.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  lbMitarbeiter.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  edtTemp.BeforeLoadDFMValues;
  btnNavPrev.BeforeLoadDFMValues;
  btnNavNext.BeforeLoadDFMValues;
  btnNavInfo.BeforeLoadDFMValues;
  grdTau.BeforeLoadDFMValues;
  XDataWebClient.BeforeLoadDFMValues;
  tabTau.BeforeLoadDFMValues;
  tabTauMA_NAME.BeforeLoadDFMValues;
  tabTauBELADUNG_AM.BeforeLoadDFMValues;
  tabTauENTLADUNG_AM.BeforeLoadDFMValues;
  tabTauBELADE_NAME.BeforeLoadDFMValues;
  tabTauBELADESTELLE.BeforeLoadDFMValues;
  tabTauENTLADE_NAME.BeforeLoadDFMValues;
  tabTauENTLADESTELLE.BeforeLoadDFMValues;
  tabTauMA_VNAME.BeforeLoadDFMValues;
  tabTauANZ_DOK.BeforeLoadDFMValues;
  tabTauMA_NR.BeforeLoadDFMValues;
  dsTau.BeforeLoadDFMValues;
  DataSource1.BeforeLoadDFMValues;
  try
    Name := 'frmTau';
    Width := 1436;
    Height := 533;
    Color := 16574684;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1436;
    WebPanel1.Height := 35;
    WebPanel1.Align := alTop;
    WebPanel1.Color := 16506058;
    WebPanel1.TabOrder := 0;
    lbMitarbeiter.SetParentComponent(WebPanel1);
    lbMitarbeiter.Name := 'lbMitarbeiter';
    lbMitarbeiter.Left := 16;
    lbMitarbeiter.Top := 6;
    lbMitarbeiter.Width := 173;
    lbMitarbeiter.Height := 23;
    lbMitarbeiter.Caption := 'Transportauftr'#228'ge';
    lbMitarbeiter.Font.Charset := DEFAULT_CHARSET;
    lbMitarbeiter.Font.Color := clBlack;
    lbMitarbeiter.Font.Height := -19;
    lbMitarbeiter.Font.Name := 'Tahoma';
    lbMitarbeiter.Font.Style := [fsBold];
    lbMitarbeiter.HeightPercent := 100.000000000000000000;
    lbMitarbeiter.ParentFont := False;
    lbMitarbeiter.WidthPercent := 100.000000000000000000;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 35;
    WebPanel2.Width := 34;
    WebPanel2.Height := 498;
    WebPanel2.Align := alLeft;
    WebPanel2.ChildOrder := 2;
    WebPanel2.Color := 16506058;
    WebPanel2.TabOrder := 1;
    edtTemp.SetParentComponent(Self);
    edtTemp.Name := 'edtTemp';
    edtTemp.Left := 224;
    edtTemp.Top := 41;
    edtTemp.Width := 121;
    edtTemp.Height := 22;
    edtTemp.ChildOrder := 23;
    edtTemp.HeightPercent := 100.000000000000000000;
    edtTemp.Text := 'edtTemp';
    edtTemp.Visible := False;
    edtTemp.WidthPercent := 100.000000000000000000;
    btnNavPrev.SetParentComponent(Self);
    btnNavPrev.Name := 'btnNavPrev';
    btnNavPrev.Left := 40;
    btnNavPrev.Top := 40;
    btnNavPrev.Width := 33;
    btnNavPrev.Height := 30;
    btnNavPrev.Cursor := crHandPoint;
    btnNavPrev.ParentDoubleBuffered := False;
    btnNavPrev.DoubleBuffered := True;
    btnNavPrev.Font.Charset := DEFAULT_CHARSET;
    btnNavPrev.Font.Color := clWindowText;
    btnNavPrev.Font.Height := -11;
    btnNavPrev.Font.Name := 'Segoe UI';
    btnNavPrev.Font.Style := [];
    btnNavPrev.ParentColor := True;
    btnNavPrev.TabOrder := 3;
    SetEvent(btnNavPrev, Self, 'OnClick', 'Do_DatenLaden');
    btnNavPrev.Text := '';
    btnNavPrev.Bitmaps.Clear;
    with btnNavPrev.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Tau.btnNavPrev.Bitmaps.Bitmap.png');
    end;
    btnNavPrev.BitmapVisible := True;
    btnNavPrev.ControlIndex := 0;
    btnNavNext.SetParentComponent(Self);
    btnNavNext.Name := 'btnNavNext';
    btnNavNext.Left := 75;
    btnNavNext.Top := 40;
    btnNavNext.Width := 33;
    btnNavNext.Height := 30;
    btnNavNext.Cursor := crHandPoint;
    btnNavNext.ParentDoubleBuffered := False;
    btnNavNext.DoubleBuffered := True;
    btnNavNext.Font.Charset := DEFAULT_CHARSET;
    btnNavNext.Font.Color := clWindowText;
    btnNavNext.Font.Height := -11;
    btnNavNext.Font.Name := 'Segoe UI';
    btnNavNext.Font.Style := [];
    btnNavNext.ParentColor := True;
    btnNavNext.TabOrder := 4;
    SetEvent(btnNavNext, Self, 'OnClick', 'Do_DatenLaden');
    btnNavNext.Text := '';
    btnNavNext.Bitmaps.Clear;
    with btnNavNext.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Tau.btnNavNext.Bitmaps.Bitmap.png');
    end;
    btnNavNext.BitmapVisible := True;
    btnNavNext.ControlIndex := 0;
    btnNavInfo.SetParentComponent(Self);
    btnNavInfo.Name := 'btnNavInfo';
    btnNavInfo.Left := 111;
    btnNavInfo.Top := 40;
    btnNavInfo.Width := 94;
    btnNavInfo.Height := 30;
    btnNavInfo.Hint := 'Daten laden';
    btnNavInfo.ParentDoubleBuffered := False;
    btnNavInfo.DoubleBuffered := True;
    btnNavInfo.Font.Charset := DEFAULT_CHARSET;
    btnNavInfo.Font.Color := clWindowText;
    btnNavInfo.Font.Height := -11;
    btnNavInfo.Font.Name := 'Segoe UI';
    btnNavInfo.Font.Style := [];
    btnNavInfo.ParentColor := True;
    btnNavInfo.TabOrder := 5;
    SetEvent(btnNavInfo, Self, 'OnClick', 'btnNavInfoClick');
    btnNavInfo.Text := '1 - 50 [254]';
    btnNavInfo.ControlIndex := 0;
    grdTau.SetParentComponent(Self);
    grdTau.Name := 'grdTau';
    grdTau.Left := 40;
    grdTau.Top := 76;
    grdTau.Width := 1373;
    grdTau.Height := 437;
    grdTau.Anchors := [akLeft,akTop,akRight,akBottom];
    grdTau.Columns.Clear;
    with grdTau.Columns.Add do
    begin
      Alignment := taCenter;
      DataField := 'REF_NR';
      Title := 'Tau-Nr';
      Width := 104;
    end;
    with grdTau.Columns.Add do
    begin
      Alignment := taCenter;
      DataField := 'BELADUNG_AM';
      Title := 'Beladung am';
      Width := 130;
    end;
    with grdTau.Columns.Add do
    begin
      Alignment := taCenter;
      DataField := 'ENTLADUNG_AM';
      Title := 'Entladung am';
      Width := 130;
    end;
    with grdTau.Columns.Add do
    begin
      DataField := 'BELADE_NAME';
      Title := 'Beladename';
      Width := 109;
    end;
    with grdTau.Columns.Add do
    begin
      DataField := 'BELADESTELLE';
      Title := 'Beladestelle';
      Width := 200;
    end;
    with grdTau.Columns.Add do
    begin
      DataField := 'ENTLADE_NAME';
      Title := 'Entladename';
      Width := 129;
    end;
    with grdTau.Columns.Add do
    begin
      DataField := 'ENTLADESTELLE';
      Title := 'Entladestelle';
      Width := 200;
    end;
    with grdTau.Columns.Add do
    begin
      DataField := 'KND_AUFTR_NR';
      Title := 'Knd.Auftrag';
      Width := 154;
    end;
    with grdTau.Columns.Add do
    begin
      Alignment := taCenter;
      DataField := 'ANZ_DOK';
      Title := 'Anz.Dok.';
      Width := 77;
    end;
    with grdTau.Columns.Add do
    begin
      Alignment := taCenter;
      DataField := 'ID_TAU';
      Editor := geNumber;
      Title := 'ID';
    end;
    grdTau.DataSource := dsTau;
    grdTau.ElementFont := efCSS;
    grdTau.FixedFont.Charset := DEFAULT_CHARSET;
    grdTau.FixedFont.Color := clWindowText;
    grdTau.FixedFont.Height := -12;
    grdTau.FixedFont.Name := 'Segoe UI';
    grdTau.FixedFont.Style := [];
    grdTau.FixedCols := 1;
    grdTau.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSizing,goColSizing,goRowSelect,goFixedRowDefAlign];
    grdTau.TabOrder := 6;
    grdTau.HeightPercent := 100.000000000000000000;
    grdTau.WidthPercent := 100.000000000000000000;
    grdTau.ColWidths[0] := 24;
    grdTau.ColWidths[1] := 104;
    grdTau.ColWidths[2] := 130;
    grdTau.ColWidths[3] := 130;
    grdTau.ColWidths[4] := 109;
    grdTau.ColWidths[5] := 200;
    grdTau.ColWidths[6] := 129;
    grdTau.ColWidths[7] := 200;
    grdTau.ColWidths[8] := 154;
    grdTau.ColWidths[9] := 77;
    grdTau.ColWidths[10] := 64;
    XDataWebClient.SetParentComponent(Self);
    XDataWebClient.Name := 'XDataWebClient';
    XDataWebClient.Left := 272;
    XDataWebClient.Top := 208;
    tabTau.SetParentComponent(Self);
    tabTau.Name := 'tabTau';
    tabTau.Left := 144;
    tabTau.Top := 168;
    tabTauMA_NAME.SetParentComponent(tabTau);
    tabTauMA_NAME.Name := 'tabTauMA_NAME';
    tabTauMA_NAME.Alignment := taCenter;
    tabTauMA_NAME.DisplayLabel := 'Tau-Nr';
    tabTauMA_NAME.DisplayWidth := 12;
    tabTauMA_NAME.FieldName := 'REF_NR';
    tabTauMA_NAME.Required := True;
    tabTauBELADUNG_AM.SetParentComponent(tabTau);
    tabTauBELADUNG_AM.Name := 'tabTauBELADUNG_AM';
    tabTauBELADUNG_AM.Alignment := taCenter;
    tabTauBELADUNG_AM.FieldName := 'BELADUNG_AM';
    tabTauBELADUNG_AM.Size := 10;
    tabTauENTLADUNG_AM.SetParentComponent(tabTau);
    tabTauENTLADUNG_AM.Name := 'tabTauENTLADUNG_AM';
    tabTauENTLADUNG_AM.Alignment := taCenter;
    tabTauENTLADUNG_AM.FieldName := 'ENTLADUNG_AM';
    tabTauENTLADUNG_AM.Size := 10;
    tabTauBELADE_NAME.SetParentComponent(tabTau);
    tabTauBELADE_NAME.Name := 'tabTauBELADE_NAME';
    tabTauBELADE_NAME.FieldName := 'BELADE_NAME';
    tabTauBELADE_NAME.Size := 50;
    tabTauBELADESTELLE.SetParentComponent(tabTau);
    tabTauBELADESTELLE.Name := 'tabTauBELADESTELLE';
    tabTauBELADESTELLE.FieldName := 'BELADESTELLE';
    tabTauBELADESTELLE.Size := 120;
    tabTauENTLADE_NAME.SetParentComponent(tabTau);
    tabTauENTLADE_NAME.Name := 'tabTauENTLADE_NAME';
    tabTauENTLADE_NAME.FieldName := 'ENTLADE_NAME';
    tabTauENTLADE_NAME.Size := 50;
    tabTauENTLADESTELLE.SetParentComponent(tabTau);
    tabTauENTLADESTELLE.Name := 'tabTauENTLADESTELLE';
    tabTauENTLADESTELLE.FieldName := 'ENTLADESTELLE';
    tabTauENTLADESTELLE.Size := 120;
    tabTauMA_VNAME.SetParentComponent(tabTau);
    tabTauMA_VNAME.Name := 'tabTauMA_VNAME';
    tabTauMA_VNAME.DisplayLabel := 'Vorname';
    tabTauMA_VNAME.DisplayWidth := 20;
    tabTauMA_VNAME.FieldName := 'KND_AUFTR_NR';
    tabTauMA_VNAME.Size := 100;
    tabTauANZ_DOK.SetParentComponent(tabTau);
    tabTauANZ_DOK.Name := 'tabTauANZ_DOK';
    tabTauANZ_DOK.Alignment := taCenter;
    tabTauANZ_DOK.FieldName := 'ANZ_DOK';
    tabTauANZ_DOK.Size := 2;
    tabTauMA_NR.SetParentComponent(tabTau);
    tabTauMA_NR.Name := 'tabTauMA_NR';
    tabTauMA_NR.Alignment := taCenter;
    tabTauMA_NR.DisplayWidth := 6;
    tabTauMA_NR.FieldName := 'ID_TAU';
    tabTauMA_NR.Required := True;
    dsTau.SetParentComponent(Self);
    dsTau.Name := 'dsTau';
    dsTau.DataSet := tabTau;
    dsTau.Left := 80;
    dsTau.Top := 176;
    DataSource1.SetParentComponent(Self);
    DataSource1.Name := 'DataSource1';
    DataSource1.DataSet := tabTau;
  finally
    WebPanel1.AfterLoadDFMValues;
    lbMitarbeiter.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    edtTemp.AfterLoadDFMValues;
    btnNavPrev.AfterLoadDFMValues;
    btnNavNext.AfterLoadDFMValues;
    btnNavInfo.AfterLoadDFMValues;
    grdTau.AfterLoadDFMValues;
    XDataWebClient.AfterLoadDFMValues;
    tabTau.AfterLoadDFMValues;
    tabTauMA_NAME.AfterLoadDFMValues;
    tabTauBELADUNG_AM.AfterLoadDFMValues;
    tabTauENTLADUNG_AM.AfterLoadDFMValues;
    tabTauBELADE_NAME.AfterLoadDFMValues;
    tabTauBELADESTELLE.AfterLoadDFMValues;
    tabTauENTLADE_NAME.AfterLoadDFMValues;
    tabTauENTLADESTELLE.AfterLoadDFMValues;
    tabTauMA_VNAME.AfterLoadDFMValues;
    tabTauANZ_DOK.AfterLoadDFMValues;
    tabTauMA_NR.AfterLoadDFMValues;
    dsTau.AfterLoadDFMValues;
    DataSource1.AfterLoadDFMValues;
  end;
end;

end.


{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2019	- 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCComboBox;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, Types, WEBLib.Controls,
  {$IFDEF FMXLIB}
  FMX.Types,
  {$ENDIF}
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  UITypes,
  {$ENDIF}
  {$ENDIF}
  WEBLib.TMSFNCCustomPicker, WEBLib.TMSFNCCustomSelector,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCTreeView, WEBLib.TMSFNCTreeViewData,
  WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCTypes, WEBLib.TMSFNCBitmapContainer,
  WEBLib.TMSFNCCustomTreeView;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 1; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 4; // Build nr.

  // Version history
  // v1.0.0.0 : first release
  // v1.0.0.1 : Fixed : Issue with control color in disabled state
  // v1.0.0.2 : Improved : Exposed DropDownWidth property
  // v1.0.0.3 : Fixed : Issue with ItemIndex
  // v1.0.0.4 : Fixed : Published OnItemSelected
  // v1.0.0.5 : Fixed : Issue with getting the text of an item
  // v1.0.0.6 : Improved : Exposed ShowFocus property
  // v1.0.1.0 : New : Added BitampContainer property
  // v1.0.2.0 : New : Added AutoFilter property
  // v1.1.0.0 : New : Appearance changed to ItemAppearance and selector Appearance added
  // v1.1.0.1 : Improved : Showing selected item in the dropdown
  // v1.1.0.2 : Fixed : Removed return key dropdown toggle on mobile platforms
  //          : Fixed : Scrollbar click on Windows caused dropdown closing
  // v1.1.0.3 : Fixed : Several conditions where OnItemSelected was not triggered
  // v1.1.0.4 : Fixed : Images not drawn as selected item

type
  TTMSFNCTreeViewOpen = class(TTMSFNCTreeView);
  TTMSFNCCustomComboBoxStyle = (csDropDown, csDropDownList);
  TTMSFNCCustomComboBoxItemSelectedEvent = procedure(Sender: TObject; AText: string; AItemIndex: Integer) of object;

  TTMSFNCCustomComboBox = class(TTMSFNCDefaultPicker, ITMSFNCBitmapContainer)
  private
    FWrapper: TTMSFNCCustomSelector;
    FTreeView: TTMSFNCTreeView;
    FItems: TStringList;
    FStyle: TTMSFNCCustomComboBoxStyle;
    FAutoCloseUp: Boolean;
    FAutoDropDown: Boolean;
    FAutoComplete: Boolean;
    FDropDownCount: Integer;
    FPrevString: string;
    FPrevIndex: Integer;
    FCaseSensitive: Boolean;
    FAutoCompleteNumChar: Integer;
    FAutoCompleteDelay: Integer;
    FTick: Cardinal;
    FKeyDown: Word;
    FOnItemSelected: TTMSFNCCustomComboBoxItemSelectedEvent;
    FFont: TTMSFNCGraphicsFont;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FAutoFilter: Boolean;
    procedure SetItemIndex(const Value: Integer);
    procedure SetItems(const Value: TStringList);
    procedure SetStyle(const Value: TTMSFNCCustomComboBoxStyle);
    procedure SetAutoCloseUp(const Value: Boolean);
    procedure SetAutoDropDown(const Value: Boolean);
    procedure SetAutoComplete(const Value: Boolean);
    procedure SetDropDownCount(const Value: Integer);
    procedure SetItemHeight(const Value: Double);
    procedure SetText(const Value: string);
    procedure SetCaseSensitive(const Value: Boolean);
    procedure SetAutoCompleteNumChar(const Value: Integer);
    procedure SetAutoCompleteDelay(const Value: Integer);
    function GetItemIndex: Integer;
    function GetItemHeight: Double;
    function GetText: string;
    function GetCount: Integer;
    procedure SetFnt(const Value: TTMSFNCGraphicsFont);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetAutoFilter(const Value: Boolean);
    function GetItemAppearance: TTMSFNCTreeViewNodesAppearance;
    procedure SetItemAppearance(const Value: TTMSFNCTreeViewNodesAppearance);
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure FontChange(Sender: TObject);
    procedure Loaded; override;
    procedure DoComboBoxItemSelected(AText: string; AItemIndex: Integer); virtual;
    procedure DrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure InitializePopup; override;
    procedure SelectFirstValue; override;
    procedure SelectLastValue; override;
    procedure SelectNextValue; override;
    procedure SelectPreviousValue; override;
    procedure ItemsChanged(Sender: TObject);
    procedure SetAdaptToStyle(const Value: Boolean); override;
    procedure ComboBoxNodeTextAlign(Sender: TObject; {%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
    procedure ComboBoxItemClicked(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure AdjustDropDownHeight;
    procedure UpdateListBoxLookup(AText: string);
    procedure DeleteFromEdit;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    {$IFDEF FMXLIB}
    procedure EditKeyDown(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
    procedure TreeviewKeyUp(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
    procedure KeyDown(var Key: Word; var KeyChar: WideChar; Shift: TShiftState); override;
    procedure TreeViewMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Single);
    {$IFDEF ANDROID}
    procedure EditTyping(Sender: TObject);
    {$ENDIF}
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure EditKeyDown(Sender: TObject; var Key: Word; {%H-}Shift: TShiftState);
    procedure EditKeyPress(Sender: TObject; var Key: Char);
    procedure KeyPress(var Key: Char); override;
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure TreeviewKeyUp(Sender: TObject; var Key: Word; {%H-}Shift: TShiftState);
    procedure TreeViewMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
    {$ENDIF}
    procedure TreeViewVScroll(Sender: TObject; APosition: Single);
    function CreateSelector: TTMSFNCCustomSelector; override;
    function GetVisibleItemCount: Integer;
    property AutoFilter: Boolean read FAutoFilter write SetAutoFilter default True;
    property AutoCloseUp: Boolean read FAutoCloseUp write SetAutoCloseUp default False;
    property AutoDropDown: Boolean read FAutoDropDown write SetAutoDropDown default False;
    property AutoComplete: Boolean read FAutoComplete write SetAutoComplete default True;
    property AutoCompleteDelay: Integer read FAutoCompleteDelay write SetAutoCompleteDelay default 500;
    property AutoCompleteNumChar: Integer read FAutoCompleteNumChar write SetAutoCompleteNumChar default 2;
    property CaseSensitive: Boolean read FCaseSensitive write SetCaseSensitive default False;
    property DropDownCount: Integer read FDropDownCount write SetDropDownCount default 8;
    property Font: TTMSFNCGraphicsFont read FFont write SetFnt;
    property Items: TStringList read FItems write SetItems;
    property ItemIndex: Integer read GetItemIndex write SetItemIndex default -1;
    property ItemHeight: Double read GetItemHeight write SetItemHeight;
    property Style: TTMSFNCCustomComboBoxStyle read FStyle write SetStyle default csDropDown;
    property Text: string read GetText write SetText;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property ItemAppearance: TTMSFNCTreeViewNodesAppearance read GetItemAppearance write SetItemAppearance;
    property OnItemSelected: TTMSFNCCustomComboBoxItemSelectedEvent read FOnItemSelected write FOnItemSelected;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure AddItem(AItem: string);
    property Count: Integer read GetCount;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCComboBox = class(TTMSFNCCustomComboBox)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property AutoCloseUp;
    property AutoDropDown;
    property AutoComplete;
    property AutoCompleteDelay;
    property AutoCompleteNumChar;
    property AutoFilter;
    property BitmapContainer;
    property CaseSensitive;
    property DropDownCount;
    property DropDownWidth;
    property Font;
    property Items;
    property ItemIndex;
    property ItemHeight;
    property ShowFocus;
    property Style;
    property Text;
    property ItemAppearance;
    property Appearance;
    property OnItemSelected;
  end;

implementation

uses
  Math, SysUtils, WEBLib.TMSFNCUtils, WEBLib.TMSFNCCustomControl
  {$IFDEF FMXLIB}
  ,FMX.Edit
  {$ENDIF}
  {$IFDEF CMNLIB}
  ,StdCtrls
  {$IFDEF VCLLIB}
  ,VCL.Graphics
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,WEBLib.StdCtrls
  {$ENDIF};

{$IFDEF FMXLIB}
type
  TCustomEditProtected = class(TCustomEdit);
  {$ENDIF}

function GetTickCountX: DWORD;
var
  h, m, s, ms: Word;
begin
  DecodeTime(Now, h, m, s, ms);
  Result := ms + s * 1000 + m * 60 * 1000 + h * 60 * 60 * 1000;
end;

{ TTMSFNCCustomComboBox }

procedure TTMSFNCCustomComboBox.AddItem(AItem: string);
begin
  FItems.Add(AItem);
end;

procedure TTMSFNCCustomComboBox.AdjustDropDownHeight;
var
  h: Double;
  c: Integer;
begin
  if Assigned(FTreeView) then
  begin
    c := GetVisibleItemCount;
    h := Min(FDropDownCount, c) * FTreeView.NodesAppearance.FixedHeight + 1;
    DropDownHeight := h;
  end;
end;

procedure TTMSFNCCustomComboBox.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  BeginUpdate;
  FFont.Height := TTMSFNCUtils.MulDivInt(FFont.Height, M,D);
  {$IFDEF VCLLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION >= 33}
  if Assigned(FTreeView) then
  begin
    TTMSFNCTreeViewOpen(FTreeView).ScaleForPPI(CurrentPPI);
  end;
  {$IFEND}
  {$HINTS ON}
  {$ENDIF}
  EndUpdate;
end;

procedure TTMSFNCCustomComboBox.ComboBoxItemClicked(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode);
var
  n: TTMSFNCTreeViewNode;
begin
  if Assigned(ANode) and Assigned(ANode.Node) then
  begin
    Content := ANode.Node.Text[0];
    DoComboBoxItemSelected(ANode.Node.Text[0], ANode.Node.Index);
    n := ANode.Node;
    FTreeView.RemoveFilters;
    FTreeView.SelectNode(n);
  end;
  DropDown;
  Invalidate;
end;

procedure TTMSFNCCustomComboBox.ComboBoxNodeTextAlign(Sender: TObject;
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  AHorizontalTextAlign := gtaLeading;
end;

constructor TTMSFNCCustomComboBox.Create(AOwner: TComponent);
begin
  inherited;
  Width := 150;
  DropDownWidth := Width;
  DropDownCount := 8;
  FItems := TStringList.Create;
  FItems.OnChange := @ItemsChanged;
  Editable := True;
  FPrevString := '';
  FPrevIndex := -1;
  FAutoFilter := True;
  FAutoComplete := True;
  FAutoCloseUp := False;
  FAutoDropDown := False;
  FCaseSensitive := False;
  FAutoCompleteNumChar := 2;
  FAutoCompleteDelay := 500;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChange;
  {$IFDEF FMXLIB}
  Edit.StyledSettings := TCustomEditProtected(Edit).StyledSettings - [TStyledSetting.Family, TStyledSetting.Size, TStyledSetting.Style];
  {$IFDEF ANDROID}
  Edit.TextSettings.VertAlign := TTextAlign.Leading;
  Edit.OnTyping := EditTyping;
  {$ENDIF}
  {$ENDIF}
  Edit.OnKeyDown := @EditKeyDown;
  {$IFDEF CMNWEBLIB}
  Edit.OnKeyPress := EditKeyPress;
  {$ENDIF}
  Popup.FocusedControl := Edit;
  {$IFNDEF ANDROID}
  Popup.StaysOpen := True;
  {$ENDIF}
  {$IFDEF ANDROID}
  Popup.StaysOpen := False;
  {$ENDIF}

  if IsDesignTime then
    Text := 'TTMSFNCComboBox';
end;

function TTMSFNCCustomComboBox.CreateSelector: TTMSFNCCustomSelector;
var
  c: TTMSFNCTreeViewColumn;
begin
  FWrapper := TTMSFNCCustomSelector.Create(Self);
  FTreeView := TTMSFNCTreeView.Create(FWrapper);
  FTreeView.Parent := FWrapper;
  FTreeView.ControlAlignment := caClient;
  FTreeView.VerticalScrollBarVisible := False;

  FTreeView.OnMouseDown := TreeViewMouseDown;
  FTreeView.OnNodeClick := ComboBoxItemClicked;
  FTreeView.OnKeyUp := @TreeviewKeyUp;
  FTreeView.OnGetNodeHorizontalTextAlign := ComboBoxNodeTextAlign;
  FTreeView.OnVScroll := TreeViewVScroll;
  FTreeView.NodesAppearance.FixedHeight := 16;
  FTreeView.Columns.Clear;
  c := FTreeView.Columns.Add;
  c.Text := 'Header';
  c.HorizontalTextAlign := gtaCenter;
  FTreeView.ClearNodes;
  FTreeView.ColumnsAppearance.Layouts := [];
  FTreeView.StretchScrollBars := True;
  FTreeView.NodesAppearance.ExpandColumn := -1;
  FTreeView.NodesAppearance.LevelIndent := 0;
  FTreeView.NodesAppearance.ShowLines := False;
  FTreeView.NodesAppearance.ExpandWidth := 0;
  FTreeView.NodesAppearance.ExpandHeight := 0;
  FTreeView.NodesAppearance.SelectionArea := tsaFull;
  FTreeView.NodesAppearance.ShowFocus := False;

  Result := FWrapper;
end;

procedure TTMSFNCCustomComboBox.DeleteFromEdit;
begin
  FPrevString := Edit.Text;

  if Edit.SelLength = 0 then
    Delete(FPrevString, Edit.SelStart, 1)
  else
    Delete(FPrevString, Max(1, Edit.SelStart + 1), Edit.SelLength);

  UpdateListBoxLookup(FPrevString);
  if (Length(FPrevString) < FAutoCompleteNumChar) and Popup.IsOpen then
  begin
    DropDown;
    FTreeView.RemoveFilters;
  end;
end;

destructor TTMSFNCCustomComboBox.Destroy;
begin
  FFont.Free;
  FItems.Free;
  inherited;
end;

procedure TTMSFNCCustomComboBox.DoComboBoxItemSelected(AText: string;
  AItemIndex: Integer);
begin
  FPrevIndex := AItemIndex;
  if Assigned(OnItemSelected) then
    OnItemSelected(Self, AText, AItemIndex);
end;

procedure TTMSFNCCustomComboBox.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  AGraphics.BitmapContainer := BitmapContainer;
  inherited;
end;

procedure TTMSFNCCustomComboBox.DrawContent(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  r: TRectF;
begin
  inherited;
  if not Editable or IsExporting then
  begin
    if not Enabled then
      AGraphics.Font.Color := gcSilver
    else
      AGraphics.Font.AssignSource(Font);

    r := ARect;
    InflateRectEx(r, -3, -3);
    AGraphics.DrawText(r, Content);
  end;
end;

procedure TTMSFNCCustomComboBox.FontChange(Sender: TObject);
begin
  Invalidate;
  Edit.Font.Assign(Font);
  {$IFDEF FMXLIB}
  Edit.FontColor := (Font as TTMSFNCGraphicsFont).Color;
  {$ENDIF}
end;

function TTMSFNCCustomComboBox.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomComboBox.GetCount: Integer;
begin
  Result := 0;
  if Assigned(FTreeView) then
    Result := FTreeView.Nodes.Count;
end;

function TTMSFNCCustomComboBox.GetItemAppearance: TTMSFNCTreeViewNodesAppearance;
begin
  Result := FTreeView.NodesAppearance;
end;

function TTMSFNCCustomComboBox.GetItemHeight: Double;
begin
  Result := 0.0;
  if Assigned(FTreeView) then
    Result := FTreeView.NodesAppearance.FixedHeight;
end;

function TTMSFNCCustomComboBox.GetItemIndex: Integer;
begin
  Result := -1;
  if Assigned(FTreeView) and (FTreeView.SelectedVirtualNodeCount > 0) and Assigned(FTreeView.SelectedVirtualNodes[0]) then
    Result := FTreeView.SelectedVirtualNodes[0].Row;
end;

function TTMSFNCCustomComboBox.GetText: string;
begin
  Result := Content;
end;

function TTMSFNCCustomComboBox.GetVisibleItemCount: Integer;
begin
  Result := 0;
  if Assigned(FTreeView) then
    Result := TTMSFNCTreeViewOpen(FTreeView).VisibleNodes.Count;
end;

procedure TTMSFNCCustomComboBox.InitializePopup;
begin
  inherited;
  if Assigned(FTreeView) then
  begin
    FTreeView.Fill.Assign(Fill);
    FTreeView.Stroke.Assign(Stroke);
    FTreeView.Height := Round(DropDownHeight);
    FTreeView.VerticalScrollBarVisible := Items.Count > DropDownCount;
    if Assigned(FTreeView.GetNodeForRow(ItemIndex)) then
      FTreeView.ScrollToVirtualNode(FTreeView.GetNodeForRow(ItemIndex));
  end;
end;

procedure TTMSFNCCustomComboBox.ItemsChanged(Sender: TObject);
var
  I: Integer;
begin
  if Assigned(FTreeView) then
  begin
    FTreeView.BeginUpdate;
    FTreeView.ClearNodes;
    for I := 0 to FItems.Count - 1 do
      FTreeView.Nodes.Add.Text[0] := FItems[I];

    FTreeView.EndUpdate;

    AdjustDropDownHeight;
  end;
end;

procedure TTMSFNCCustomComboBox.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
  begin
    FBitmapContainer := nil;
    if Assigned(FTreeView) then
      FTreeView.BitmapContainer := nil;
  end;
end;

procedure TTMSFNCCustomComboBox.Loaded;
begin
  inherited;
  {$IFDEF WEBLIB}
  AdjustDropDownHeight;
  {$ENDIF}
//  {$IFDEF VCLLIB}
//  {$HINTS OFF}
//  {$IF COMPILERVERSION >= 33}
//  if Assigned(FTreeView) then
//  begin
//    TTMSFNCTreeViewOpen(FTreeView).ChangeDpiScale(96, TTMSFNCTreeViewOpen(FTreeView).DesigntimeFormPixelsPerInch);
//    TTMSFNCTreeViewOpen(FTreeView).ScaleForPPI(CurrentPPI);
//  end;
//  {$IFEND}
//  {$HINTS ON}
//  {$ENDIF}
end;

{$IFDEF FMXLIB}
{$IFDEF ANDROID}
procedure TTMSFNCCustomComboBox.EditTyping(Sender: TObject);
var
  vn: TTMSFNCTreeViewVirtualNode;
begin
  if (not Assigned(FTreeView)) or (not AutoComplete) then
    Exit;

  if Length(FPrevString) >= Length(Content) then
  begin
    UpdateListBoxLookup(Content);

    if AutoCloseUp and (Length(Content) < FAutoCompleteNumChar) and Popup.IsOpen then
    begin
      DropDown;
      FTreeView.RemoveFilters;
    end;

    FPrevString := Content;
    Exit;
  end;

  UpdateListBoxLookup(Content);

  vn := FTreeView.LookupNode(FPrevString, False, -1, FCaseSensitive);
  if Assigned(vn) and Assigned(vn.Node) then
  begin
    FPrevString := Content;

    if AutoCloseUp and (FPrevString = vn.Node.Text[0]) and Popup.IsOpen then
    begin
      DropDown;
      FTreeView.RemoveFilters;
    end;
  end;
end;
{$ENDIF}
{$ENDIF}

{$IFDEF FMXLIB}
procedure TTMSFNCCustomComboBox.EditKeyDown(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomComboBox.EditKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  FKeyDown := Key;

  if (FKeyDown = KEY_F4) then
  begin
    DropDown;
    Exit;
  end;

  if (ssAlt in Shift) then
  begin
    case FKeyDown of
      KEY_DOWN, KEY_UP:
      begin
        DropDown;
        Exit;
      end;
    end;
  end;

  if (Shift <> [ssAlt]) and (FKeyDown in [KEY_UP, KEY_DOWN]) then
  begin
    if FKeyDown = KEY_UP then
      SelectPreviousValue;

    if FKeyDown = KEY_DOWN then
      SelectNextValue;

    Key := 0;
  end;

  {$IFDEF WEBLIB}
  if FKeyDown = KEY_BACK then
    DeleteFromEdit;
  {$ENDIF}
end;

procedure TTMSFNCCustomComboBox.EditKeyPress(Sender: TObject; var Key: Char);
{$ENDIF}
var
  vn: TTMSFNCTreeViewVirtualNode;
  selPos, selLen: Integer;
  kch: Char;

  procedure EatKey;
  begin
    {$IFDEF FMXLIB}
    KeyChar := #0;
    Key := 0;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    Key := #0;
    {$ENDIF}
  end;
begin
  if not Assigned(FTreeView) then
    Exit;

  {$IFDEF FMXLIB}
  kch := KeyChar;
  FKeyDown := Key;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  kch := Key;
  {$ENDIF}

  if (FKeyDown = KEY_ESCAPE) then
  begin
    if AutoCloseUp and Popup.IsOpen then;
    begin
      DropDown;
      Exit;
    end;
  end;

  if (FKeyDown = KEY_RETURN) then
  begin
    if Popup.IsOpen then
    begin
      Edit.SelStart := Length(Edit.Text);
      Edit.SelLength := 0;
      FTreeView.RemoveFilters;
      {$IFDEF FMXMOBILE}
      DropDown;
      {$ENDIF}
    end;

    {$IFNDEF FMXMOBILE}
    DropDown;
    {$ENDIF}

    Exit;
  end;

  {$IFDEF FMXLIB}
  if (FKeyDown = KEY_F4) then
  begin
    DropDown;
    Exit;
  end;

  if (ssAlt in Shift) then
  begin
    case FKeyDown of
      KEY_DOWN, KEY_UP:
      begin
        DropDown;
        Exit;
      end;
    end;
  end;

  if FKeyDown in [KEY_UP, KEY_DOWN] then
  begin
    if FKeyDown = KEY_UP then
      SelectPreviousValue;

    if FKeyDown = KEY_DOWN then
      SelectNextValue;

    EatKey;
  end;
  {$ENDIF}

  if AutoComplete then
  begin
    {$IFNDEF WEBLIB}
    if FKeyDown = KEY_BACK then
    begin
      DeleteFromEdit;
      Exit;
    end;
    {$ENDIF}

    if kch = #0 then
      Exit;

    if Edit.SelLength = Length(Edit.Text) then
      FPrevString := '';

    FPrevString := FPrevString + kch;
    if Length(FPrevString) < FAutoCompleteNumChar then
      Exit;

    UpdateListBoxLookup(FPrevString);

    vn := FTreeView.LookupNode(FPrevString, False, -1, FCaseSensitive);
    if Assigned(vn) and Assigned(vn.Node) then
    begin
      FTreeView.SelectNode(vn.Node);
      selPos := Length(FPrevString);
      Content := vn.Node.Text[0];
      Edit.SelStart := selPos;
      selLen := Length(Edit.Text) - selPos;
      Edit.SelLength := selLen;
      EatKey;

      if FPrevIndex <> vn.Node.Index then
        DoComboBoxItemSelected(vn.Node.Text[0], vn.Node.Index);

      if AutoCloseUp and (selLen = 0) and Popup.IsOpen then
      begin
        //DoComboBoxItemSelected(vn.Node.Text[0], vn.Node.Index);
        DropDown;
      end;
    end;
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomComboBox.KeyDown(var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomComboBox.KeyDown(var Key: Word; Shift: TShiftState);
begin
  inherited;
  FKeyDown := Key;
end;

procedure TTMSFNCCustomComboBox.KeyPress(var Key: Char);
{$ENDIF}
var
  vn: TTMSFNCTreeViewVirtualNode;
  kch: Char;
  tick: Cardinal;
begin
  inherited;

  if not Assigned(FTreeView) then
    Exit;

  {$IFDEF FMXLIB}
  kch := KeyChar;
  FKeyDown := Key;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  kch := Key;
  {$ENDIF}

  if (FKeyDown = KEY_ESCAPE) then
  begin
    if Popup.IsOpen then;
    begin
      DropDown;
      Exit;
    end;
  end;

  if AutoComplete then
  begin
    if FKeyDown = KEY_BACK then
    begin
      Delete(FPrevString, Length(FPrevString), 1);
      if (Length(FPrevString) = 1) and Popup.IsOpen then
      begin
        DropDown;
        FTreeView.RemoveFilters;
      end;

      Exit;
    end;

    if kch = #0 then
      Exit;

    if AutoDropDown and (not Popup.IsOpen) then
      DropDown;

    tick := FAutoCompleteDelay;
    if (GetTickCountX - FTick) < tick then
      FPrevString := FPrevString + kch
    else
    begin
      FTick := GetTickCountX;
      FPrevString := kch;
    end;
  end
  else
  begin
    if kch = #0 then
      Exit;

    FPrevString := kch;
  end;

  vn := FTreeView.LookupNode(FPrevString, False, -1, FCaseSensitive);
  if Assigned(vn) and Assigned(vn.Node) then
  begin
    FTreeView.SelectNode(vn.Node);
    Content := vn.Node.Text[0];
    DoComboBoxItemSelected(vn.Node.Text[0], vn.Node.Index);
    Invalidate;

    if AutoComplete and (Content = FPrevString) and AutoCloseUp and Popup.IsOpen then
      DropDown;
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomComboBox.TreeviewKeyUp(Sender: TObject; var Key: Word; var KeyChar: WideChar; Shift: TShiftState);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomComboBox.TreeviewKeyUp(Sender: TObject; var Key: Word; Shift: TShiftState);
{$ENDIF}
var
  t: TTMSFNCTreeViewOpen;
begin
  FKeyDown := Key;

  if (FKeyDown = KEY_RETURN) then
  begin
    t := TTMSFNCTreeViewOpen(FTreeView);
    Content := t.SelectedNode.Text[0];
    DropDown;
    Invalidate;
    Exit;
  end;
end;

procedure TTMSFNCCustomComboBox.SelectFirstValue;
var
  vn: TTMSFNCTreeViewVirtualNode;
begin
  inherited;
  if Assigned(FTreeView) then
  begin
    vn := TTMSFNCTreeViewOpen(FTreeView).GetFirstVisibleVirtualNode;
    if Assigned(vn) and Assigned(vn.Node) then
    begin
      FTreeView.SelectNode(vn.Node);
      Content := vn.Node.Text[0];
      DoComboBoxItemSelected(vn.Node.Text[0], vn.Node.Index);
      Invalidate;
    end;
  end;
end;

procedure TTMSFNCCustomComboBox.SelectLastValue;
var
  vn: TTMSFNCTreeViewVirtualNode;
begin
  inherited;
  if Assigned(FTreeView) then
  begin
    vn := TTMSFNCTreeViewOpen(FTreeView).GetLastVisibleVirtualNode;
    if Assigned(vn) and Assigned(vn.Node) then
    begin
      FTreeView.SelectNode(vn.Node);
      Content := vn.Node.Text[0];
      DoComboBoxItemSelected(vn.Node.Text[0], vn.Node.Index);
      Invalidate;
    end;
  end;
end;

procedure TTMSFNCCustomComboBox.SelectNextValue;
var
  vn: TTMSFNCTreeViewVirtualNode;
begin
  inherited;
  if Assigned(FTreeView) then
  begin
    vn := TTMSFNCTreeViewOpen(FTreeView).GetNextFocusableNode(FTreeView.SelectedVirtualNode);
    if Assigned(vn) and Assigned(vn.Node) then
    begin
      FTreeView.SelectNode(vn.Node);
      Content := vn.Node.Text[0];
      DoComboBoxItemSelected(vn.Node.Text[0], vn.Node.Index);
      Invalidate;
    end;
  end;
end;

procedure TTMSFNCCustomComboBox.SelectPreviousValue;
var
  vn: TTMSFNCTreeViewVirtualNode;
begin
  inherited;
  if Assigned(FTreeView) then
  begin
    vn := TTMSFNCTreeViewOpen(FTreeView).GetPreviousFocusableNode(FTreeView.SelectedVirtualNode);
    if Assigned(vn) and Assigned(vn.Node) then
    begin
      FTreeView.SelectNode(vn.Node);
      Content := vn.Node.Text[0];
      DoComboBoxItemSelected(vn.Node.Text[0], vn.Node.Index);
      Invalidate;
    end;
  end;
end;

procedure TTMSFNCCustomComboBox.SetAdaptToStyle(const Value: Boolean);
begin
  inherited;
  if Assigned(FWrapper) then
    FWrapper.AdaptToStyle := AdaptToStyle;
  if Assigned(FTreeView) then
    FTreeView.AdaptToStyle := AdaptToStyle;
end;

procedure TTMSFNCCustomComboBox.SetAutoCloseUp(const Value: Boolean);
begin
  FAutoCloseUp := Value;
end;

procedure TTMSFNCCustomComboBox.SetAutoComplete(const Value: Boolean);
begin
  FAutoComplete := Value;
end;

procedure TTMSFNCCustomComboBox.SetAutoCompleteDelay(const Value: Integer);
begin
  FAutoCompleteDelay := Value;
end;

procedure TTMSFNCCustomComboBox.SetAutoCompleteNumChar(const Value: Integer);
begin
  if Value > 0 then
    FAutoCompleteNumChar := Value
  else
    FAutoCompleteNumChar := 1;
end;

procedure TTMSFNCCustomComboBox.SetAutoDropDown(const Value: Boolean);
begin
  FAutoDropDown := Value;
end;

procedure TTMSFNCCustomComboBox.SetAutoFilter(const Value: Boolean);
begin
  FAutoFilter := Value;
end;

procedure TTMSFNCCustomComboBox.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  if Assigned(FTreeView) then
    FTreeView.BitmapContainer := Value;
  Invalidate;
end;

procedure TTMSFNCCustomComboBox.SetCaseSensitive(const Value: Boolean);
begin
  FCaseSensitive := Value;
end;

procedure TTMSFNCCustomComboBox.SetDropDownCount(const Value: Integer);
begin
  if FDropDownCount <> Value then
  begin
    FDropDownCount := Value;
    AdjustDropDownHeight;
  end;
end;

procedure TTMSFNCCustomComboBox.SetFnt(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
  Edit.Font.Assign(Value);
  {$IFDEF FMXLIB}
  if Value is TTMSFNCGraphicsFont then
    Edit.FontColor := (Value as TTMSFNCGraphicsFont).Color;
  {$ENDIF}
end;

procedure TTMSFNCCustomComboBox.SetItemAppearance(
  const Value: TTMSFNCTreeViewNodesAppearance);
begin
  FTreeView.NodesAppearance.Assign(Value);
end;

procedure TTMSFNCCustomComboBox.SetItemHeight(const Value: Double);
begin
  if Assigned(FTreeView) then
  begin
    FTreeView.NodesAppearance.FixedHeight := Value;
    AdjustDropDownHeight;
  end;
end;

procedure TTMSFNCCustomComboBox.SetItemIndex(const Value: Integer);
var
  t: TTMSFNCTreeViewOpen;
begin
  FPrevIndex := Value;
  if Assigned(FTreeView) then
  begin
    t := TTMSFNCTreeViewOpen(FTreeView);
    if (Value >= 0) and (Value <= Items.Count - 1) then
    begin
      t.SelectVirtualNode(t.GetNodeForRow(Value));
      if Assigned(t.SelectedNode) then
      begin
        Text := t.SelectedNode.Text[0];
        DoComboBoxItemSelected(Text, Value);
      end
      else
        Text := '';
    end
    else
    begin
      t.SelectVirtualNode(nil);
      Text := '';
    end;
  end;
end;

procedure TTMSFNCCustomComboBox.SetItems(const Value: TStringList);
begin
  FItems.Assign(Value);
end;

procedure TTMSFNCCustomComboBox.SetStyle(
  const Value: TTMSFNCCustomComboBoxStyle);
begin
  if FStyle <> Value then
  begin
    FStyle := Value;
    case FStyle of
      TTMSFNCCustomComboBoxStyle.csDropDown:
      begin
        Editable := True;
        Popup.FocusedControl := Edit;
        {$IFNDEF ANDROID}
        Popup.StaysOpen := True;
        {$ENDIF}
      end;
      TTMSFNCCustomComboBoxStyle.csDropDownList:
      begin
        Editable := False;
        Popup.FocusedControl := FTreeView;
        Popup.StaysOpen := False;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomComboBox.SetText(const Value: string);
begin
  Content := Value;
end;

procedure TTMSFNCCustomComboBox.TreeViewVScroll(Sender: TObject;
  APosition: Single);
begin
  {$IFDEF MSWINDOWS}
  if Editable then
    PreventDropdown := True;
  {$ENDIF}
end;

{$IFDEF FMXLIB}
procedure TTMSFNCCustomComboBox.TreeViewMouseDown(Sender: TObject;
  Button: TMouseButton; Shift: TShiftState; X, Y: Single);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomComboBox.TreeViewMouseDown(Sender: TObject;
  Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
{$ENDIF}
begin
  if Editable then
    PreventDropDown := True;
end;

procedure TTMSFNCCustomComboBox.UpdateListBoxLookup(AText: string);
var
  f: TTMSFNCTreeViewFilterData;
  c: Integer;
begin
  if FAutoFilter then
  begin
    FTreeView.Filter.Clear;
    f := FTreeView.Filter.Add;
    f.Condition := AText + '*';
    f.CaseSensitive := FCaseSensitive;
    FTreeView.ApplyFilter;
  end;

  //AdjustDropDownHeight;

  c := GetVisibleItemCount;
  if AutoDropDown and (Length(AText) >= FAutoCompleteNumChar) and (not Popup.IsOpen) and (c > 0) then
    DropDown;
end;

{ TTMSFNCComboBox }

procedure TTMSFNCComboBox.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCComboBox);
end;

end.

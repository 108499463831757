unit BuchungEdit;

interface


uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls, WEBLib.ExtCtrls;

type
  TfrmBuchungEdit = class(TWebForm)
    WebPanel1: TWebPanel;
    WebLabel1: TWebLabel;
    edtZuName: TWebEdit;
    WebLabel2: TWebLabel;
    edtVorname: TWebEdit;
    WebLabel3: TWebLabel;
    WebButton1: TWebButton;
    WebButton2: TWebButton;
    edtDatum: TWebDateTimePicker;
    WebLabel4: TWebLabel;
    edtZeit: TWebDateTimePicker;
    WebLabel5: TWebLabel;
    cbxGrund: TWebComboBox;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  end;

var
  frmBuchungEdit: TfrmBuchungEdit;

implementation

{$R *.dfm}

uses UnitMain, PzsDatenmodul;

end.

unit Mitarbeiter;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  Vcl.Graphics, WEBLib.Buttons, WEBLib.Grids, Vcl.Grids, WEBLib.DBCtrls, XData.Web.Connection,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl, VCL.TMSFNCCustomPicker,
  VCL.TMSFNCComboBox, Vcl.Imaging.pngimage, XData.Web.Client, Bcl.Utils, CP.Func.AES,
  VCL.TMSFNCToolBar   ;

type
  TfrmMitarbeiter = class(TForm)
    WebPanel1: TPanel;
    lbMitarbeiter: TLabel;
    WebPanel2: TPanel;
    mLog: TMemo;
    WebButton1: TButton;
    XDataWebClient: TXDataWebClient;
    WebButton2: TButton;
    edtTemp: TEdit;
    tabMitarbeiter: TXDataWebDataSet;
    tabMitarbeiterMA_NR: TIntegerField;
    tabMitarbeiterMA_NAME: TStringField;
    tabMitarbeiterMA_VNAME: TStringField;
    tabMitarbeiterMA_PERSNR: TStringField;
    dsMitarbeiter: TDataSource;
    grdMitarbeiter: TDBGrid;
    btnNavPrev: TTMSFNCToolBarButton;
    btnNavNext: TTMSFNCToolBarButton;
    btnNavInfo: TTMSFNCToolBarButton;
    tabMitarbeiterKST_BEZ: TStringField;
    tabMitarbeiterKST_NAME: TStringField;
    tabMitarbeiterZEITMODELL: TStringField;
    tabMitarbeiterTARIF: TStringField;
    tabMitarbeiterGEBDAT: TStringField;
    tabMitarbeiterEINTRITT: TStringField;
    tabMitarbeiterAUSTRITT: TStringField;
    tabMitarbeiterGRUPPE: TStringField;
    btnTest: TTMSFNCToolBarButton;

    DataSource1: TDataSource;
    procedure tabMaDatMA_GEBDATGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure grdMaDatFixedCellClick(Sender: TObject; ACol, ARow: LongInt);
    procedure edtFilterChange(Sender: TObject);

    procedure grdMaDatDblClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure XDataWebClientLoad(Response: TXDataClientResponse);
    procedure WebButton2Click(Sender: TObject);
    procedure dsMaDatDataChange(Sender: TObject; Field: TField);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure Do_DatenLaden(Sender: TObject);
    procedure btnNavInfoClick(Sender: TObject);
    [async] procedure btnEditClick(Sender: TObject); async;
    [async] procedure Daten_Laden( aID : Integer ); async;
    [async] procedure grdMitarbeiterDblClick(Sender: TObject); async;
    [async] procedure btnTestClick(Sender: TObject); async;

  private
    bFirst : Boolean;
    iMaRec_Von : Integer;
    iMaRec_Bis : Integer;

    sSortFeldName : String;

  public


  protected procedure LoadDFMValues; override; end;

var frmMitarbeiter: TfrmMitarbeiter;

implementation

{$R *.dfm}

uses MitarbeiterEdit, UnitMain,  PzsDatenmodul;

//================================================================================================//

procedure TfrmMitarbeiter.btnNavInfoClick(Sender: TObject);
var iID : Integer;
begin

  iMaRec_Von := 1;
  iMaRec_Bis := frmMainForm.g_RecAnzahl;

  iID := tabMitarbeiter.FieldByName('MA_NR').AsInteger;
  Daten_Laden(iID);

end;

//================================================================================================//
[async] procedure TfrmMitarbeiter.Daten_Laden( aID : Integer ); async;
var Response: TXDataClientResponse;
    ClientConn: TXDataWebClient;
    Blob: JSValue;
    sDaten, sSQL, sTmp, sKopfZeile: String;
    aSL : TStringList;
    aStream : TStream;
    aTMP :  TJSObject;
    i : Integer;
    aArray : TArray<string>;
begin

  frmMainForm.LogAction('Start: Ma_Laden', false );

  btnNavInfo.Enabled := False;

  ClientConn := TXDataWebClient.Create(nil);
  ClientConn.Connection := frmMainForm.XDataConn;

//  sSQL := 'SELECT  MA_NR, MA_PERSNR, MA_NAME, MA_VNAME '
//         +'FROM MA_DAT WHERE (FIR_MAND=1 AND MA_AUSGESCHIE <> 1)  '
//         +'AND (MA_EXTERN=0 or MA_EXTERN IS NULL) '
//         +'ORDER BY MA_NAME, MA_VNAME ';


  sSQL := 'SELECT  a.MA_NR, a.MA_PERSNR, a.MA_NAME, a.MA_VNAME, '
        +' (SELECT KST_NAME FROM GET_MA_KST_NAME( a.MA_NR, CURRENT_TIMESTAMP )) as KST_REF, '
        +' (SELECT KST_BEZ FROM GET_MA_KST_NAME( a.MA_NR, CURRENT_TIMESTAMP ))  as KST_BEZ, '
        +' (SELECT ZM FROM GET_MA_ZM_NAME( a.MA_NR, CURRENT_TIMESTAMP )) as ZEITMODELL, '
        +' (SELECT TARIF FROM GET_MA_TRF_NAME( a.MA_NR, CURRENT_TIMESTAMP )) as TARIF, '
        +'  a.MA_GEBDAT, a.MA_EINTDAT, a.MA_AUSTDAT,'
        +' (SELECT GRP_BEZ from GRP_DAT WHERE FIR_MAND=a.FIR_MAND AND GRP_NR=a.MA_GRPNR)  as GRUPPE, '

         +' a.MA_SPRINGER, a.MA_STAAT,  '
         +'  a.BEFRISTET_BIS,  a.MA_AUFENTHALTBIS, a.MA_ARBEITBIS, '
         +' a.MA_ANSTELL, a.MA_KURZ_BEZ, a.ERST_EINTRITT,  a.MA_OHNEGZ, a.ABRECHNUNGSART, '
         +' a.PC,  a.IST_MASCHINE, '

         +' (SELECT ADR_NAM1 FROM MANDANT M, ADRESSEN AD WHERE M.FIR_ADRNR=AD.ADR_NR '
         +'         AND M.ID_MANDANT=a.ID_MANDANT) as MANDANT, '
         +' (SELECT BEZEICHNUNG FROM BESCHART B WHERE B.ID_BESCHART=a.MA_TEILZEIT ) as BESCHART, '
         +' a.WOCHE_SOLL_STD,a.MONAT_SOLL_STD, '

         +' D.ADR_SEX, D.ADR_STR, D.ADR_PLZ, D.ADR_ORT, D.ADR_TEL, D.ADR_EMAIL, D.ADR_FAX, '
         +' (SELECT BEZEICHNUNG FROM EXPGRP EX WHERE a.ID_EXPGRP=EX.ID_EXPGRP ) as EXPGRUPPE, '
         +' (SELECT OUT_NAME FROM GET_MA_FIRMA_NAME( A.ART_FIRMA, A.ID_FIRMA )) AS ANG_BEI '
         +' FROM MA_DAT a, ADRESSEN D WHERE (a.FIR_MAND=1 AND a.MA_AUSGESCHIE <> 1) '
         +'  AND (D.FIR_MAND = A.ADR_FIR_MAND and D.ADR_NR = A.ADR_NR) AND (MA_EXTERN=0 or MA_EXTERN IS NULL) '
         +'  ORDER BY a.MA_NAME, a.MA_VNAME ';


//  Response := await( ClientConn.RawInvokeAsync('ISystemService.GetDaten', [ sSQL, 'CSV',iMaRec_Von,iMaRec_Bis] ) );


  Blob := Response.Result;
  asm sDaten = await Blob.text(); end;

  ClientConn.Free;


  //------------------------ Daten jetzt noch entschlüsseln
  edtTemp.Text := sDaten;

  frmMainForm.LogAction('   1 :  TfrmMitarbeiter.Daten_Laden',false);

  TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(edtTemp.Text),
    procedure(const AEncrypted: string)
      begin

        aSL := TStringList.Create;
        aSL.Text := AEncrypted; // mLog.Lines.Text;


        tabMitarbeiter.Close;
        tabMitarbeiter.Open;

        sKopfZeile := aSL[0];
        aArray := sKopfZeile.Split([';']);
        btnNavInfo.Text := aArray[3]+'-'+aArray[4]+' ['+aArray[2]+']';


        For i := 2 to aSL.Count -1 Do
            Begin
             sTmp := aSL[i];
              aArray := sTmp.Split([';']);
              tabMitarbeiter.Append;
              tabMitarbeiter.FieldByName('MA_NR').AsInteger     := StrToIntDef(aArray[0],0);
              tabMitarbeiter.FieldByName('MA_PERSNR').AsString  := aArray[1];
              tabMitarbeiter.FieldByName('MA_NAME').AsString    := aArray[2];
              tabMitarbeiter.FieldByName('MA_VNAME').AsString   := aArray[3];
              tabMitarbeiter.FieldByName('KST_REF').AsString    := aArray[4];
              tabMitarbeiter.FieldByName('KST_BEZ').AsString    := aArray[5];
              tabMitarbeiter.FieldByName('ZEITMODELL').AsString := aArray[6];
              tabMitarbeiter.FieldByName('TARIF').AsString      := aArray[7];
              tabMitarbeiter.FieldByName('GEBDAT').AsString     := aArray[8];
              tabMitarbeiter.FieldByName('EINTRITT').AsString   := aArray[9];
              tabMitarbeiter.FieldByName('AUSTRITT').AsString   := aArray[10];
              tabMitarbeiter.FieldByName('GRUPPE').AsString     := aArray[11];

              tabMitarbeiter.Post;
            End;

        aSL.Free;

        If aID = 0 then tabMitarbeiter.First;
        If aID > 0 then
          If not tabMitarbeiter.Locate('MA_NR',aID,[]) then tabMitarbeiter.First;

        btnNavNext.Enabled := (tabMitarbeiter.RecordCount=frmMainForm.g_RecAnzahl );
        btnNavInfo.Enabled := True;

        frmMainForm.LogAction('Ende:  TfrmMitarbeiter.Daten_Laden',False);

      end,
    procedure(const AError: string)
     begin
      ShowMessage(AError);
     end);

end;

//================================================================================================//

procedure TfrmMitarbeiter.Do_DatenLaden(Sender: TObject);
begin

  If Sender = btnNavNext then
    Begin
     iMaRec_Von := iMaRec_Von + frmMainForm.g_RecAnzahl;
    End
  Else
    Begin
     iMaRec_Von := iMaRec_Von - frmMainForm.g_RecAnzahl;
     If iMaRec_Von < 1 then iMaRec_Von := 1;
    End;
  iMaRec_Bis := iMaRec_Von + frmMainForm.g_RecAnzahl-1;

  Daten_Laden(0);

end;

//================================================================================================//

procedure TfrmMitarbeiter.dsMaDatDataChange(Sender: TObject; Field: TField);
begin

end;

//================================================================================================//

procedure TfrmMitarbeiter.edtFilterChange(Sender: TObject);
begin


end;

//================================================================================================//

procedure TfrmMitarbeiter.grdMaDatDblClick(Sender: TObject);
begin
  btnEditClick(grdMitarbeiter)
end;

//================================================================================================//

procedure TfrmMitarbeiter.grdMaDatFixedCellClick(Sender: TObject; ACol, ARow: LongInt);
var sFeldName : String;
begin

  If aRow > 0 then Exit;

//  sSortFeldName := TWebGridColumn( grdMitarbeiter.Columns[aCol] ).DataField ;

//  Daten_Laden( tabMaDatMA_NR.AsInteger ) ;

end;

//================================================================================================//

procedure TfrmMitarbeiter.grdMitarbeiterDblClick(Sender: TObject);
 var sMaName, sZuname, sVorname, sZeitStempel : String;
     dtZP : TDateTime;
     iID, iMaNr, iGrund : Integer;
     Response: TXDataClientResponse;
begin

  iMaNr  := tabMitarbeiterMA_NR.AsInteger;


  If iMaNr=0 then Exit;

  sMaName :=  tabMitarbeiterMA_NAME.AsString+', '
            + tabMitarbeiterMA_VNAME.AsString;
//
  frmMitarbeiterEdit := TfrmMitarbeiterEdit.Create(Self);
  frmMitarbeiterEdit.Caption := 'Mitarbeiter bearbeiten: '+sMaName;
  frmMitarbeiterEdit.iMaNr  := iMaNr;
  frmMitarbeiterEdit.Popup  := True;
  frmMitarbeiterEdit.Border := fbDialogSizeable;

//  await( frmMainForm.AnwAbw_Laden );

  // used to manage Back button handling to close subform
  window.location.hash := 'subform';

frmMainForm.LogAction('Klick 1',False);

  // load file HTML template + controls
  await( TfrmMitarbeiterEdit, frmMitarbeiterEdit.Load() );

frmMainForm.LogAction('Klick 2',False);

  await( frmMitarbeiterEdit.Daten_Ins_Formular );

frmMainForm.LogAction('Klick 3',False);

  Try
    // excute form and wait for close
    await( TModalResult, frmMitarbeiterEdit.Execute);
//
//    // Änderungen speichern
//    If frmBuchungEdit.ModalResult = mrOK then
//      Begin
//        dtZP := Trunc(frmBuchungEdit.edtDatum.Date) + Frac( frmBuchungEdit.edtZeit.DateTime );
//        await(tabZeitBuchung.Edit);
//        tabZeitBuchung.FieldByName('BU_TISTAMP').AsString := DateTimeToStr(dtZP);
//
//        Case frmBuchungEdit.cbxGrund.ItemIndex Of
//          0 : iGrund := 3;
//          1 : iGrund := 1;
//          2 : iGrund := 2;
//          3 : iGrund := 4;
//        End;
//        tabZeitBuchung.FieldByName('ANWABW_NR').AsInteger := iGrund;
//        tabZeitBuchung.FieldByName('ANWABW_BEZ').AsString := frmMainForm.Get_AnwAbw_Bez(iGrund);
//
//        await(tabZeitBuchung.Post);
//
//        //in die Datenbank übernehmen
//        Response := await( XDataWebClient.RawInvokeAsync('ISystemService.BuchungUpd', [ iID, iGrund, DateTimeToStr(dtZP) ] ));
//
//      End;
//
  Finally
    frmMitarbeiterEdit.Free;
    mLog.Lines.Add('frmMitarbeiterEdit.free')
  End;

end;

//================================================================================================//

procedure TfrmMitarbeiter.tabMaDatMA_GEBDATGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin

//  If TDateTimeField(Sender).AsDateTime=0 then Text := ''
//  Else Text := '  '+DateTimeToStr(TDateTimeField(Sender).AsDateTime);

end;

//================================================================================================//

procedure TfrmMitarbeiter.btnTestClick(Sender: TObject);
//var aObj, aObjResult : TWebCoreObj;
//    Response: TXDataClientResponse;
begin

//   aObj := TWebCoreObj.Create;
//   aObj.ID := 1;
//   aObj.Bezeichnung := 'test';
//   aObj.Tag := Now - 0.5;
//
//   Response := await( XDataWebClient.RawInvokeAsync('ISystemService.Test', [ aObj] ));
//
//   aObjResult := TWebCoreObj( Response.Result );
//
//   //btnTest.Text := TWebCoreObj( Response.Result ).Bezeichnung;
//
//   btnTest.Text := aObjResult.Bezeichnung;
//
//   aObj.Free;
//
//

end;

//================================================================================================//

procedure TfrmMitarbeiter.WebButton1Click(Sender: TObject);
begin

  XDataWebClient.Get('MA_DAT', 1 );

end;

//================================================================================================//

procedure TfrmMitarbeiter.WebButton2Click(Sender: TObject);
begin

  XDataWebClient.List('MA_DAT', '');

end;

procedure TfrmMitarbeiter.WebButton3Click(Sender: TObject);
begin

end;

//================================================================================================//

procedure TfrmMitarbeiter.WebFormCreate(Sender: TObject);
begin

  bFirst := False;

  XDataWebClient.Connection   := frmMainForm.XDataConn;

  iMaRec_Von := 1;
  iMaRec_Bis := frmMainForm.g_RecAnzahl;

end;

//================================================================================================//

procedure TfrmMitarbeiter.WebFormShow(Sender: TObject);
begin
  Daten_Laden(0);
end;

//================================================================================================//

procedure TfrmMitarbeiter.XDataWebClientLoad(Response: TXDataClientResponse);
var Mab: TJSObject;
begin

  If Response.RequestId = 'list' then
    mLog.Lines.Text := TJSJson.stringify(Response.Result)
  Else
    Begin
      // Both lines below are equivalent.
      Mab := TJSObject(Response.Result);
      Mab := Response.ResultAsObject;

      mLog.Lines.Add( Response.ResponseText );

    End;
end;

//================================================================================================//

procedure TfrmMitarbeiter.btnEditClick(Sender: TObject);
 var sMaName : String;
begin

  sMaName :=  tabMitarbeiter.FieldbyName('MA_Name').AsString+', '
            + tabMitarbeiter.FieldbyName('MA_VName').AsString;

  frmMitarbeiterEdit := TfrmMitarbeiterEdit.Create(Self);
  frmMitarbeiterEdit.Caption := 'Mitarbeiter bearbeiten: '+sMaName;
  frmMitarbeiterEdit.Popup := True;
  frmMitarbeiterEdit.Border := fbDialog;

  // used to manage Back button handling to close subform
  //  window.location.hash := 'subform';

  // load file HTML template + controls
  await( TfrmMitarbeiterEdit, frmMitarbeiterEdit.Load() );

  // init control after loading
  frmMitarbeiterEdit.edtZuname.Text  := tabMitarbeiter.FieldbyName('MA_Name').AsString;
  frmMitarbeiterEdit.edtVorname.Text := tabMitarbeiter.FieldbyName('MA_VNAME').AsString;

  try
    // excute form and wait for close
    await( TModalResult, frmMitarbeiterEdit.Execute);

    If frmMitarbeiterEdit.ModalResult = mrOK then
      Begin
        await(tabMitarbeiter.Edit);
        tabMitarbeiter.FieldByName('MA_Name').AsString  := frmMitarbeiterEdit.edtZuname.Text;
        tabMitarbeiter.FieldByName('MA_VNAME').AsString := frmMitarbeiterEdit.edtVorname.Text;
        await(tabMitarbeiter.Post);
        //await(tabMaDat.ApplyUpdates);
      End;

  Finally
    frmMitarbeiterEdit.Free;
  End;


 Daten_Laden(0);
 //Daten_Laden( tabMaDat.FieldByName('MA_NR').AsInteger );



end;

//================================================================================================//

procedure TfrmMitarbeiter.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  lbMitarbeiter := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  mLog := TMemo.Create(Self);
  edtTemp := TEdit.Create(Self);
  grdMitarbeiter := TDBGrid.Create(Self);
  btnNavPrev := TTMSFNCToolBarButton.Create(Self);
  btnNavNext := TTMSFNCToolBarButton.Create(Self);
  btnNavInfo := TTMSFNCToolBarButton.Create(Self);
  btnTest := TTMSFNCToolBarButton.Create(Self);
  XDataWebClient := TXDataWebClient.Create(Self);
  tabMitarbeiter := TXDataWebDataSet.Create(Self);
  tabMitarbeiterMA_NR := TIntegerField.Create(Self);
  tabMitarbeiterMA_NAME := TStringField.Create(Self);
  tabMitarbeiterMA_VNAME := TStringField.Create(Self);
  tabMitarbeiterMA_PERSNR := TStringField.Create(Self);
  tabMitarbeiterKST_NAME := TStringField.Create(Self);
  tabMitarbeiterKST_BEZ := TStringField.Create(Self);
  tabMitarbeiterZEITMODELL := TStringField.Create(Self);
  tabMitarbeiterTARIF := TStringField.Create(Self);
  tabMitarbeiterGEBDAT := TStringField.Create(Self);
  tabMitarbeiterEINTRITT := TStringField.Create(Self);
  tabMitarbeiterAUSTRITT := TStringField.Create(Self);
  tabMitarbeiterGRUPPE := TStringField.Create(Self);
  dsMitarbeiter := TDataSource.Create(Self);
  DataSource1 := TDataSource.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  lbMitarbeiter.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  mLog.BeforeLoadDFMValues;
  edtTemp.BeforeLoadDFMValues;
  grdMitarbeiter.BeforeLoadDFMValues;
  btnNavPrev.BeforeLoadDFMValues;
  btnNavNext.BeforeLoadDFMValues;
  btnNavInfo.BeforeLoadDFMValues;
  btnTest.BeforeLoadDFMValues;
  XDataWebClient.BeforeLoadDFMValues;
  tabMitarbeiter.BeforeLoadDFMValues;
  tabMitarbeiterMA_NR.BeforeLoadDFMValues;
  tabMitarbeiterMA_NAME.BeforeLoadDFMValues;
  tabMitarbeiterMA_VNAME.BeforeLoadDFMValues;
  tabMitarbeiterMA_PERSNR.BeforeLoadDFMValues;
  tabMitarbeiterKST_NAME.BeforeLoadDFMValues;
  tabMitarbeiterKST_BEZ.BeforeLoadDFMValues;
  tabMitarbeiterZEITMODELL.BeforeLoadDFMValues;
  tabMitarbeiterTARIF.BeforeLoadDFMValues;
  tabMitarbeiterGEBDAT.BeforeLoadDFMValues;
  tabMitarbeiterEINTRITT.BeforeLoadDFMValues;
  tabMitarbeiterAUSTRITT.BeforeLoadDFMValues;
  tabMitarbeiterGRUPPE.BeforeLoadDFMValues;
  dsMitarbeiter.BeforeLoadDFMValues;
  DataSource1.BeforeLoadDFMValues;
  try
    Name := 'frmMitarbeiter';
    Width := 1202;
    Height := 533;
    Color := 16574684;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1202;
    WebPanel1.Height := 35;
    WebPanel1.Align := alTop;
    WebPanel1.Color := 16506058;
    WebPanel1.TabOrder := 0;
    lbMitarbeiter.SetParentComponent(WebPanel1);
    lbMitarbeiter.Name := 'lbMitarbeiter';
    lbMitarbeiter.Left := 16;
    lbMitarbeiter.Top := 6;
    lbMitarbeiter.Width := 106;
    lbMitarbeiter.Height := 23;
    lbMitarbeiter.Caption := 'Mitarbeiter';
    lbMitarbeiter.Font.Charset := DEFAULT_CHARSET;
    lbMitarbeiter.Font.Color := clBlack;
    lbMitarbeiter.Font.Height := -19;
    lbMitarbeiter.Font.Name := 'Tahoma';
    lbMitarbeiter.Font.Style := [fsBold];
    lbMitarbeiter.HeightPercent := 100.000000000000000000;
    lbMitarbeiter.ParentFont := False;
    lbMitarbeiter.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(WebPanel1);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 639;
    WebButton1.Top := 4;
    WebButton1.Width := 96;
    WebButton1.Height := 22;
    WebButton1.Caption := 'Get('#39'MA_DAT'#39;
    WebButton1.ChildOrder := 8;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebPanel1);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 741;
    WebButton2.Top := 4;
    WebButton2.Width := 96;
    WebButton2.Height := 22;
    WebButton2.Caption := 'List';
    WebButton2.ChildOrder := 9;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 35;
    WebPanel2.Width := 34;
    WebPanel2.Height := 498;
    WebPanel2.Align := alLeft;
    WebPanel2.ChildOrder := 2;
    WebPanel2.Color := 16506058;
    WebPanel2.TabOrder := 1;
    mLog.SetParentComponent(Self);
    mLog.Name := 'mLog';
    mLog.Left := 694;
    mLog.Top := 41;
    mLog.Width := 285;
    mLog.Height := 237;
    mLog.Anchors := [akLeft,akTop,akRight,akBottom];
    mLog.HeightPercent := 100.000000000000000000;
    mLog.Lines.BeginUpdate;
    try
      mLog.Lines.Clear;
      mLog.Lines.Add('mLog');
    finally
      mLog.Lines.EndUpdate;
    end;
    mLog.SelLength := 0;
    mLog.SelStart := 0;
    mLog.Visible := False;
    mLog.WidthPercent := 100.000000000000000000;
    edtTemp.SetParentComponent(Self);
    edtTemp.Name := 'edtTemp';
    edtTemp.Left := 488;
    edtTemp.Top := 45;
    edtTemp.Width := 121;
    edtTemp.Height := 22;
    edtTemp.ChildOrder := 23;
    edtTemp.HeightPercent := 100.000000000000000000;
    edtTemp.Text := 'edtTemp';
    edtTemp.Visible := False;
    edtTemp.WidthPercent := 100.000000000000000000;
    grdMitarbeiter.SetParentComponent(Self);
    grdMitarbeiter.Name := 'grdMitarbeiter';
    grdMitarbeiter.Left := 40;
    grdMitarbeiter.Top := 76;
    grdMitarbeiter.Width := 1139;
    grdMitarbeiter.Height := 449;
    grdMitarbeiter.Cursor := crHandPoint;
    grdMitarbeiter.Anchors := [akLeft,akTop,akRight,akBottom];
    grdMitarbeiter.Columns.Clear;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'MA_PERSNR';
      Title := 'Pers.-Nr';
      Width := 71;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'MA_NAME';
      Title := 'Zuname';
      Width := 139;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'MA_VNAME';
      Title := 'Vorname';
      Width := 94;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'KST_REF';
      Title := 'Kst.-Nr';
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'KST_BEZ';
      Title := 'Kostenstelle';
      Width := 141;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'ZEITMODELL';
      Title := 'Zeitmodell';
      Width := 140;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'TARIF';
      Title := 'Tarif';
      Width := 125;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      Alignment := taCenter;
      DataField := 'GEBDAT';
      Title := 'Geburtstag';
      Width := 72;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      Alignment := taCenter;
      DataField := 'EINTRITT';
      Title := 'Eintritt';
      Width := 72;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      Alignment := taCenter;
      DataField := 'AUSTRITT';
      Title := 'Austritt';
      Width := 72;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'GRUPPE';
      Title := 'Mitarbeitergruppe';
      Width := 115;
    end;
    grdMitarbeiter.DataSource := dsMitarbeiter;
    grdMitarbeiter.ElementFont := efCSS;
    grdMitarbeiter.FixedFont.Charset := DEFAULT_CHARSET;
    grdMitarbeiter.FixedFont.Color := clWindowText;
    grdMitarbeiter.FixedFont.Height := -12;
    grdMitarbeiter.FixedFont.Name := 'Segoe UI';
    grdMitarbeiter.FixedFont.Style := [];
    grdMitarbeiter.FixedCols := 0;
    grdMitarbeiter.Font.Charset := DEFAULT_CHARSET;
    grdMitarbeiter.Font.Color := clWindowText;
    grdMitarbeiter.Font.Height := -13;
    grdMitarbeiter.Font.Name := 'Segoe UI';
    grdMitarbeiter.Font.Style := [];
    grdMitarbeiter.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goColSizing,goColMoving,goRowSelect,goFixedRowDefAlign];
    grdMitarbeiter.ParentFont := False;
    grdMitarbeiter.TabOrder := 4;
    grdMitarbeiter.HeightPercent := 100.000000000000000000;
    grdMitarbeiter.WidthPercent := 100.000000000000000000;
    SetEvent(grdMitarbeiter, Self, 'OnDblClick', 'grdMitarbeiterDblClick');
    grdMitarbeiter.ColWidths[0] := 71;
    grdMitarbeiter.ColWidths[1] := 139;
    grdMitarbeiter.ColWidths[2] := 94;
    grdMitarbeiter.ColWidths[3] := 64;
    grdMitarbeiter.ColWidths[4] := 141;
    grdMitarbeiter.ColWidths[5] := 140;
    grdMitarbeiter.ColWidths[6] := 125;
    grdMitarbeiter.ColWidths[7] := 72;
    grdMitarbeiter.ColWidths[8] := 72;
    grdMitarbeiter.ColWidths[9] := 72;
    grdMitarbeiter.ColWidths[10] := 115;
    btnNavPrev.SetParentComponent(Self);
    btnNavPrev.Name := 'btnNavPrev';
    btnNavPrev.Left := 40;
    btnNavPrev.Top := 40;
    btnNavPrev.Width := 33;
    btnNavPrev.Height := 30;
    btnNavPrev.Cursor := crHandPoint;
    btnNavPrev.ParentDoubleBuffered := False;
    btnNavPrev.DoubleBuffered := True;
    btnNavPrev.Font.Charset := DEFAULT_CHARSET;
    btnNavPrev.Font.Color := clWindowText;
    btnNavPrev.Font.Height := -11;
    btnNavPrev.Font.Name := 'Segoe UI';
    btnNavPrev.Font.Style := [];
    btnNavPrev.ParentColor := True;
    btnNavPrev.TabOrder := 5;
    SetEvent(btnNavPrev, Self, 'OnClick', 'Do_DatenLaden');
    btnNavPrev.Text := '';
    btnNavPrev.Bitmaps.Clear;
    with btnNavPrev.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Mitarbeiter.btnNavPrev.Bitmaps.Bitmap.png');
    end;
    btnNavPrev.BitmapVisible := True;
    btnNavPrev.ControlIndex := 0;
    btnNavNext.SetParentComponent(Self);
    btnNavNext.Name := 'btnNavNext';
    btnNavNext.Left := 75;
    btnNavNext.Top := 40;
    btnNavNext.Width := 33;
    btnNavNext.Height := 30;
    btnNavNext.Cursor := crHandPoint;
    btnNavNext.ParentDoubleBuffered := False;
    btnNavNext.DoubleBuffered := True;
    btnNavNext.Font.Charset := DEFAULT_CHARSET;
    btnNavNext.Font.Color := clWindowText;
    btnNavNext.Font.Height := -11;
    btnNavNext.Font.Name := 'Segoe UI';
    btnNavNext.Font.Style := [];
    btnNavNext.ParentColor := True;
    btnNavNext.TabOrder := 6;
    SetEvent(btnNavNext, Self, 'OnClick', 'Do_DatenLaden');
    btnNavNext.Text := '';
    btnNavNext.Bitmaps.Clear;
    with btnNavNext.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Mitarbeiter.btnNavNext.Bitmaps.Bitmap.png');
    end;
    btnNavNext.BitmapVisible := True;
    btnNavNext.ControlIndex := 0;
    btnNavInfo.SetParentComponent(Self);
    btnNavInfo.Name := 'btnNavInfo';
    btnNavInfo.Left := 111;
    btnNavInfo.Top := 40;
    btnNavInfo.Width := 94;
    btnNavInfo.Height := 30;
    btnNavInfo.Hint := 'Daten laden';
    btnNavInfo.ParentDoubleBuffered := False;
    btnNavInfo.DoubleBuffered := True;
    btnNavInfo.Font.Charset := DEFAULT_CHARSET;
    btnNavInfo.Font.Color := clWindowText;
    btnNavInfo.Font.Height := -11;
    btnNavInfo.Font.Name := 'Segoe UI';
    btnNavInfo.Font.Style := [];
    btnNavInfo.ParentColor := True;
    btnNavInfo.TabOrder := 7;
    SetEvent(btnNavInfo, Self, 'OnClick', 'btnNavInfoClick');
    btnNavInfo.Text := '1 - 50 [254]';
    btnNavInfo.ControlIndex := 0;
    btnTest.SetParentComponent(Self);
    btnTest.Name := 'btnTest';
    btnTest.Left := 224;
    btnTest.Top := 46;
    btnTest.Width := 217;
    btnTest.Height := 24;
    btnTest.ParentDoubleBuffered := False;
    btnTest.DoubleBuffered := True;
    btnTest.Font.Charset := DEFAULT_CHARSET;
    btnTest.Font.Color := clWindowText;
    btnTest.Font.Height := -11;
    btnTest.Font.Name := 'Segoe UI';
    btnTest.Font.Style := [];
    btnTest.ParentColor := True;
    btnTest.TabOrder := 8;
    SetEvent(btnTest, Self, 'OnClick', 'btnTestClick');
    btnTest.Text := 'Test';
    btnTest.ControlIndex := 0;
    XDataWebClient.SetParentComponent(Self);
    XDataWebClient.Name := 'XDataWebClient';
    XDataWebClient.Left := 272;
    XDataWebClient.Top := 208;
    tabMitarbeiter.SetParentComponent(Self);
    tabMitarbeiter.Name := 'tabMitarbeiter';
    tabMitarbeiter.Left := 136;
    tabMitarbeiter.Top := 208;
    tabMitarbeiterMA_NR.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_NR.Name := 'tabMitarbeiterMA_NR';
    tabMitarbeiterMA_NR.Alignment := taCenter;
    tabMitarbeiterMA_NR.DisplayLabel := 'MaNr';
    tabMitarbeiterMA_NR.DisplayWidth := 6;
    tabMitarbeiterMA_NR.FieldName := 'MA_NR';
    tabMitarbeiterMA_NR.Required := True;
    tabMitarbeiterMA_NAME.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_NAME.Name := 'tabMitarbeiterMA_NAME';
    tabMitarbeiterMA_NAME.DisplayLabel := 'Zuname';
    tabMitarbeiterMA_NAME.DisplayWidth := 25;
    tabMitarbeiterMA_NAME.FieldName := 'MA_NAME';
    tabMitarbeiterMA_NAME.Required := True;
    tabMitarbeiterMA_NAME.Size := 30;
    tabMitarbeiterMA_VNAME.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_VNAME.Name := 'tabMitarbeiterMA_VNAME';
    tabMitarbeiterMA_VNAME.DisplayLabel := 'Vorname';
    tabMitarbeiterMA_VNAME.DisplayWidth := 20;
    tabMitarbeiterMA_VNAME.FieldName := 'MA_VNAME';
    tabMitarbeiterMA_VNAME.Size := 30;
    tabMitarbeiterMA_PERSNR.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_PERSNR.Name := 'tabMitarbeiterMA_PERSNR';
    tabMitarbeiterMA_PERSNR.FieldName := 'MA_PERSNR';
    tabMitarbeiterKST_NAME.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterKST_NAME.Name := 'tabMitarbeiterKST_NAME';
    tabMitarbeiterKST_NAME.DisplayWidth := 10;
    tabMitarbeiterKST_NAME.FieldName := 'KST_REF';
    tabMitarbeiterKST_NAME.Size := 10;
    tabMitarbeiterKST_BEZ.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterKST_BEZ.Name := 'tabMitarbeiterKST_BEZ';
    tabMitarbeiterKST_BEZ.DisplayWidth := 20;
    tabMitarbeiterKST_BEZ.FieldName := 'KST_BEZ';
    tabMitarbeiterKST_BEZ.Size := 160;
    tabMitarbeiterZEITMODELL.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterZEITMODELL.Name := 'tabMitarbeiterZEITMODELL';
    tabMitarbeiterZEITMODELL.DisplayWidth := 15;
    tabMitarbeiterZEITMODELL.FieldName := 'ZEITMODELL';
    tabMitarbeiterZEITMODELL.Size := 100;
    tabMitarbeiterTARIF.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterTARIF.Name := 'tabMitarbeiterTARIF';
    tabMitarbeiterTARIF.DisplayWidth := 15;
    tabMitarbeiterTARIF.FieldName := 'TARIF';
    tabMitarbeiterGEBDAT.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterGEBDAT.Name := 'tabMitarbeiterGEBDAT';
    tabMitarbeiterGEBDAT.Alignment := taCenter;
    tabMitarbeiterGEBDAT.FieldName := 'GEBDAT';
    tabMitarbeiterGEBDAT.Size := 10;
    tabMitarbeiterEINTRITT.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterEINTRITT.Name := 'tabMitarbeiterEINTRITT';
    tabMitarbeiterEINTRITT.Alignment := taCenter;
    tabMitarbeiterEINTRITT.FieldName := 'EINTRITT';
    tabMitarbeiterEINTRITT.Size := 10;
    tabMitarbeiterAUSTRITT.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterAUSTRITT.Name := 'tabMitarbeiterAUSTRITT';
    tabMitarbeiterAUSTRITT.Alignment := taCenter;
    tabMitarbeiterAUSTRITT.FieldName := 'AUSTRITT';
    tabMitarbeiterAUSTRITT.Size := 10;
    tabMitarbeiterGRUPPE.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterGRUPPE.Name := 'tabMitarbeiterGRUPPE';
    tabMitarbeiterGRUPPE.DisplayWidth := 19;
    tabMitarbeiterGRUPPE.FieldName := 'GRUPPE';
    tabMitarbeiterGRUPPE.Size := 25;
    dsMitarbeiter.SetParentComponent(Self);
    dsMitarbeiter.Name := 'dsMitarbeiter';
    dsMitarbeiter.DataSet := tabMitarbeiter;
    dsMitarbeiter.Left := 136;
    dsMitarbeiter.Top := 272;
    DataSource1.SetParentComponent(Self);
    DataSource1.Name := 'DataSource1';
    DataSource1.DataSet := tabMitarbeiter;
  finally
    WebPanel1.AfterLoadDFMValues;
    lbMitarbeiter.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    mLog.AfterLoadDFMValues;
    edtTemp.AfterLoadDFMValues;
    grdMitarbeiter.AfterLoadDFMValues;
    btnNavPrev.AfterLoadDFMValues;
    btnNavNext.AfterLoadDFMValues;
    btnNavInfo.AfterLoadDFMValues;
    btnTest.AfterLoadDFMValues;
    XDataWebClient.AfterLoadDFMValues;
    tabMitarbeiter.AfterLoadDFMValues;
    tabMitarbeiterMA_NR.AfterLoadDFMValues;
    tabMitarbeiterMA_NAME.AfterLoadDFMValues;
    tabMitarbeiterMA_VNAME.AfterLoadDFMValues;
    tabMitarbeiterMA_PERSNR.AfterLoadDFMValues;
    tabMitarbeiterKST_NAME.AfterLoadDFMValues;
    tabMitarbeiterKST_BEZ.AfterLoadDFMValues;
    tabMitarbeiterZEITMODELL.AfterLoadDFMValues;
    tabMitarbeiterTARIF.AfterLoadDFMValues;
    tabMitarbeiterGEBDAT.AfterLoadDFMValues;
    tabMitarbeiterEINTRITT.AfterLoadDFMValues;
    tabMitarbeiterAUSTRITT.AfterLoadDFMValues;
    tabMitarbeiterGRUPPE.AfterLoadDFMValues;
    dsMitarbeiter.AfterLoadDFMValues;
    DataSource1.AfterLoadDFMValues;
  end;
end;

end.


unit MitarbeiterEdit;

interface


uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls, System.Generics.Collections,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls, WEBLib.ExtCtrls,
  XData.Web.Client, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCDateTimePicker, WEBLib.ComCtrls, Bcl.Utils, CP.Func.AES ;

type
  TfrmMitarbeiterEdit = class(TForm)
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    edtZuName: TEdit;
    WebLabel2: TLabel;
    edtVorname: TEdit;
    WebLabel3: TLabel;
    WebButton1: TButton;
    WebButton2: TButton;
    [async]
    WebLabel4: TLabel;
    edtPersNr: TEdit;
    dtEintritt: TDateTimePicker;
    WebLabel5: TLabel;
    cbxZM: TComboBox;
    WebPageControl1: TPageControl;
    WebPageControl1Sheet1: TTabSheet;
    WebPageControl1Sheet2: TTabSheet;
    WebLabel6: TLabel;
    [async] procedure WebFormShow(Sender: TObject); async;
    [async] procedure WebFormCreate(Sender: TObject); async;

  private


  public
    iMaNr : Integer;
    [async] function Daten_Ins_Formular : Integer; async;

  protected procedure LoadDFMValues; override; end;


var frmMitarbeiterEdit: TfrmMitarbeiterEdit;

implementation

{$R *.dfm}

uses UnitMain,  PzsDatenmodul;

//================================================================================================//

procedure TfrmMitarbeiterEdit.WebFormCreate(Sender: TObject);
begin

 frmMainForm.LogAction('Start - WebFormCreate',False);

 frmMainForm.LogAction('Ende  - WebFormCreate',False);

end;

//================================================================================================//

procedure TfrmMitarbeiterEdit.WebFormShow(Sender: TObject);
begin

 frmMainForm.LogAction('Start - WebFormShow',False);

// await( dmPzs.FillCbx_Mit_Zeitmodell( cbxZM ) );
// await(  Daten_Ins_Formular  );

 frmMainForm.LogAction('Ende - WebFormShow',False);

end;

//================================================================================================//

function TfrmMitarbeiterEdit.Daten_Ins_Formular : Integer;
var //aObjResult : TWebCoreObj_MA;
    Response: TXDataClientResponse;
    ClientConn: TXDataWebClient;
    i : Integer;
    //aZM_List : TObjectList<TWebCoreObj_ZM>;
    aSL : TStringList;
begin

// frmMainForm.LogAction('Start - Daten_Ins_Formular',False);
//
//
//  aObjResult := TWebCoreObj_MA.Create;
//
//  ClientConn := TXDataWebClient.Create(nil);
//  ClientConn.Connection := frmMainForm.XDataConn;
//
//  Response := await( ClientConn.RawInvokeAsync('ISystemService.MitarbeiterGet', [ self.iMaNr] ));
//
//  ClientConn.Free;
//
//  //------------------------ Daten jetzt noch entschlüsseln
//  edtZuname.Text := sDaten;
//
//  frmMainForm.LogAction('   1 :  TfrmMitarbeiter.Daten_Laden',false);
//
//  TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(edtZuname.Text),
//    procedure(const AEncrypted: string)
//      begin
//
//        aSL := TStringList.Create;
//        aSL.Text := AEncrypted; // mLog.Lines.Text;
//
//
//        tabMitarbeiter.Close;
//        tabMitarbeiter.Open;
//
//        sKopfZeile := aSL[0];
//        aArray := sKopfZeile.Split([';']);
//        btnNavInfo.Text := aArray[3]+'-'+aArray[4]+' ['+aArray[2]+']';
//
//
//        For i := 2 to aSL.Count -1 Do
//            Begin
//             sTmp := aSL[i];
//              aArray := sTmp.Split([';']);
//              tabMitarbeiter.Append;
//              tabMitarbeiter.FieldByName('MA_NR').AsInteger     := StrToIntDef(aArray[0],0);
//              tabMitarbeiter.FieldByName('MA_PERSNR').AsString  := aArray[1];
//              tabMitarbeiter.FieldByName('MA_NAME').AsString    := aArray[2];
//              tabMitarbeiter.FieldByName('MA_VNAME').AsString   := aArray[3];
//              tabMitarbeiter.FieldByName('KST_REF').AsString    := aArray[4];
//              tabMitarbeiter.FieldByName('KST_BEZ').AsString    := aArray[5];
//              tabMitarbeiter.FieldByName('ZEITMODELL').AsString := aArray[6];
//              tabMitarbeiter.FieldByName('TARIF').AsString      := aArray[7];
//              tabMitarbeiter.FieldByName('GEBDAT').AsString     := aArray[8];
//              tabMitarbeiter.FieldByName('EINTRITT').AsString   := aArray[9];
//              tabMitarbeiter.FieldByName('AUSTRITT').AsString   := aArray[10];
//              tabMitarbeiter.FieldByName('GRUPPE').AsString     := aArray[11];
//
//              tabMitarbeiter.Post;
//            End;
//
//        aSL.Free;
//
//        If aID = 0 then tabMitarbeiter.First;
//        If aID > 0 then
//          If not tabMitarbeiter.Locate('MA_NR',aID,[]) then tabMitarbeiter.First;
//
//        btnNavNext.Enabled := (tabMitarbeiter.RecordCount=frmMainForm.g_RecAnzahl );
//        btnNavInfo.Enabled := True;
//
//        frmMainForm.LogAction('Ende:  TfrmMitarbeiter.Daten_Laden',False);
//
//      end,
//    procedure(const AError: string)
//     begin
//      ShowMessage(AError);
//     end);
//










//  aObjResult := TWebCoreObj_MA( Response.Result );
//
//  edtZuName.Text  := aObjResult.MA_NAME;
//  edtVorname.Text := aObjResult.MA_VNAME;
//  edtPersNr.Text  := aObjResult.PERSNR;
//  dtEintritt.Date := aObjResult.EINTRITT;
//
//  dmPzs.SetInCbx_Zeitmodell( cbxZM, aObjResult.WMOD_NUMMER );


 frmMainForm.LogAction('Ende  - Daten_Ins_Formular',False);

end;

//================================================================================================//

procedure TfrmMitarbeiterEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  edtZuName := TEdit.Create(Self);
  edtVorname := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  edtPersNr := TEdit.Create(Self);
  dtEintritt := TDateTimePicker.Create(Self);
  cbxZM := TComboBox.Create(Self);
  WebPageControl1 := TPageControl.Create(Self);
  WebPageControl1Sheet1 := TTabSheet.Create(Self);
  WebPageControl1Sheet2 := TTabSheet.Create(Self);

  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  edtZuName.BeforeLoadDFMValues;
  edtVorname.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  edtPersNr.BeforeLoadDFMValues;
  dtEintritt.BeforeLoadDFMValues;
  cbxZM.BeforeLoadDFMValues;
  WebPageControl1.BeforeLoadDFMValues;
  WebPageControl1Sheet1.BeforeLoadDFMValues;
  WebPageControl1Sheet2.BeforeLoadDFMValues;
  try
    Name := 'frmMitarbeiterEdit';
    Width := 699;
    Height := 553;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 19;
    WebLabel2.Top := 88;
    WebLabel2.Width := 46;
    WebLabel2.Height := 17;
    WebLabel2.Caption := 'Zuname';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 19;
    WebLabel3.Top := 116;
    WebLabel3.Width := 52;
    WebLabel3.Height := 17;
    WebLabel3.Caption := 'Vorname';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 395;
    WebLabel4.Top := 88;
    WebLabel4.Width := 99;
    WebLabel4.Height := 17;
    WebLabel4.Caption := 'Pers.-Nr.................';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 395;
    WebLabel5.Top := 116;
    WebLabel5.Width := 85;
    WebLabel5.Height := 17;
    WebLabel5.Caption := 'Eintritt................';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 16;
    WebLabel6.Top := 149;
    WebLabel6.Width := 61;
    WebLabel6.Height := 17;
    WebLabel6.Caption := 'Zeitmodell';
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 699;
    WebPanel1.Height := 45;
    WebPanel1.Align := alTop;
    WebPanel1.Color := 16506058;
    WebPanel1.TabOrder := 0;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 9;
    WebLabel1.Width := 201;
    WebLabel1.Height := 25;
    WebLabel1.Caption := 'Mitarbeiter bearbeiten';
    WebLabel1.Color := clBlack;
    WebLabel1.Font.Charset := ANSI_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -19;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    edtZuName.SetParentComponent(Self);
    edtZuName.Name := 'edtZuName';
    edtZuName.Left := 85;
    edtZuName.Top := 85;
    edtZuName.Width := 284;
    edtZuName.Height := 22;
    edtZuName.ChildOrder := 1;
    edtZuName.HeightPercent := 100.000000000000000000;
    edtZuName.Text := 'edtZuName';
    edtZuName.WidthPercent := 100.000000000000000000;
    edtVorname.SetParentComponent(Self);
    edtVorname.Name := 'edtVorname';
    edtVorname.Left := 85;
    edtVorname.Top := 113;
    edtVorname.Width := 284;
    edtVorname.Height := 22;
    edtVorname.ChildOrder := 1;
    edtVorname.HeightPercent := 100.000000000000000000;
    edtVorname.Text := 'edtName';
    edtVorname.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 462;
    WebButton1.Top := 504;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Cursor := crHandPoint;
    WebButton1.Anchors := [akRight,akBottom];
    WebButton1.Caption := 'OK';
    WebButton1.ChildOrder := 5;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ModalResult := 1;
    WebButton1.WidthPercent := 100.000000000000000000;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 585;
    WebButton2.Top := 504;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Cursor := crHandPoint;
    WebButton2.Anchors := [akRight,akBottom];
    WebButton2.Caption := 'Abbrechen';
    WebButton2.ChildOrder := 6;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.ModalResult := 2;
    WebButton2.WidthPercent := 100.000000000000000000;
    edtPersNr.SetParentComponent(Self);
    edtPersNr.Name := 'edtPersNr';
    edtPersNr.Left := 469;
    edtPersNr.Top := 85;
    edtPersNr.Width := 92;
    edtPersNr.Height := 22;
    edtPersNr.ChildOrder := 1;
    edtPersNr.HeightPercent := 100.000000000000000000;
    edtPersNr.Text := 'edtName';
    edtPersNr.WidthPercent := 100.000000000000000000;
    dtEintritt.SetParentComponent(Self);
    dtEintritt.Name := 'dtEintritt';
    dtEintritt.Left := 469;
    dtEintritt.Top := 113;
    dtEintritt.Width := 105;
    dtEintritt.Height := 22;
    dtEintritt.BorderStyle := bsSingle;
    dtEintritt.ChildOrder := 10;
    dtEintritt.Color := clWhite;
    dtEintritt.Date := 45552.477030590280000000;
    dtEintritt.Role := '';
    dtEintritt.Text := '';
    cbxZM.SetParentComponent(Self);
    cbxZM.Name := 'cbxZM';
    cbxZM.Left := 85;
    cbxZM.Top := 146;
    cbxZM.Width := 189;
    cbxZM.Height := 25;
    cbxZM.Cursor := crHandPoint;
    cbxZM.HeightPercent := 100.000000000000000000;
    cbxZM.Text := '****';
    cbxZM.WidthPercent := 100.000000000000000000;
    cbxZM.ItemIndex := 0;
    cbxZM.Items.BeginUpdate;
    try
      cbxZM.Items.Clear;
      cbxZM.Items.Add('****');
    finally
      cbxZM.Items.EndUpdate;
    end;
    WebPageControl1.SetParentComponent(Self);
    WebPageControl1.Name := 'WebPageControl1';
    WebPageControl1.Left := 22;
    WebPageControl1.Top := 208;
    WebPageControl1.Width := 653;
    WebPageControl1.Height := 267;
    WebPageControl1.ChildOrder := 12;
    WebPageControl1.TabIndex := 0;
    WebPageControl1.TabOrder := 8;
    WebPageControl1Sheet1.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet1.Name := 'WebPageControl1Sheet1';
    WebPageControl1Sheet1.Left := 0;
    WebPageControl1Sheet1.Top := 20;
    WebPageControl1Sheet1.Width := 653;
    WebPageControl1Sheet1.Height := 247;
    WebPageControl1Sheet1.Caption := 'Daten';
    WebPageControl1Sheet2.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet2.Name := 'WebPageControl1Sheet2';
    WebPageControl1Sheet2.Left := 0;
    WebPageControl1Sheet2.Top := 20;
    WebPageControl1Sheet2.Width := 653;
    WebPageControl1Sheet2.Height := 247;
    WebPageControl1Sheet2.Caption := 'Informationen';
    WebPageControl1Sheet2.ChildOrder := 1;
  finally
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    edtZuName.AfterLoadDFMValues;
    edtVorname.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    edtPersNr.AfterLoadDFMValues;
    dtEintritt.AfterLoadDFMValues;
    cbxZM.AfterLoadDFMValues;
    WebPageControl1.AfterLoadDFMValues;
    WebPageControl1Sheet1.AfterLoadDFMValues;
    WebPageControl1Sheet2.AfterLoadDFMValues;
  end;
end;

end.

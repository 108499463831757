unit AbwVerwaltung;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls, UnitMain,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.Grids, Vcl.Grids,
  WEBLib.DBCtrls, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions, VCL.TMSFNCCustomComponent, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGridDatabaseAdapter, VCL.TMSFNCCustomControl, VCL.TMSFNCCustomScrollControl, DateUtils,
  VCL.TMSFNCGridData, VCL.TMSFNCGrid, VCL.TMSFNCToolBar, XData.Web.Client, Types, VCL.TMSFNCEdit,
  Vcl.Menus, WEBLib.Menus;




type  // für Abwesenheitsverwaltung
   TZelle = class
     Col    : Integer ;
     Row    : Integer ;
     Datum  : TDateTime ;
     AbwList : TList;
   End;


type
  TfrmAbwVerwaltung = class(TForm)
    pnlStatus: TPanel;
    lbZeitbuchungen: TLabel;
    tabMitarbeiter: TXDataWebDataSet;
    tabMitarbeiterMA_NR: TIntegerField;
    tabMitarbeiterMA_NAME: TStringField;
    tabMitarbeiterMA_VNAME: TStringField;
    dsMitarbeiter: TDataSource;
    grdZeitBuchung: TDBGrid;
    btnNavPrev: TTMSFNCToolBarButton;
    btnNavNext: TTMSFNCToolBarButton;
    btnNavInfo: TTMSFNCToolBarButton;
    edtTemp: TEdit;
    tabMitarbeiterMA_PERSNR: TStringField;
    WebPanel1: TPanel;
    grdDaten: TStringGrid;
    WebButton1: TButton;
    mlog: TMemo;
    edtJahr: TTMSFNCEdit;
    btnJahrNext: TTMSFNCToolBarButton;
    btnJahrPrev: TTMSFNCToolBarButton;
    tabAbw: TXDataWebDataSet;
    IntegerField1: TIntegerField;
    tabAbwKAL_DATE: TDateTimeField;
    tabAbwABW_DAUER: TIntegerField;
    tabAbwANWABW_NR: TIntegerField;
    tabAbwID_ABW_LANG: TIntegerField;
    tabAbwABW_ERSTKZ: TIntegerField;
    tabAbwANWABW_BEZ: TStringField;
    tabAbwANWABW_ZEICHEN: TStringField;
    tabAbwANWABW_FARBE: TStringField;
    tabAbwGANZER_TAG: TIntegerField;
    tabAbwKST: TStringField;
    dsAbw: TDataSource;
    tabAbwINS_AM: TStringField;
    edtMitarbeiter: TTMSFNCEdit;
    WebPopupMenu1: TPopupMenu;
    Eintragen1: TMenuItem;
    Entfernen1: TMenuItem;
    cbxAbw: TComboBox;
    tabAnwAbw: TXDataWebDataSet;
    tabAnwAbwANWABW_NR: TIntegerField;
    tabAnwAbwANWABW_BEZ: TStringField;
    tabAnwAbwANWABW_TYP: TIntegerField;
    tabAnwAbwANWABW_KURZ: TStringField;
    tabAnwAbwANWABW_FARBE: TStringField;

    lbPos: TLabel;

    tabAbwID: TIntegerField;
    [async] procedure AnwAbw_Laden( aID : Integer ); async;
    [async] procedure Ma_Laden( aID : Integer ); async;
    [async] procedure MaAbw_Laden( aMaNr : Integer ); async;

    [async] procedure WebFormShow(Sender: TObject); async;
    [async] procedure WebFormCreate(Sender: TObject); async;

    [async]  procedure grdDatenKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState); async;

    procedure Do_Laden(Sender: TObject);
    procedure btnNavInfoClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure grdDatenClickCell(Sender: TObject; ACol, ARow: Integer);
    procedure grdZeitBuchungDblClick(Sender: TObject);
    procedure DoJahrNavigieren(Sender: TObject);
    procedure grdDatenMouseMove(Sender: TObject; Shift: TShiftState; X, Y: Integer);
    procedure grdDatenMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
    procedure grdDatenMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
    procedure grdDatenSelectCell(Sender: TObject; ACol, ARow: LongInt; var CanSelect: Boolean);
    procedure Eintragen1Click(Sender: TObject);

    procedure WebFormMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);


  private
    iCol : Integer;
    iRow : Integer;
    iRec_Von : Integer;
    iRec_Bis : Integer;

    bFreiSam : Boolean;
    bFreiSon : Boolean;

    function  GetZelle_By_Tag( dtTag : TDateTime) : TZelle;
    function  GetSchriftFarbe( colHintergrund: TColor) : TColor;
    procedure Grid_Einstellen;
    procedure Grid_Initialisieren;
    procedure ZellenObjekte_Erzeugen;
    procedure ZellenObjekte_Freigeben;
    procedure ZellenObjekte_SetDatum;
    procedure ZellenObjekte_Reset;
    procedure MaAbw_Nach_Zelle;
    procedure WE_Nach_Zelle;
    procedure ZeichneZelle( aZellenObjekt : TZelle );

  public

  protected procedure LoadDFMValues; override; end;


var frmAbwVerwaltung: TfrmAbwVerwaltung;

const ch = chr(39);

implementation

{$R *.dfm}

uses Bcl.Utils, CP.Func.AES , PzsDatenmodul;

//================================================================================================//

procedure TfrmAbwVerwaltung.WebButton1Click(Sender: TObject);
var ACanvas: TCanvas;
    el: TJSHTMLCanvasELement;
    i, j, iNr : Integer;
begin

 Showmessage(   IntToStr( dmPzs.tabAnwAbw.RecordCount )   );

//  AnwAbw_Laden(0);

//frmMainForm.FillCBX_Mit_AnwAbw(cbxAbw,False);


exit;
  grdDaten.ColCount := 31;
  grdDaten.RowCount := 14;



//  grdDaten.AddCanvas(2,2);
//  el := grdDaten.GetCanvas(2,2);
//  ACanvas := TCanvas.Create(el);
//  ACanvas.MoveTo(0,0);
//  ACanvas.LineTo( grdDaten.ColWidths[2]-1,grdDaten.RowHeights[2]-1 );
//  ACanvas.Free;


  grdDaten.BeginUpdate;

//  iNr := 0;
//  for I := 0 to grdDaten.RowCount - 1 do
//    begin
//      for J := 0 to grdDaten.ColCount - 1 do
//        Begin
//          Inc(iNr);
//          grdDaten.Cells[J, I] := IntToStr(iNr);
//        End;
//    end;

  grdDaten.EndUpdate;

  Grid_Einstellen;


end;

//================================================================================================//

procedure TfrmAbwVerwaltung.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 ZellenObjekte_Freigeben;
end;

//================================================================================================//

procedure TfrmAbwVerwaltung.WebFormCreate(Sender: TObject);
var el: TJSElement;
begin

//  el := grdDaten.ElementHandle;
//  asm
//   // el.ContextMenu := nil;
//    el.OnContextMenu = function() { return false; };
//    el.ondragstart   = function() { return false; };
//    el.ontouchstart  = function() { return false; };
//    el.ontouchmove   = function() { return false; };
//    el.ontouchend    = function() { return false; };
//    el.ontouchcancel = function() { return false; };
//  end;


  //grdDaten.EnableContextMenu := False;
  //TTMSFNCMapsOpen(grdDaten).Settings.EnableContextMenu := False;


  edtJahr.IntValue :=  Trunc( Date );

  tabMitarbeiter.Connection   := frmMainForm.XDataConn;


  iRec_Von := 1;
  iRec_Bis := frmMainForm.g_RecAnzahl;

  ZellenObjekte_Erzeugen;

  ZellenObjekte_SetDatum;


end;

//================================================================================================//

procedure TfrmAbwVerwaltung.WebFormMouseDown(Sender: TObject; Button: TMouseButton;
  Shift: TShiftState; X, Y: Integer);
begin

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.WebFormResize(Sender: TObject);
begin

   Grid_Einstellen;

end;


//================================================================================================//

procedure TfrmAbwVerwaltung.Grid_Initialisieren;
var c, r, iBreite : Integer;
begin

  For r := 1 to 12 Do
    Begin
      grdDaten.Cells[0,r] := FormatSettings.LongMonthNames[r];
    End;


  For c := 1 to 31 Do
    Begin
      grdDaten.Cells[c,0] := IntToStr(c);
    End;


//    grdDaten.obj Objects[c,r]


end;

//================================================================================================//

procedure TfrmAbwVerwaltung.Grid_Einstellen;
var c, r, iBreite, iBreiteMonat : Integer;
begin


  iBreiteMonat := 90;

  iBreite := Trunc(  (grdDaten.Width-iBreiteMonat) / grdDaten.ColCount );


  grdDaten.BeginUpdate;

    grdDaten.ColWidths[0] := 90;

    For c := 1 to grdDaten.ColCount - 1 Do
      grdDaten.ColWidths[c] := iBreite;

  grdDaten.EndUpdate;

  self.Width := self.Width + 1;
  self.Width := self.Width - 1;

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.WebFormShow(Sender: TObject);
begin

  frmMainForm.LogAction('',False) ;
  frmMainForm.LogAction('Start - TfrmAbwVerwaltung.WebFormShow',False) ;

  Ma_Laden(0);
  //asm await sleep(500); end;


  //TAwait.Exec<string>(frmMainForm.AnwAbw_Laden);
  //AnwAbw_Laden(0);

  await ( dmPzs.Fill_AnwAbw( cbxAbw, False ) );


  //asm await sleep(500); end;

  bFreiSam := True;
  bFreiSon := True;
  WE_Nach_Zelle;


  Grid_Initialisieren;
  Grid_Einstellen;

  frmMainForm.LogAction('Ende - TfrmAbwVerwaltung.WebFormShow',False) ;

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.DoJahrNavigieren(Sender: TObject);
var aMaNr : Integer;
begin

  If Sender = btnJahrPrev then edtJahr.Text := IntToStr( StrToInt(edtJahr.Text) -1 );
  If Sender = btnJahrNext then edtJahr.Text := IntToStr( StrToInt(edtJahr.Text) +1 );

  ZellenObjekte_SetDatum;

  aMaNr := tabMitarbeiterMA_NR.AsInteger;
  If aMaNr=0 then Exit;

  MaAbw_Laden( aMaNr );

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.btnNavInfoClick(Sender: TObject);
var iID : Integer;
begin

  iRec_Von := 1;
  iRec_Bis := frmMainForm.g_RecAnzahl;

  iID := tabMitarbeiter.FieldByName('MA_NR').AsInteger;
  Ma_Laden(iID);

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.Do_Laden(Sender: TObject);
begin

  If Sender = btnNavNext then
    Begin
     iRec_Von := iRec_Von + frmMainForm.g_RecAnzahl;
    End
  Else
    Begin
     iRec_Von := iRec_Von - frmMainForm.g_RecAnzahl;
     If iRec_Von < 1 then iRec_Von := 1;
    End;
  iRec_Bis := iRec_Von + frmMainForm.g_RecAnzahl-1;

  Ma_Laden(0);

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.Eintragen1Click(Sender: TObject);
begin

end;

//================================================================================================//

function TfrmAbwVerwaltung.GetZelle_By_Tag( dtTag : TDateTime) : TZelle;
var iCol, iRow : Integer;
     aZellenObjekt : TZelle;
begin

  Result := nil;

  For iCol := 1 to grdDaten.ColCount - 1 do
    For iRow := 1 to grdDaten.RowCount - 1 do
    Begin
      aZellenObjekt := TZelle( grdDaten.Objects[iCol, iRow] );
      If aZellenObjekt.Datum = dtTag then
        Begin
          Result := aZellenObjekt;
          Break;
        End;
    End;

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.ZellenObjekte_SetDatum;
var iCol, iRow : Integer;
     aZellenObjekt : TZelle;
begin

  For iCol := 1 to grdDaten.ColCount - 1 do
    For iRow := 1 to grdDaten.RowCount - 1 do
    Begin
      aZellenObjekt := TZelle( grdDaten.Objects[iCol, iRow] );

      Try
        aZellenObjekt.Datum := EncodeDate( StrToInt(edtJahr.Text), iRow, iCol );
        //mLog.Lines.Add( DateTimeToStr(aZellenObjekt.Datum) );
      Except
        aZellenObjekt.Datum := 0;
      End;

    End;

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.ZellenObjekte_Reset;
var i, iCol, iRow : Integer;
     aZellenObjekt : TZelle;
//     aAbw : TAbw;
     el: TJSHTMLCanvasELement;
     ACanvas : TCanvas;
begin
//
//  For iCol := 1 to grdDaten.ColCount - 1 do
//    For iRow := 1 to grdDaten.RowCount - 1 do
//    Begin
//      aZellenObjekt := TZelle( grdDaten.Objects[iCol, iRow] );
//
//      For i := 0 to aZellenObjekt.AbwList.Count-1 Do
//        Begin
//          aAbw := TAbw(aZellenObjekt.AbwList[i]);
//          aAbw.Free;
//        End;
//      aZellenObjekt.AbwList.Clear;
//
//      If grdDaten.HasCanvas( iCol, iRow) then
//        grdDaten.RemoveCanvas( icol, iRow);
//
//    End;

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.grdDatenClickCell(Sender: TObject; ACol, ARow: Integer);
var aZelle : TZelle;
begin

//  If (GetAsyncKeyState(VK_CONTROL) and (1 shl 15) > 0) then

   self.iCol := aCol;
   self.iRow := aRow;
   //mLog.Lines.Add('ClickCell aCol: '+ IntToStr(aCol)+'/'+IntToStr(aRow) );

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.grdDatenKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
var sTmp : String;
    iID, iAbwNr, iTag, iMaNr : Integer;
    aZellenObjekt : TZelle;
//    aAbw, aAbwObj : TAbw;
    el: TJSHTMLCanvasELement;
    ACanvas: TCanvas;
    ClientConn : TXDataWebClient;
    Response: TXDataClientResponse;
//    aWebAbwBuchung : TWebAbwBuchung;
begin

//  sTmp := IntToStr(iCol)+'/'+inttostr(iRow);
//
//  If not (Key in [VK_INSERT,VK_DELETE ]) then Exit;
//
//  aAbwObj := TAbw(cbxAbw.Items.Objects[cbxAbw.ItemIndex]);
//
//  aZellenObjekt := TZelle( grdDaten.Objects[grdDaten.Col, grdDaten.Row] );
//
//  If aZellenObjekt.Datum = 0 then Exit;
//
//  sTmp := 'Buchen: '+inttostr(aAbwObj.ID)+' am '+datetimeTostr(aZellenObjekt.Datum );
//
//  iTag := Trunc(aZellenObjekt.Datum);
//  iMaNr := edtMitarbeiter.Tag;
//
//
//  If Key = VK_INSERT then
//    Begin
//      aAbw := TAbw.Create;
//      aAbw.AbwNr    := aAbwObj.AbwNr;
//      aAbw.AbwLang  := aAbwObj.AbwLang;
//      aAbw.AbwKurz  := aAbwObj.AbwKurz;
//      aAbw.AbwFarbe := aAbwObj.AbwFarbe;
//      aZellenObjekt.AbwList.Add(aAbw);
//
//      ZeichneZelle( aZellenObjekt );
//
//      aWebAbwBuchung := TWebAbwBuchung.Create;
//      aWebAbwBuchung.MaNr := iMaNr;
//      aWebAbwBuchung.AnwAbwNr := aAbwObj.AbwNr;;
//      aWebAbwBuchung.Datum    := aZellenObjekt.Datum;
//      aWebAbwBuchung.UserMaNr := StrToIntDef( frmMainForm.BenutzerMaNr, -1);
//        ClientConn := TXDataWebClient.Create(nil);
//        ClientConn.Connection := frmMainForm.XDataConn;
//        Response := await( ClientConn.RawInvokeAsync('ISystemService.AbwIns', [ aWebAbwBuchung ] ));
//        ClientConn.Free;
//      aWebAbwBuchung.Free;
//
//    End;
//
//  If (Key = VK_DELETE) and (aZellenObjekt.AbwList.Count > 0) then
//    Begin
//      // löschen
//      aAbw := TAbw(aZellenObjekt.AbwList[0]);
//      iID := aAbw.ID;
//      aAbw.Free;
//      aZellenObjekt.AbwList.Delete(0);
//      aZellenObjekt.AbwList.Pack;
//
//      ZeichneZelle( aZellenObjekt );
//
//      If iID > 0 then
//        Begin
//          ClientConn := TXDataWebClient.Create(nil);
//          ClientConn.Connection := frmMainForm.XDataConn;
//          Response := await( ClientConn.RawInvokeAsync('ISystemService.AbwDel', [ iID ] ));
//          ClientConn.Free;
//        End;
//
//    End;



end;

//================================================================================================//

procedure TfrmAbwVerwaltung.ZeichneZelle( aZellenObjekt : TZelle );
var el: TJSHTMLCanvasELement;
    ACanvas: TCanvas;
    bWE, bAbw, bZeichnen : Boolean;
    iCol, iRow, iWoTag : Integer;
//    aAbw : TAbw;
begin

//  //mLog.Lines.Add('ZeichneZelle '+DateTimeToStr( aZellenObjekt.Datum ));
//
//  iWoTag := DayOfTheWeek( aZellenObjekt.Datum );
//
//  If self.bFreiSam and (iWoTag=6) then bWE := True;
//  If self.bFreiSon and (iWoTag=7) then bWE := True;
//
//  bAbw := (aZellenObjekt.AbwList.Count>0);
//
//  iCol := aZellenObjekt.Col;
//  iRow := aZellenObjekt.Row;
//
//  bZeichnen := bWE or bAbw;
//
//  If not bZeichnen then // es gibt nix zu zeichnen
//    Begin
//      If grdDaten.HasCanvas(iCol,iRow) then grdDaten.RemoveCanvas(iCol,iRow);
//      Exit;
//    End;
//
//  // es muß was gezeichnet werden
//  If not grdDaten.HasCanvas(iCol,iRow) then grdDaten.AddCanvas(iCol,iRow);
//
//  // nur das Wochenende
//  If (not bAbw) and (bWE) then
//    Begin
//       el := grdDaten.GetCanvas( iCol, iRow );
//       ACanvas := TCanvas.Create(el);
//       ACanvas.Brush.Color := clSilver;
//       ACanvas.Pen.Color := clSilver;
//       ACanvas.FillRect( Rect(3,3,el.width-3,el.height-3) ) ;
//       ACanvas.Font.Color := GetSchriftFarbe(ACanvas.Brush.Color);
//       If (iWoTag=6) then ACanvas.TextOut(4,6,'Sa');
//       If (iWoTag=7) then ACanvas.TextOut(4,6,'So');
//       ACanvas.Free;
//    End;
//
//  // Abwesenheit zeichnen
//  If bAbw then
//    Begin
//      aAbw := TAbw(aZellenObjekt.AbwList[0]);
//      el := grdDaten.GetCanvas( iCol, iRow );
//      ACanvas := TCanvas.Create(el);
//      ACanvas.Brush.Color := StringToColor( aAbw.AbwFarbe );
//      ACanvas.Pen.Color   := ACanvas.Brush.Color;
//      ACanvas.FillRect( Rect(3,3,el.width-3,el.height-3) ) ;
//      ACanvas.Font.Color := GetSchriftFarbe(ACanvas.Brush.Color);
//      ACanvas.TextOut(4,6,aAbw.AbwKurz);
//      If aZellenObjekt.AbwList.Count > 1 then
//       ACanvas.TextOut(4,14, StringOfChar('+', aZellenObjekt.AbwList.Count) );
//      ACanvas.Free;
//    End;


end;

//================================================================================================//

procedure TfrmAbwVerwaltung.grdDatenMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
begin



  If ssShift in Shift then
    Begin
      Shift := [];
      Showmessage('Strg - Col: '+ IntToStr(grdDaten.Col));

    End;

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.grdDatenMouseMove(Sender: TObject; Shift: TShiftState; X, Y: Integer);
var lRow, lCol : Integer;
begin

  grdDaten.MouseToCell(x,y, lCol, lRow);

  If lCol <> -1 then self.iCol := lCol;
  If lRow <> -1 then self.iRow := lRow;

  lbPos.Caption := IntToStr(X)+'/'+IntToStr(Y)+'    '+IntToStr(iCol)+'/'+IntToStr(iRow) ;


end;

//================================================================================================//

procedure TfrmAbwVerwaltung.grdDatenMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
var lCol, lRow : Integer;
    sTmp : String;
    Coord: TGridCoord;
begin

  grdDaten.MouseToCell(x,y, lCol, lRow);

  sTmp := IntToStr(lCol)+'/'+inttostr(lRow) + '  >   ' +IntToStr(x)+'/'+inttostr(y);

  If Button = mbRight then
   If ssCtrl in Shift then
      Begin
        //Showmessage('Rechts und ssCtrl '+sTmp );
        mLog.Lines.Add('MouseUp Rechts: '+ IntToStr(grdDaten.Col));
        mLog.Lines.Add(sTmp);
      End;

  If Button = mbLeft then
   If ssCtrl in Shift then
      Begin
        //Showmessage('Links und ssCtrl'+sTmp);
        mLog.Lines.Add('MouseUp Links: '+ IntToStr(grdDaten.Col));
        mLog.Lines.Add(sTmp);
      End;

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.grdDatenSelectCell(Sender: TObject; ACol, ARow: LongInt;
  var CanSelect: Boolean);
begin

  self.iCol := aCol;
  self.iRow := aRow;

  mlog.Lines.Add('SelectCell: '+IntToStr(ACol)+'/'+IntToStr(ARow)  );

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.grdZeitBuchungDblClick(Sender: TObject);
var aMaNr : Integer;
begin

  aMaNr := tabMitarbeiterMA_NR.AsInteger;
  If aMaNr=0 then Exit;

  edtMitarbeiter.Tag := aMaNr;

  MaAbw_Laden( aMaNr );

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.MaAbw_Laden( aMaNr : Integer );
var Response: TXDataClientResponse;
    ClientConn: TXDataWebClient;
    Blob: JSValue;
    sDaten, sSQL, sTmp, sKopfZeile: String;
    aSL : TStringList;
    aStream : TStream;
    aTMP :  TJSObject;
    i, iJahr : Integer;
    aArray : TArray<string>;
    dtVon, dtBis : TDateTime;
begin

  mLog.Lines.Add('MaAbw_Laden - MaNr:'+inttostr(aMaNr));

  iJahr := StrToIntDef( edtJahr.Text, Trunc( Date )) ;

  mLog.Lines.Add('MaAbw_Laden - MaNr:'+inttostr(aMaNr) + ' Jahr: '+IntToStr(iJahr)  );

  dtVon := EncodeDate( iJahr, 1,  1 );
  dtBis := EncodeDate( iJahr,12, 31 );

  mLog.Lines.Add('MaAbw_Laden 2 ' +DateTimetoStr(dtVon) );

  edtMitarbeiter.Tag := aMaNr;
  edtMitarbeiter.Text := tabMitarbeiterMA_NAME.AsString+', '+tabMitarbeiterMA_VNAME.AsString;

  ClientConn := TXDataWebClient.Create(nil);
  ClientConn.Connection := frmMainForm.XDataConn;

  sSQL := ' SELECT R.KAL_DATE, R.MA_NR, R.ABW_DAUER, R.ANWABW_NR, R.ID_ABW_LANG, '
    + ' R.ABW_ERSTKZ, A.ANWABW_BEZ, ANWABW_ZEICHEN, ANWABW_FARBE, GANZER_TAG, INS_AM, '
    + ' R.KST_NR, R.INS_MANR, '
    + ' (SELECT KST_BEZ FROM KST_DAT K WHERE K.FIR_MAND=R.FIR_MAND AND K.KST_NR=R.KST_NR) AS KST, '
    + ' R.BEMERKUNG, R.ID_REL_ANWABW_MAKAL '
    + ' FROM REL_ANWABW_MAKAL R, ANWABW A'
    + ' WHERE R.FIR_MAND = 1  AND R.MA_NR = '+IntToStr(aMaNr)
    + ' AND R.KAL_DATE >= '+cH+DateTimeToStr(dtVon)+cH
    + ' AND R.KAL_DATE <= '+cH+DateTimeToStr(dtBis)+cH
    + ' AND R.ANWABW_NR NOT IN ( 103 )' // außer 103 Abwesend
    + ' AND A.FIR_MAND = R.FIR_MAND' + ' AND A.ANWABW_NR = R.ANWABW_NR'
    + ' ORDER BY KAL_DATE, ANWABW_NR';

  mLog.Lines.Add(sSQL);


  Response :=
   await( ClientConn.RawInvokeAsync('ISystemService.GetDaten', [ sSQL, 'CSV',0,0] ) );

  Blob := Response.Result;
  asm sDaten = await Blob.text(); end;

  ClientConn.Free;

  //------------------------ Daten jetzt noch entschlüsseln
  edtTemp.Text := sDaten;

  TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(edtTemp.Text),
    procedure(const AEncrypted: string)
      begin

        aSL := TStringList.Create;
        aSL.Text := AEncrypted; // mLog.Lines.Text;

        tabAbw.Close;
        tabAbw.Open;

        sKopfZeile := aSL[0];
        aArray := sKopfZeile.Split([';']);

        For i := 2 to aSL.Count -1 Do
            Begin
             sTmp := aSL[i];
              aArray := sTmp.Split([';']);
              tabAbw.Append;
              tabAbw.FieldByName('KAL_DATE').AsDateTime   := StrToDateTime( aArray[0] );
              tabAbw.FieldByName('MA_NR').AsInteger       := StrToIntDef(aArray[1],0);
              tabAbw.FieldByName('ABW_DAUER').AsInteger   := StrToIntDef(aArray[2],0);
              tabAbw.FieldByName('ANWABW_NR').AsInteger   := StrToIntDef(aArray[3],0);
              tabAbw.FieldByName('ID_ABW_LANG').AsInteger := StrToIntDef(aArray[4],0);
              tabAbw.FieldByName('ABW_ERSTKZ').AsInteger  := StrToIntDef(aArray[5],0);
              tabAbw.FieldByName('ANWABW_BEZ').AsString   := aArray[6];
              tabAbw.FieldByName('ANWABW_ZEICHEN').AsString := aArray[7];
              tabAbw.FieldByName('ANWABW_FARBE').AsString := aArray[8];
              tabAbw.FieldByName('GANZER_TAG').AsInteger  := StrToIntDef(aArray[9],0);
              tabAbw.FieldByName('INS_AM').AsString       := aArray[10];
              tabAbw.FieldByName('KST').AsString          := aArray[13];
              tabAbw.FieldByName('ID').AsInteger          := StrToIntDef(aArray[15],0);
              tabAbw.Post;
            End;

        aSL.Free;

        ZellenObjekte_Reset;
        WE_Nach_Zelle;
        MaAbw_Nach_Zelle;

      end,
    procedure(const AError: string)
     begin
      ShowMessage(AError);
     end);



end;

//================================================================================================//

procedure TfrmAbwVerwaltung.WE_Nach_Zelle;
var iCol, iRow, iWoTag : Integer;
    aZellenObjekt : TZelle;
    bZeichnen : Boolean;
    ACanvas: TCanvas;
    el: TJSHTMLCanvasELement;
begin

  mLog.Lines.Clear;

  For iCol := 1 to grdDaten.ColCount - 1 do
    For iRow := 1 to grdDaten.RowCount - 1 do
    Begin
      aZellenObjekt := TZelle( grdDaten.Objects[iCol, iRow] );

      If aZellenObjekt.Datum = 0 then Continue;

      iWoTag := DayOfTheWeek( aZellenObjekt.Datum );

      If iWoTag < 6 then Continue;

      bZeichnen := False;

      If self.bFreiSam and (iWoTag=6) then bZeichnen := True;
      If self.bFreiSon and (iWoTag=7) then bZeichnen := True;

      If bZeichnen then
        Begin

//          If not grdDaten.HasCanvas(iCol,iRow) then
//            Begin
//              grdDaten.AddCanvas(iCol,iRow);
//            End;

          ZeichneZelle( aZellenObjekt );
//           el := grdDaten.GetCanvas( iCol, iRow );
//           ACanvas := TCanvas.Create(el);
//           ACanvas.Brush.Color := clSilver;
//           ACanvas.Pen.Color := clSilver;
//           ACanvas.FillRect( Rect(3,3,el.width-3,el.height-3) ) ;
//           ACanvas.Font.Color := GetSchriftFarbe(ACanvas.Brush.Color);
//           If (iWoTag=6) then ACanvas.TextOut(4,6,'Sa');
//           If (iWoTag=7) then ACanvas.TextOut(4,6,'So');
//           ACanvas.Free;

         End;

    End;


end;

//================================================================================================//

procedure TfrmAbwVerwaltung.MaAbw_Nach_Zelle;
var dtTag : TDateTime;
    aZellenObjekt : TZelle;
//    aAbw : TAbw;
    ACanvas: TCanvas;
    el: TJSHTMLCanvasELement;
begin

//   tabAbw.First;
//   While not tabAbw.Eof Do
//     Begin
//
//       dtTag := tabAbw.FieldByName('KAL_DATE').AsDateTime;
//
//       aZellenObjekt := GetZelle_By_Tag( dtTag );
//       If aZellenObjekt = nil then
//         Begin
//           tabAbw.Next;
//           Continue;
//         End;
//
//
//       aAbw := TAbw.Create;
//       aAbw.ID       := tabAbw.FieldByName('ID').AsInteger;
//       aAbw.AbwNr    := tabAbw.FieldByName('ANWABW_NR').AsInteger;
//       aAbw.AbwLang  := tabAbw.FieldByName('ANWABW_BEZ').AsString;
//       aAbw.AbwKurz  := tabAbw.FieldByName('ANWABW_ZEICHEN').AsString;
//       aAbw.AbwFarbe := tabAbw.FieldByName('ANWABW_FARBE').AsString;
//       aZellenObjekt.AbwList.Add(aAbw);
//
//       ZeichneZelle( aZellenObjekt );
//
//       tabAbw.Next;
//     End;

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.Ma_Laden( aID : Integer );
var Response: TXDataClientResponse;
    ClientConn: TXDataWebClient;
    Blob: JSValue;
    sDaten, sSQL, sTmp, sKopfZeile: String;
    aSL : TStringList;
    aStream : TStream;
    aTMP :  TJSObject;
    i : Integer;
    aArray : TArray<string>;
begin

  frmMainForm.LogAction('Start: - TfrmAbwVerwaltung.Ma_Laden',false);


  btnNavInfo.Enabled := False;

  ClientConn := TXDataWebClient.Create(nil);
  ClientConn.Connection := frmMainForm.XDataConn;

  sSQL := 'SELECT  MA_NR, MA_PERSNR, MA_NAME, MA_VNAME '
         +'FROM MA_DAT WHERE (FIR_MAND=1 AND MA_AUSGESCHIE <> 1)  '
         +'AND (MA_EXTERN=0 or MA_EXTERN IS NULL) '
         +'ORDER BY MA_NAME, MA_VNAME ';


  Response :=
   await( ClientConn.RawInvokeAsync('ISystemService.GetDaten', [ sSQL, 'CSV',iRec_Von,iRec_Bis] ) );

  Blob := Response.Result;
  asm sDaten = await Blob.text(); end;

  ClientConn.Free;

  //------------------------ Daten jetzt noch entschlüsseln
  edtTemp.Text := sDaten;

  TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(edtTemp.Text),
    procedure(const AEncrypted: string)
      begin

        aSL := TStringList.Create;
        aSL.Text := AEncrypted; // mLog.Lines.Text;

        tabMitarbeiter.Close;
        tabMitarbeiter.Open;

        sKopfZeile := aSL[0];
        aArray := sKopfZeile.Split([';']);
        btnNavInfo.Text := aArray[3]+'-'+aArray[4]+' ['+aArray[2]+']';

        For i := 2 to aSL.Count -1 Do
            Begin
             sTmp := aSL[i];
              aArray := sTmp.Split([';']);
              tabMitarbeiter.Append;
              tabMitarbeiter.FieldByName('MA_NR').AsInteger     := StrToIntDef(aArray[0],0);
              tabMitarbeiter.FieldByName('MA_PERSNR').AsString  := aArray[1];
              tabMitarbeiter.FieldByName('MA_NAME').AsString    := aArray[2];
              tabMitarbeiter.FieldByName('MA_VNAME').AsString   := aArray[3];
              tabMitarbeiter.Post;
            End;

        aSL.Free;

        If aID = 0 then tabMitarbeiter.First;
        If aID > 0 then
          If not tabMitarbeiter.Locate('MA_NR',aID,[]) then tabMitarbeiter.First;

        btnNavNext.Enabled := (tabMitarbeiter.RecordCount=frmMainForm.g_RecAnzahl );
        btnNavInfo.Enabled := True;

        frmMainForm.LogAction('Ende: - TfrmAbwVerwaltung.Ma_Laden',False);

      end,
    procedure(const AError: string)
     begin
      ShowMessage(AError);
     end);

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.AnwAbw_Laden( aID : Integer );
var sSQL, sDaten, sTmp : String;
     i : Integer;
     Blob: JSValue;
     aSL : TStringList;
     aArray : TArray<string>;
     ClientConn: TXDataWebClient;
     Response: TXDataClientResponse;
begin

  frmMainForm.LogAction('Start - TfrmAbwVerwaltung.AnwAbw_Laden',False );

  ClientConn := TXDataWebClient.Create(nil);
  ClientConn.Connection := frmMainForm.XDataConn;

  sSQL := 'SELECT ANWABW_NR, ANWABW_BEZ, ANWABW_TYP, ANWABW_ZEICHEN, ANWABW_FARBE '
        +' FROM ANWABW ORDER BY ANWABW_BEZ ';

  Response := await( ClientConn.RawInvokeAsync('ISystemService.GetDaten', [ sSQL, 'CSV',0,0 ] ) );

  Blob := Response.Result;
  asm sDaten = await Blob.text(); end;

  ClientConn.Free;

  //------------------------ Daten jetzt noch entschlüsseln
  edtTemp.Text := sDaten;

  TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(edtTemp.Text),
     procedure(const AEncrypted: string)
      begin

        aSL := TStringList.Create;
        aSL.Text := AEncrypted; // mLog.Lines.Text;

        tabAnwAbw.Close;
        tabAnwAbw.Open;

        For i := 2 to aSL.Count -1 Do
            Begin
             sTmp := aSL[i];
              aArray := sTmp.Split([';']);
              tabAnwAbw.Append;
              tabAnwAbw.FieldByName('ANWABW_NR').AsInteger  := StrToIntDef(aArray[0],0);
              tabAnwAbw.FieldByName('ANWABW_BEZ').AsString  := aArray[1];
              tabAnwAbw.FieldByName('ANWABW_TYP').AsInteger := StrToIntDef(aArray[2],0);
              tabAnwAbw.FieldByName('ANWABW_KURZ').AsString  := aArray[3];
              tabAnwAbw.FieldByName('ANWABW_FARBE').AsString := aArray[4];
              tabAnwAbw.Post;
              //frmMainForm.LogAction(' > AnwAbw_Laden '+sTmp ,False) ;
            End;

        tabAnwAbw.First;
        aSL.Free;

        dmPzs.FillCBX_Mit_AnwAbw( cbxAbw, False) ;

        frmMainForm.LogAction('Ende  - TfrmAbwVerwaltung.AnwAbw_Laden',False);

      end,
    procedure(const AError: string)
     begin
      ShowMessage(AError);
     end );

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.ZellenObjekte_Erzeugen;
var aZellenObjekt: TZelle;
    iCol, iRow: Integer;
    dtDatum : TDateTime;
begin

  frmMainForm.LogAction('ZellenObjekte_Erzeugen',False);
  mLog.Lines.Add('ZellenObjekte_Erzeugen');

  For iCol := 1 to grdDaten.ColCount - 1 do
    For iRow := 1 to grdDaten.RowCount - 1 do
    Begin
      aZellenObjekt := TZelle.Create;
      aZellenObjekt.Col := iCol;
      aZellenObjekt.Row := iRow;
      aZellenObjekt.AbwList := TList.Create;
      grdDaten.Objects[iCol, iRow] := aZellenObjekt;
      //grdDaten.AddCanvas(iCol,iRow);
    End;

end;

//================================================================================================//

procedure TfrmAbwVerwaltung.ZellenObjekte_Freigeben;
var aZellenObjekt: TZelle;
//    aAbw : TAbw;
    i, iCol, iRow: Integer;
begin

//  frmMainForm.LogAction('ZellenObjekte_Freigeben',False);
//
//  For iCol := 1 to grdDaten.ColCount - 1 do
//    For iRow := 1 to grdDaten.RowCount - 1 do
//    Begin
//      aZellenObjekt := TZelle(grdDaten.Objects[iCol, iRow]);
//
//      For i := 0 to aZellenObjekt.AbwList.Count-1 Do
//        Begin
//          aAbw := TAbw(aZellenObjekt.AbwList[i]);
//          aAbw.Free;
//        End;
//      aZellenObjekt.AbwList.Clear;
//      aZellenObjekt.AbwList.Free;
//
//      TZelle(aZellenObjekt).Free;
//      TZelle(aZellenObjekt) := NIL;
//
//      grdDaten.RemoveCanvas(iCol, iRow);
//
//    End;

end;

//================================================================================================//

function TfrmAbwVerwaltung.GetSchriftFarbe( colHintergrund: TColor) : TColor;
var intensity : Integer;
begin


  colHintergrund := ColorToRGB(colHintergrund);

  intensity := GetBValue(colHintergrund) * 21 // Blue
    + GetGValue(colHintergrund) * 174 // Green
    + GetRValue(colHintergrund) * 61; // Red

   // intensity = 0 -> dark
   // intensity = 255*256 -> bright

  If intensity >= (128*256) then
       Result := clBlack
  Else Result := clWhite;

end;

//================================================================================================//



procedure TfrmAbwVerwaltung.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbPos := TLabel.Create(Self);
  pnlStatus := TPanel.Create(Self);
  lbZeitbuchungen := TLabel.Create(Self);
  grdZeitBuchung := TDBGrid.Create(Self);
  btnNavPrev := TTMSFNCToolBarButton.Create(Self);
  btnNavNext := TTMSFNCToolBarButton.Create(Self);
  btnNavInfo := TTMSFNCToolBarButton.Create(Self);
  edtTemp := TEdit.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  grdDaten := TStringGrid.Create(Self);
  WebButton1 := TButton.Create(Self);
  mlog := TMemo.Create(Self);
  edtJahr := TTMSFNCEdit.Create(Self);
  btnJahrNext := TTMSFNCToolBarButton.Create(Self);
  btnJahrPrev := TTMSFNCToolBarButton.Create(Self);
  edtMitarbeiter := TTMSFNCEdit.Create(Self);
  cbxAbw := TComboBox.Create(Self);
  tabMitarbeiter := TXDataWebDataSet.Create(Self);
  tabMitarbeiterMA_NR := TIntegerField.Create(Self);
  tabMitarbeiterMA_NAME := TStringField.Create(Self);
  tabMitarbeiterMA_VNAME := TStringField.Create(Self);
  tabMitarbeiterMA_PERSNR := TStringField.Create(Self);
  dsMitarbeiter := TDataSource.Create(Self);
  tabAbw := TXDataWebDataSet.Create(Self);
  tabAbwKAL_DATE := TDateTimeField.Create(Self);
  IntegerField1 := TIntegerField.Create(Self);
  tabAbwABW_DAUER := TIntegerField.Create(Self);
  tabAbwANWABW_NR := TIntegerField.Create(Self);
  tabAbwID_ABW_LANG := TIntegerField.Create(Self);
  tabAbwABW_ERSTKZ := TIntegerField.Create(Self);
  tabAbwANWABW_BEZ := TStringField.Create(Self);
  tabAbwANWABW_ZEICHEN := TStringField.Create(Self);
  tabAbwANWABW_FARBE := TStringField.Create(Self);
  tabAbwGANZER_TAG := TIntegerField.Create(Self);
  tabAbwINS_AM := TStringField.Create(Self);
  tabAbwKST := TStringField.Create(Self);
  tabAbwID := TIntegerField.Create(Self);
  dsAbw := TDataSource.Create(Self);
  WebPopupMenu1 := TPopupMenu.Create(Self);
  Eintragen1 := TMenuItem.Create(Self);
  Entfernen1 := TMenuItem.Create(Self);
  tabAnwAbw := TXDataWebDataSet.Create(Self);
  tabAnwAbwANWABW_NR := TIntegerField.Create(Self);
  tabAnwAbwANWABW_BEZ := TStringField.Create(Self);
  tabAnwAbwANWABW_TYP := TIntegerField.Create(Self);
  tabAnwAbwANWABW_KURZ := TStringField.Create(Self);
  tabAnwAbwANWABW_FARBE := TStringField.Create(Self);

  lbPos.BeforeLoadDFMValues;
  pnlStatus.BeforeLoadDFMValues;
  lbZeitbuchungen.BeforeLoadDFMValues;
  grdZeitBuchung.BeforeLoadDFMValues;
  btnNavPrev.BeforeLoadDFMValues;
  btnNavNext.BeforeLoadDFMValues;
  btnNavInfo.BeforeLoadDFMValues;
  edtTemp.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  grdDaten.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  mlog.BeforeLoadDFMValues;
  edtJahr.BeforeLoadDFMValues;
  btnJahrNext.BeforeLoadDFMValues;
  btnJahrPrev.BeforeLoadDFMValues;
  edtMitarbeiter.BeforeLoadDFMValues;
  cbxAbw.BeforeLoadDFMValues;
  tabMitarbeiter.BeforeLoadDFMValues;
  tabMitarbeiterMA_NR.BeforeLoadDFMValues;
  tabMitarbeiterMA_NAME.BeforeLoadDFMValues;
  tabMitarbeiterMA_VNAME.BeforeLoadDFMValues;
  tabMitarbeiterMA_PERSNR.BeforeLoadDFMValues;
  dsMitarbeiter.BeforeLoadDFMValues;
  tabAbw.BeforeLoadDFMValues;
  tabAbwKAL_DATE.BeforeLoadDFMValues;
  IntegerField1.BeforeLoadDFMValues;
  tabAbwABW_DAUER.BeforeLoadDFMValues;
  tabAbwANWABW_NR.BeforeLoadDFMValues;
  tabAbwID_ABW_LANG.BeforeLoadDFMValues;
  tabAbwABW_ERSTKZ.BeforeLoadDFMValues;
  tabAbwANWABW_BEZ.BeforeLoadDFMValues;
  tabAbwANWABW_ZEICHEN.BeforeLoadDFMValues;
  tabAbwANWABW_FARBE.BeforeLoadDFMValues;
  tabAbwGANZER_TAG.BeforeLoadDFMValues;
  tabAbwINS_AM.BeforeLoadDFMValues;
  tabAbwKST.BeforeLoadDFMValues;
  tabAbwID.BeforeLoadDFMValues;
  dsAbw.BeforeLoadDFMValues;
  WebPopupMenu1.BeforeLoadDFMValues;
  Eintragen1.BeforeLoadDFMValues;
  Entfernen1.BeforeLoadDFMValues;
  tabAnwAbw.BeforeLoadDFMValues;
  tabAnwAbwANWABW_NR.BeforeLoadDFMValues;
  tabAnwAbwANWABW_BEZ.BeforeLoadDFMValues;
  tabAnwAbwANWABW_TYP.BeforeLoadDFMValues;
  tabAnwAbwANWABW_KURZ.BeforeLoadDFMValues;
  tabAnwAbwANWABW_FARBE.BeforeLoadDFMValues;
  try
    Name := 'frmAbwVerwaltung';
    Width := 921;
    Height := 586;
    Color := 16378331;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnMouseDown', 'WebFormMouseDown');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    lbPos.SetParentComponent(Self);
    lbPos.Name := 'lbPos';
    lbPos.Left := 544;
    lbPos.Top := 84;
    lbPos.Width := 29;
    lbPos.Height := 15;
    lbPos.Caption := 'lbPos';
    lbPos.Color := 16378331;
    lbPos.HeightPercent := 100.000000000000000000;
    lbPos.Visible := False;
    lbPos.WidthPercent := 100.000000000000000000;
    pnlStatus.SetParentComponent(Self);
    pnlStatus.Name := 'pnlStatus';
    pnlStatus.Left := 0;
    pnlStatus.Top := 0;
    pnlStatus.Width := 921;
    pnlStatus.Height := 35;
    pnlStatus.Align := alTop;
    pnlStatus.ChildOrder := 11;
    pnlStatus.Color := 16370864;
    pnlStatus.TabOrder := 0;
    lbZeitbuchungen.SetParentComponent(pnlStatus);
    lbZeitbuchungen.Name := 'lbZeitbuchungen';
    lbZeitbuchungen.Left := 16;
    lbZeitbuchungen.Top := 6;
    lbZeitbuchungen.Width := 241;
    lbZeitbuchungen.Height := 23;
    lbZeitbuchungen.Caption := 'Abwesenheitsverwaltung';
    lbZeitbuchungen.Color := 16378331;
    lbZeitbuchungen.Font.Charset := DEFAULT_CHARSET;
    lbZeitbuchungen.Font.Color := clBlack;
    lbZeitbuchungen.Font.Height := -19;
    lbZeitbuchungen.Font.Name := 'Tahoma';
    lbZeitbuchungen.Font.Style := [fsBold];
    lbZeitbuchungen.HeightPercent := 100.000000000000000000;
    lbZeitbuchungen.ParentFont := False;
    lbZeitbuchungen.WidthPercent := 100.000000000000000000;
    grdZeitBuchung.SetParentComponent(Self);
    grdZeitBuchung.Name := 'grdZeitBuchung';
    grdZeitBuchung.Left := 8;
    grdZeitBuchung.Top := 105;
    grdZeitBuchung.Width := 321;
    grdZeitBuchung.Height := 442;
    grdZeitBuchung.Cursor := crHandPoint;
    grdZeitBuchung.Anchors := [akLeft,akTop,akBottom];
    grdZeitBuchung.Columns.Clear;
    with grdZeitBuchung.Columns.Add do
    begin
      DataField := 'MA_PERSNR';
      Title := 'Pers.-Nr';
      Width := 71;
    end;
    with grdZeitBuchung.Columns.Add do
    begin
      DataField := 'MA_NAME';
      Title := 'Zuname';
      Width := 139;
    end;
    with grdZeitBuchung.Columns.Add do
    begin
      DataField := 'MA_VNAME';
      Title := 'Vorname';
      Width := 94;
    end;
    grdZeitBuchung.DataSource := dsMitarbeiter;
    grdZeitBuchung.ElementFont := efCSS;
    grdZeitBuchung.FixedFont.Charset := DEFAULT_CHARSET;
    grdZeitBuchung.FixedFont.Color := clWindowText;
    grdZeitBuchung.FixedFont.Height := -12;
    grdZeitBuchung.FixedFont.Name := 'Segoe UI';
    grdZeitBuchung.FixedFont.Style := [];
    grdZeitBuchung.FixedCols := 0;
    grdZeitBuchung.Font.Charset := DEFAULT_CHARSET;
    grdZeitBuchung.Font.Color := clWindowText;
    grdZeitBuchung.Font.Height := -13;
    grdZeitBuchung.Font.Name := 'Segoe UI';
    grdZeitBuchung.Font.Style := [];
    grdZeitBuchung.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goColSizing,goColMoving,goRowSelect,goFixedRowDefAlign];
    grdZeitBuchung.ParentFont := False;
    grdZeitBuchung.TabOrder := 1;
    grdZeitBuchung.HeightPercent := 100.000000000000000000;
    grdZeitBuchung.WidthPercent := 100.000000000000000000;
    SetEvent(grdZeitBuchung, Self, 'OnDblClick', 'grdZeitBuchungDblClick');
    grdZeitBuchung.ColWidths[0] := 71;
    grdZeitBuchung.ColWidths[1] := 139;
    grdZeitBuchung.ColWidths[2] := 94;
    btnNavPrev.SetParentComponent(Self);
    btnNavPrev.Name := 'btnNavPrev';
    btnNavPrev.Left := 8;
    btnNavPrev.Top := 40;
    btnNavPrev.Width := 33;
    btnNavPrev.Height := 30;
    btnNavPrev.Cursor := crHandPoint;
    btnNavPrev.ParentDoubleBuffered := False;
    btnNavPrev.DoubleBuffered := True;
    btnNavPrev.Font.Charset := DEFAULT_CHARSET;
    btnNavPrev.Font.Color := clWindowText;
    btnNavPrev.Font.Height := -11;
    btnNavPrev.Font.Name := 'Segoe UI';
    btnNavPrev.Font.Style := [];
    btnNavPrev.ParentColor := True;
    btnNavPrev.TabOrder := 2;
    SetEvent(btnNavPrev, Self, 'OnClick', 'Do_Laden');
    btnNavPrev.Text := '';
    btnNavPrev.Bitmaps.Clear;
    with btnNavPrev.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('AbwVerwaltung.btnNavPrev.Bitmaps.Bitmap.png');
    end;
    btnNavPrev.BitmapVisible := True;
    btnNavPrev.ControlIndex := 0;
    btnNavNext.SetParentComponent(Self);
    btnNavNext.Name := 'btnNavNext';
    btnNavNext.Left := 43;
    btnNavNext.Top := 40;
    btnNavNext.Width := 33;
    btnNavNext.Height := 30;
    btnNavNext.Cursor := crHandPoint;
    btnNavNext.ParentDoubleBuffered := False;
    btnNavNext.DoubleBuffered := True;
    btnNavNext.Font.Charset := DEFAULT_CHARSET;
    btnNavNext.Font.Color := clWindowText;
    btnNavNext.Font.Height := -11;
    btnNavNext.Font.Name := 'Segoe UI';
    btnNavNext.Font.Style := [];
    btnNavNext.ParentColor := True;
    btnNavNext.TabOrder := 3;
    SetEvent(btnNavNext, Self, 'OnClick', 'Do_Laden');
    btnNavNext.Text := '';
    btnNavNext.Bitmaps.Clear;
    with btnNavNext.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('AbwVerwaltung.btnNavNext.Bitmaps.Bitmap.png');
    end;
    btnNavNext.BitmapVisible := True;
    btnNavNext.ControlIndex := 0;
    btnNavInfo.SetParentComponent(Self);
    btnNavInfo.Name := 'btnNavInfo';
    btnNavInfo.Left := 79;
    btnNavInfo.Top := 40;
    btnNavInfo.Width := 94;
    btnNavInfo.Height := 30;
    btnNavInfo.Hint := 'Daten laden';
    btnNavInfo.ParentDoubleBuffered := False;
    btnNavInfo.DoubleBuffered := True;
    btnNavInfo.Font.Charset := DEFAULT_CHARSET;
    btnNavInfo.Font.Color := clWindowText;
    btnNavInfo.Font.Height := -11;
    btnNavInfo.Font.Name := 'Segoe UI';
    btnNavInfo.Font.Style := [];
    btnNavInfo.ParentColor := True;
    btnNavInfo.TabOrder := 4;
    SetEvent(btnNavInfo, Self, 'OnClick', 'btnNavInfoClick');
    btnNavInfo.Text := '1 - 50 [254]';
    btnNavInfo.ControlIndex := 0;
    edtTemp.SetParentComponent(Self);
    edtTemp.Name := 'edtTemp';
    edtTemp.Left := 202;
    edtTemp.Top := 40;
    edtTemp.Width := 55;
    edtTemp.Height := 22;
    edtTemp.ChildOrder := 23;
    edtTemp.HeightPercent := 100.000000000000000000;
    edtTemp.Text := 'edtTemp';
    edtTemp.Visible := False;
    edtTemp.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 563;
    WebPanel1.Width := 921;
    WebPanel1.Height := 23;
    WebPanel1.Align := alBottom;
    WebPanel1.ChildOrder := 11;
    WebPanel1.Color := 16370864;
    WebPanel1.TabOrder := 6;
    grdDaten.SetParentComponent(Self);
    grdDaten.Name := 'grdDaten';
    grdDaten.Left := 338;
    grdDaten.Top := 105;
    grdDaten.Width := 555;
    grdDaten.Height := 442;
    grdDaten.Anchors := [akLeft,akTop,akRight,akBottom];
    grdDaten.ColCount := 33;
    grdDaten.DefaultRowHeight := 40;
    grdDaten.FixedColor := 16370864;
    grdDaten.RowCount := 13;
    grdDaten.Font.Charset := DEFAULT_CHARSET;
    grdDaten.Font.Color := clWindowText;
    grdDaten.Font.Height := -13;
    grdDaten.Font.Name := 'Segoe UI';
    grdDaten.Font.Style := [];
    grdDaten.ParentFont := False;
    grdDaten.PopupMenu := WebPopupMenu1;
    grdDaten.TabOrder := 7;
    SetEvent(grdDaten, Self, 'OnKeyDown', 'grdDatenKeyDown');
    SetEvent(grdDaten, Self, 'OnMouseMove', 'grdDatenMouseMove');
    grdDaten.FixedFont.Charset := DEFAULT_CHARSET;
    grdDaten.FixedFont.Color := clWindowText;
    grdDaten.FixedFont.Height := -12;
    grdDaten.FixedFont.Name := 'Segoe UI';
    grdDaten.FixedFont.Style := [];
    grdDaten.RangeEdit.Max := 100.000000000000000000;
    grdDaten.RangeEdit.Step := 1.000000000000000000;
    grdDaten.SelectionColor := clYellow;
    grdDaten.HeightPercent := 100.000000000000000000;
    grdDaten.WidthPercent := 100.000000000000000000;
    SetEvent(grdDaten, Self, 'OnClickCell', 'grdDatenClickCell');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 769;
    WebButton1.Top := 41;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Test';
    WebButton1.ChildOrder := 8;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    mlog.SetParentComponent(Self);
    mlog.Name := 'mlog';
    mlog.Left := 15;
    mlog.Top := 392;
    mlog.Width := 305;
    mlog.Height := 140;
    mlog.Anchors := [akLeft,akBottom];
    mlog.HeightPercent := 100.000000000000000000;
    mlog.Lines.BeginUpdate;
    try
      mlog.Lines.Clear;
      mlog.Lines.Add('mlog');
    finally
      mlog.Lines.EndUpdate;
    end;
    mlog.SelLength := 0;
    mlog.SelStart := 0;
    mlog.Visible := False;
    mlog.WidthPercent := 100.000000000000000000;
    edtJahr.SetParentComponent(Self);
    edtJahr.Name := 'edtJahr';
    edtJahr.Left := 373;
    edtJahr.Top := 40;
    edtJahr.Width := 73;
    edtJahr.Height := 29;
    edtJahr.Alignment := taCenter;
    edtJahr.AutoSize := False;
    edtJahr.Font.Charset := DEFAULT_CHARSET;
    edtJahr.Font.Color := clWindowText;
    edtJahr.Font.Height := -16;
    edtJahr.Font.Name := 'Segoe UI';
    edtJahr.Font.Style := [fsBold];
    edtJahr.ParentColor := True;
    edtJahr.ParentFont := False;
    edtJahr.ReadOnly := True;
    edtJahr.TabOrder := 10;
    edtJahr.Text := '2024';
    edtJahr.Lookup.Separator := ';';
    edtJahr.ValidChars := '1234567890';
    btnJahrNext.SetParentComponent(Self);
    btnJahrNext.Name := 'btnJahrNext';
    btnJahrNext.Left := 449;
    btnJahrNext.Top := 40;
    btnJahrNext.Width := 33;
    btnJahrNext.Height := 30;
    btnJahrNext.Cursor := crHandPoint;
    btnJahrNext.ParentDoubleBuffered := False;
    btnJahrNext.DoubleBuffered := True;
    btnJahrNext.Font.Charset := DEFAULT_CHARSET;
    btnJahrNext.Font.Color := clWindowText;
    btnJahrNext.Font.Height := -11;
    btnJahrNext.Font.Name := 'Segoe UI';
    btnJahrNext.Font.Style := [];
    btnJahrNext.ParentColor := True;
    btnJahrNext.TabOrder := 11;
    SetEvent(btnJahrNext, Self, 'OnClick', 'DoJahrNavigieren');
    btnJahrNext.Text := '';
    btnJahrNext.Bitmaps.Clear;
    with btnJahrNext.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('AbwVerwaltung.btnJahrNext.Bitmaps.Bitmap.png');
    end;
    btnJahrNext.BitmapVisible := True;
    btnJahrNext.ControlIndex := 0;
    btnJahrPrev.SetParentComponent(Self);
    btnJahrPrev.Name := 'btnJahrPrev';
    btnJahrPrev.Left := 338;
    btnJahrPrev.Top := 40;
    btnJahrPrev.Width := 33;
    btnJahrPrev.Height := 30;
    btnJahrPrev.Cursor := crHandPoint;
    btnJahrPrev.ParentDoubleBuffered := False;
    btnJahrPrev.DoubleBuffered := True;
    btnJahrPrev.Font.Charset := DEFAULT_CHARSET;
    btnJahrPrev.Font.Color := clWindowText;
    btnJahrPrev.Font.Height := -11;
    btnJahrPrev.Font.Name := 'Segoe UI';
    btnJahrPrev.Font.Style := [];
    btnJahrPrev.ParentColor := True;
    btnJahrPrev.TabOrder := 12;
    SetEvent(btnJahrPrev, Self, 'OnClick', 'DoJahrNavigieren');
    btnJahrPrev.Text := '';
    btnJahrPrev.Bitmaps.Clear;
    with btnJahrPrev.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('AbwVerwaltung.btnJahrPrev.Bitmaps.Bitmap.png');
    end;
    btnJahrPrev.BitmapVisible := True;
    btnJahrPrev.ControlIndex := 0;
    edtMitarbeiter.SetParentComponent(Self);
    edtMitarbeiter.Name := 'edtMitarbeiter';
    edtMitarbeiter.Left := 501;
    edtMitarbeiter.Top := 40;
    edtMitarbeiter.Width := 262;
    edtMitarbeiter.Height := 29;
    edtMitarbeiter.Alignment := taCenter;
    edtMitarbeiter.AutoSize := False;
    edtMitarbeiter.Font.Charset := DEFAULT_CHARSET;
    edtMitarbeiter.Font.Color := clWindowText;
    edtMitarbeiter.Font.Height := -16;
    edtMitarbeiter.Font.Name := 'Segoe UI';
    edtMitarbeiter.Font.Style := [fsBold];
    edtMitarbeiter.ParentColor := True;
    edtMitarbeiter.ParentFont := False;
    edtMitarbeiter.ReadOnly := True;
    edtMitarbeiter.TabOrder := 13;
    edtMitarbeiter.Text := '---';
    edtMitarbeiter.Lookup.Separator := ';';
    edtMitarbeiter.ValidChars := '1234567890';
    cbxAbw.SetParentComponent(Self);
    cbxAbw.Name := 'cbxAbw';
    cbxAbw.Left := 338;
    cbxAbw.Top := 79;
    cbxAbw.Width := 168;
    cbxAbw.Height := 23;
    cbxAbw.Cursor := crHandPoint;
    cbxAbw.ElementClassName := 'cbxAbw';
    cbxAbw.HeightPercent := 100.000000000000000000;
    cbxAbw.Text := 'cbxAbw';
    cbxAbw.WidthPercent := 100.000000000000000000;
    cbxAbw.ItemIndex := -1;
    tabMitarbeiter.SetParentComponent(Self);
    tabMitarbeiter.Name := 'tabMitarbeiter';
    tabMitarbeiter.Left := 96;
    tabMitarbeiter.Top := 176;
    tabMitarbeiterMA_NR.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_NR.Name := 'tabMitarbeiterMA_NR';
    tabMitarbeiterMA_NR.Alignment := taCenter;
    tabMitarbeiterMA_NR.DisplayLabel := 'MaNr';
    tabMitarbeiterMA_NR.DisplayWidth := 6;
    tabMitarbeiterMA_NR.FieldName := 'MA_NR';
    tabMitarbeiterMA_NR.Required := True;
    tabMitarbeiterMA_NAME.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_NAME.Name := 'tabMitarbeiterMA_NAME';
    tabMitarbeiterMA_NAME.DisplayLabel := 'Zuname';
    tabMitarbeiterMA_NAME.DisplayWidth := 25;
    tabMitarbeiterMA_NAME.FieldName := 'MA_NAME';
    tabMitarbeiterMA_NAME.Required := True;
    tabMitarbeiterMA_NAME.Size := 30;
    tabMitarbeiterMA_VNAME.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_VNAME.Name := 'tabMitarbeiterMA_VNAME';
    tabMitarbeiterMA_VNAME.DisplayLabel := 'Vorname';
    tabMitarbeiterMA_VNAME.DisplayWidth := 20;
    tabMitarbeiterMA_VNAME.FieldName := 'MA_VNAME';
    tabMitarbeiterMA_VNAME.Size := 30;
    tabMitarbeiterMA_PERSNR.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_PERSNR.Name := 'tabMitarbeiterMA_PERSNR';
    tabMitarbeiterMA_PERSNR.FieldName := 'MA_PERSNR';
    dsMitarbeiter.SetParentComponent(Self);
    dsMitarbeiter.Name := 'dsMitarbeiter';
    dsMitarbeiter.DataSet := tabMitarbeiter;
    dsMitarbeiter.Left := 176;
    dsMitarbeiter.Top := 208;
    tabAbw.SetParentComponent(Self);
    tabAbw.Name := 'tabAbw';
    tabAbw.Left := 480;
    tabAbw.Top := 296;
    tabAbwKAL_DATE.SetParentComponent(tabAbw);
    tabAbwKAL_DATE.Name := 'tabAbwKAL_DATE';
    tabAbwKAL_DATE.FieldName := 'KAL_DATE';
    IntegerField1.SetParentComponent(tabAbw);
    IntegerField1.Name := 'IntegerField1';
    IntegerField1.Alignment := taCenter;
    IntegerField1.DisplayLabel := 'MaNr';
    IntegerField1.DisplayWidth := 6;
    IntegerField1.FieldName := 'MA_NR';
    IntegerField1.Required := True;
    tabAbwABW_DAUER.SetParentComponent(tabAbw);
    tabAbwABW_DAUER.Name := 'tabAbwABW_DAUER';
    tabAbwABW_DAUER.FieldName := 'ABW_DAUER';
    tabAbwANWABW_NR.SetParentComponent(tabAbw);
    tabAbwANWABW_NR.Name := 'tabAbwANWABW_NR';
    tabAbwANWABW_NR.FieldName := 'ANWABW_NR';
    tabAbwID_ABW_LANG.SetParentComponent(tabAbw);
    tabAbwID_ABW_LANG.Name := 'tabAbwID_ABW_LANG';
    tabAbwID_ABW_LANG.FieldName := 'ID_ABW_LANG';
    tabAbwABW_ERSTKZ.SetParentComponent(tabAbw);
    tabAbwABW_ERSTKZ.Name := 'tabAbwABW_ERSTKZ';
    tabAbwABW_ERSTKZ.FieldName := 'ABW_ERSTKZ';
    tabAbwANWABW_BEZ.SetParentComponent(tabAbw);
    tabAbwANWABW_BEZ.Name := 'tabAbwANWABW_BEZ';
    tabAbwANWABW_BEZ.FieldName := 'ANWABW_BEZ';
    tabAbwANWABW_ZEICHEN.SetParentComponent(tabAbw);
    tabAbwANWABW_ZEICHEN.Name := 'tabAbwANWABW_ZEICHEN';
    tabAbwANWABW_ZEICHEN.FieldName := 'ANWABW_ZEICHEN';
    tabAbwANWABW_ZEICHEN.Size := 3;
    tabAbwANWABW_FARBE.SetParentComponent(tabAbw);
    tabAbwANWABW_FARBE.Name := 'tabAbwANWABW_FARBE';
    tabAbwANWABW_FARBE.FieldName := 'ANWABW_FARBE';
    tabAbwANWABW_FARBE.Size := 30;
    tabAbwGANZER_TAG.SetParentComponent(tabAbw);
    tabAbwGANZER_TAG.Name := 'tabAbwGANZER_TAG';
    tabAbwGANZER_TAG.FieldName := 'GANZER_TAG';
    tabAbwINS_AM.SetParentComponent(tabAbw);
    tabAbwINS_AM.Name := 'tabAbwINS_AM';
    tabAbwINS_AM.DisplayWidth := 12;
    tabAbwINS_AM.FieldName := 'INS_AM';
    tabAbwINS_AM.Size := 12;
    tabAbwKST.SetParentComponent(tabAbw);
    tabAbwKST.Name := 'tabAbwKST';
    tabAbwKST.FieldName := 'KST';
    tabAbwKST.Size := 8;
    tabAbwID.SetParentComponent(tabAbw);
    tabAbwID.Name := 'tabAbwID';
    tabAbwID.FieldName := 'ID';
    dsAbw.SetParentComponent(Self);
    dsAbw.Name := 'dsAbw';
    dsAbw.DataSet := tabAbw;
    dsAbw.Left := 536;
    dsAbw.Top := 328;
    WebPopupMenu1.SetParentComponent(Self);
    WebPopupMenu1.Name := 'WebPopupMenu1';
    WebPopupMenu1.Appearance.HamburgerMenu.Caption := 'Menu';
    WebPopupMenu1.Appearance.SubmenuIndicator := '&#9658;';
    WebPopupMenu1.Font.Charset := DEFAULT_CHARSET;
    WebPopupMenu1.Font.Color := clWindowText;
    WebPopupMenu1.Font.Height := -12;
    WebPopupMenu1.Font.Name := 'Segoe UI';
    WebPopupMenu1.Font.Style := [];
    WebPopupMenu1.Left := 536;
    WebPopupMenu1.Top := 152;
    Eintragen1.SetParentComponent(WebPopupMenu1);
    Eintragen1.Name := 'Eintragen1';
    Eintragen1.Caption := 'Eintragen';
    SetEvent(Eintragen1, Self, 'OnClick', 'Eintragen1Click');
    Entfernen1.SetParentComponent(WebPopupMenu1);
    Entfernen1.Name := 'Entfernen1';
    Entfernen1.Caption := 'Entfernen';
    tabAnwAbw.SetParentComponent(Self);
    tabAnwAbw.Name := 'tabAnwAbw';
    tabAnwAbw.EntitySetName := 'ANWABW';
    tabAnwAbw.Left := 376;
    tabAnwAbw.Top := 312;
    tabAnwAbwANWABW_NR.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_NR.Name := 'tabAnwAbwANWABW_NR';
    tabAnwAbwANWABW_NR.FieldName := 'ANWABW_NR';
    tabAnwAbwANWABW_BEZ.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_BEZ.Name := 'tabAnwAbwANWABW_BEZ';
    tabAnwAbwANWABW_BEZ.FieldName := 'ANWABW_BEZ';
    tabAnwAbwANWABW_TYP.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_TYP.Name := 'tabAnwAbwANWABW_TYP';
    tabAnwAbwANWABW_TYP.FieldName := 'ANWABW_TYP';
    tabAnwAbwANWABW_KURZ.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_KURZ.Name := 'tabAnwAbwANWABW_KURZ';
    tabAnwAbwANWABW_KURZ.FieldName := 'ANWABW_KURZ';
    tabAnwAbwANWABW_KURZ.Size := 5;
    tabAnwAbwANWABW_FARBE.SetParentComponent(tabAnwAbw);
    tabAnwAbwANWABW_FARBE.Name := 'tabAnwAbwANWABW_FARBE';
    tabAnwAbwANWABW_FARBE.FieldName := 'ANWABW_FARBE';
    tabAnwAbwANWABW_FARBE.Size := 30;
  finally
    lbPos.AfterLoadDFMValues;
    pnlStatus.AfterLoadDFMValues;
    lbZeitbuchungen.AfterLoadDFMValues;
    grdZeitBuchung.AfterLoadDFMValues;
    btnNavPrev.AfterLoadDFMValues;
    btnNavNext.AfterLoadDFMValues;
    btnNavInfo.AfterLoadDFMValues;
    edtTemp.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    grdDaten.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    mlog.AfterLoadDFMValues;
    edtJahr.AfterLoadDFMValues;
    btnJahrNext.AfterLoadDFMValues;
    btnJahrPrev.AfterLoadDFMValues;
    edtMitarbeiter.AfterLoadDFMValues;
    cbxAbw.AfterLoadDFMValues;
    tabMitarbeiter.AfterLoadDFMValues;
    tabMitarbeiterMA_NR.AfterLoadDFMValues;
    tabMitarbeiterMA_NAME.AfterLoadDFMValues;
    tabMitarbeiterMA_VNAME.AfterLoadDFMValues;
    tabMitarbeiterMA_PERSNR.AfterLoadDFMValues;
    dsMitarbeiter.AfterLoadDFMValues;
    tabAbw.AfterLoadDFMValues;
    tabAbwKAL_DATE.AfterLoadDFMValues;
    IntegerField1.AfterLoadDFMValues;
    tabAbwABW_DAUER.AfterLoadDFMValues;
    tabAbwANWABW_NR.AfterLoadDFMValues;
    tabAbwID_ABW_LANG.AfterLoadDFMValues;
    tabAbwABW_ERSTKZ.AfterLoadDFMValues;
    tabAbwANWABW_BEZ.AfterLoadDFMValues;
    tabAbwANWABW_ZEICHEN.AfterLoadDFMValues;
    tabAbwANWABW_FARBE.AfterLoadDFMValues;
    tabAbwGANZER_TAG.AfterLoadDFMValues;
    tabAbwINS_AM.AfterLoadDFMValues;
    tabAbwKST.AfterLoadDFMValues;
    tabAbwID.AfterLoadDFMValues;
    dsAbw.AfterLoadDFMValues;
    WebPopupMenu1.AfterLoadDFMValues;
    Eintragen1.AfterLoadDFMValues;
    Entfernen1.AfterLoadDFMValues;
    tabAnwAbw.AfterLoadDFMValues;
    tabAnwAbwANWABW_NR.AfterLoadDFMValues;
    tabAnwAbwANWABW_BEZ.AfterLoadDFMValues;
    tabAnwAbwANWABW_TYP.AfterLoadDFMValues;
    tabAnwAbwANWABW_KURZ.AfterLoadDFMValues;
    tabAnwAbwANWABW_FARBE.AfterLoadDFMValues;
  end;
end;

end.
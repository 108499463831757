unit UnitLogin;

interface

uses System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls, WEBLib.Forms,
     WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls, WEBLib.ExtCtrls, WEBLib.Storage,
     System.DateUtils, WEBLib.WebCtrls;

type
  TLoginForm = class(TForm)
    tmrLoginStart: TTimer;
    pnlLogin: TPanel;
    editUsername: TEdit;
    editPassword: TEdit;
    cbxUserMerken: TCheckBox;
    btnLogin: TButton;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure editUsernameChange(Sender: TObject);
    [async] procedure tmrLoginStartTimer(Sender: TObject); async;
    [async] procedure btnLoginClick(Sender: TObject); async;
    procedure WebFormResize(Sender: TObject);

  private

  public

  protected procedure LoadDFMValues; override; end;


var LoginForm: TLoginForm;

implementation

uses UnitMain;

{$R *.dfm}

//================================================================================================//

procedure TLoginForm.btnLoginClick(Sender: TObject);
var LoginCheck: String;
begin

  btnLogin.Caption := 'Authorizing..';


  LoginCheck := await( frmMainForm.XDataLogin(editUSername.Text, editPassword.Text));

//  Showmessage(LoginCheck);

  If LoginCheck = 'Success' then
    Begin

      // Briefly show successful login message
      btnLogin.Caption := 'Anmeld.OK';
//      asm await sleep(500); end;

      // Process Remembering
      if cbxUserMerken.Checked then
      begin
        frmMainForm.Remember := True;
        TLocalStorage.SetValue('Login.Username', editUsername.Text);
        TLocalStorage.SetValue('Login.JWT', frmMainForm.JWT);
        TLocalStorage.SetValue('Login.Expiry', FloatToStr(frmMainForm.JWT_Expiry));
      end
      else
      begin
        frmMainForm.Remember := False;
        TLocalStorage.RemoveKey('Login.Username');
        TLocalStorage.RemoveKey('Login.JWT');
        TLocalStorage.RemoveKey('Login.Expiry');
      end;

      frmMainForm.Set_BenutzerAktiv( True );
      frmMainForm.LoadForm('frmMainForm');

      window.history.pushState(frmMainForm.CaptureState, '', frmMainForm.URL);
      frmMainForm.Position      := window.history.length;
      frmMainForm.StartPosition := frmMainForm.Position - 1;


    end  //If LoginCheck = 'Success' then

  Else

    Begin
      frmMainForm.Set_BenutzerAktiv( False );
      btnLogin.Caption := '<span class="m-2">Login</span>';
      LoginCheck := StringReplace(LoginCheck,': ',':<br />',[]);
      LoginCheck := StringReplace(LoginCheck,'. ','.<br />',[]);
      if Trim(LoginCheck) = '/'
        then LoginCheck := 'System Error / Server connection could not be established.';
      Showmessage('Anmeldung ist nicht möglich');
      //frmMainForm.Toast(Copy(LoginCheck,1,Pos('/',LoginCheck) -2),Copy(LoginCheck, Pos('/',LoginCheck)+2,Length(LoginCheck)),15000);

    End;


end;

//================================================================================================//

procedure TLoginForm.WebFormKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if (Key = VK_RETURN)
     and (trim(editUsername.Text) <> '')
     and (Trim(editPassword.Text) <> '')
  then btnLoginClick(Sender)
  else editUsernameChange(Sender);
end;

//================================================================================================//

procedure TLoginForm.WebFormResize(Sender: TObject);
begin

  pnlLogin.Left := Trunc( (self.Width/2)  - (pnlLogin.Width/2) );
  pnlLogin.Top  := Trunc( (self.Height/2) - (pnlLogin.Height/2) );

end;

//================================================================================================//

procedure TLoginForm.WebFormCreate(Sender: TObject);
begin
  // Update Title
  //labelLoginTitle.Caption := 'Login-Daten merken';

  // Hide the main login form
//  divLoginBox.ElementHandle.style.setProperty('opacity','0');

  // Check for autologin
  tmrLoginStart.Enabled := True;

end;

//================================================================================================//

procedure TLoginForm.editUsernameChange(Sender: TObject);
begin

  if (Trim(editUsername.Text) = '') and (Trim(editPassword.Text) = '') then
  begin
    btnLogin.Enabled := False;

  end
  else if (Trim(editUsername.Text) = '') then
  begin
    btnLogin.Enabled := False;

  end
  else if (Trim(editPassword.Text) = '') then
  begin
    btnLogin.Enabled := False;

  end
  else
  begin
    btnLogin.Enabled := True;

  end;
end;

//================================================================================================//

procedure TLoginForm.tmrLoginStartTimer(Sender: TObject);
var
  Remembered: String;
  LoggedIn: Boolean;
  CheckForm: String;
  CheckSubForm: String;
  CheckForms: Boolean;
  UserPass: Boolean;
begin

  tmrLoginStart.Enabled := False;
  LoggedIn   := False;
  CheckForms := False;
  UserPass   := False;

  editUsername.Text := 'ww';
  editPassword.Text := 'ww';


  // Check for valid state information
  CheckForm := TLocalStorage.GetValue('Login.CurrentForm');
  CheckSubform := TLocalStorage.GetValue('Login.CurrentSubForm');
  asm if (window.ValidForms.includes(CheckForm) && window.ValidSubForms.includes(CheckSubForm)) { CheckForms = true; } end;

  if (CheckForms = True) then
  begin
    if (CheckForm <> 'LoginForm') then
    begin
      if (TLocalStorage.GetValue('Login.Expiry') <> '') then
      begin
        frmMainForm.JWT_Expiry := StrToFloat(TLocalStorage.GetValue('Login.Expiry'));
        if frmMainForm.JWT_Expiry > (TTimeZone.Local.ToUniversalTime(Now) + 60/86400) then
        begin
          LoggedIn := True;
          frmMainForm.LogAction('AutoLogin - JWT Time Remaining: '+IntToStr(SecondsBetween(frmMainForm.JWT_Expiry, TTimeZone.Local.ToUniversalTime(Now)))+'s', False);
          frmMainForm.ProcessJWT(TLocalStorage.GetValue('Login.JWT'));
          frmMainForm.LoadForm(CheckForm);
          window.history.pushState(frmMainForm.CaptureState, '', frmMainForm.URL);
          frmMainForm.Position := window.history.length;
          frmMainForm.StartPosition := frmMainForm.Position - 1;
        end
        else
        begin
          frmMainForm.Logout('AutoLogin - JWT Expired');
        end;
      end;
    end;
  end;

  // Logging in Manually
  if not(LoggedIn) then
  begin
    // Update Icons
    btnLogin.Caption := '<span class="mx-2">Login</span>';


    // Check if we have remembered a Username
    Remembered :=  TLocalStorage.GetValue('Login.Username');
    if Remembered <> '' then
    begin
      editUsername.Text := Remembered;
      editUsernameChange(Sender);
      UserPass := True;
    end
    else
    begin
      editUsernameChange(Sender);
    end;

    // Add CAPS-LOCK highlighting
    asm
      editPassword.addEventListener("keyup", function(event) {
        if (event.getModifierState("CapsLock")) {
          editPassword.style.setProperty('background','yellow','important');
        }
        else {
          editPassword.style.setProperty('background','rgba(70, 90, 126, 0.4)','important');
        }
      });
      editPassword.addEventListener("focusout", function(event) {
          editPassword.style.setProperty('background','rgba(70, 90, 126, 0.4)','important');
      });
    end;

    // Show the login page
    asm await sleep(500); end;

    // Show the login form
   // divLoginBox.ElementHandle.style.setProperty('opacity','1');

    // Try to set the focus to one of these two elements
    if UserPass
    then editPassword.SetFocus
    else editUsername.SetFocus;


  end;

  frmMainForm.PreventCompilerHint(CheckSubForm);

end;

//================================================================================================//

procedure TLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  pnlLogin := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  editUsername := TEdit.Create(Self);
  editPassword := TEdit.Create(Self);
  cbxUserMerken := TCheckBox.Create(Self);
  btnLogin := TButton.Create(Self);
  tmrLoginStart := TTimer.Create(Self);

  pnlLogin.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  editUsername.BeforeLoadDFMValues;
  editPassword.BeforeLoadDFMValues;
  cbxUserMerken.BeforeLoadDFMValues;
  btnLogin.BeforeLoadDFMValues;
  tmrLoginStart.BeforeLoadDFMValues;
  try
    Name := 'LoginForm';
    Width := 640;
    Height := 480;
    Color := 16378331;
    Visible := True;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnKeyDown', 'WebFormKeyDown');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    pnlLogin.SetParentComponent(Self);
    pnlLogin.Name := 'pnlLogin';
    pnlLogin.Left := 168;
    pnlLogin.Top := 116;
    pnlLogin.Width := 369;
    pnlLogin.Height := 229;
    pnlLogin.ChildOrder := 6;
    pnlLogin.Color := 16046785;
    pnlLogin.TabOrder := 0;
    WebLabel1.SetParentComponent(pnlLogin);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 60;
    WebLabel1.Top := 35;
    WebLabel1.Width := 49;
    WebLabel1.Height := 15;
    WebLabel1.Caption := 'Benutzer:';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(pnlLogin);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 60;
    WebLabel2.Top := 62;
    WebLabel2.Width := 50;
    WebLabel2.Height := 15;
    WebLabel2.Caption := 'Passwort:';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    editUsername.SetParentComponent(pnlLogin);
    editUsername.Name := 'editUsername';
    editUsername.Left := 115;
    editUsername.Top := 32;
    editUsername.Width := 179;
    editUsername.Height := 22;
    editUsername.HeightPercent := 100.000000000000000000;
    editUsername.TextHint := 'Username';
    editUsername.WidthPercent := 100.000000000000000000;
    SetEvent(editUsername, Self, 'OnChange', 'editUsernameChange');
    editPassword.SetParentComponent(pnlLogin);
    editPassword.Name := 'editPassword';
    editPassword.Left := 115;
    editPassword.Top := 60;
    editPassword.Width := 179;
    editPassword.Height := 22;
    editPassword.ChildOrder := 1;
    editPassword.HeightPercent := 100.000000000000000000;
    editPassword.PasswordChar := '*';
    editPassword.TextHint := 'Password';
    editPassword.WidthPercent := 100.000000000000000000;
    SetEvent(editPassword, Self, 'OnChange', 'editUsernameChange');
    cbxUserMerken.SetParentComponent(pnlLogin);
    cbxUserMerken.Name := 'cbxUserMerken';
    cbxUserMerken.Left := 60;
    cbxUserMerken.Top := 163;
    cbxUserMerken.Width := 168;
    cbxUserMerken.Height := 22;
    cbxUserMerken.Cursor := crHandPoint;
    cbxUserMerken.Caption := 'Einstellungen merken';
    cbxUserMerken.Checked := True;
    cbxUserMerken.ChildOrder := 4;
    cbxUserMerken.HeightStyle := ssAuto;
    cbxUserMerken.HeightPercent := 100.000000000000000000;
    cbxUserMerken.State := cbChecked;
    cbxUserMerken.WidthPercent := 100.000000000000000000;
    btnLogin.SetParentComponent(pnlLogin);
    btnLogin.Name := 'btnLogin';
    btnLogin.Left := 115;
    btnLogin.Top := 92;
    btnLogin.Width := 126;
    btnLogin.Height := 25;
    btnLogin.Cursor := crHandPoint;
    btnLogin.Caption := '//';
    btnLogin.ChildOrder := 6;
    btnLogin.Enabled := False;
    btnLogin.HeightPercent := 100.000000000000000000;
    btnLogin.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogin, Self, 'OnClick', 'btnLoginClick');
    tmrLoginStart.SetParentComponent(Self);
    tmrLoginStart.Name := 'tmrLoginStart';
    tmrLoginStart.Enabled := False;
    tmrLoginStart.Interval := 10;
    SetEvent(tmrLoginStart, Self, 'OnTimer', 'tmrLoginStartTimer');
    tmrLoginStart.Left := 456;
    tmrLoginStart.Top := 256;
  finally
    pnlLogin.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    editUsername.AfterLoadDFMValues;
    editPassword.AfterLoadDFMValues;
    cbxUserMerken.AfterLoadDFMValues;
    btnLogin.AfterLoadDFMValues;
    tmrLoginStart.AfterLoadDFMValues;
  end;
end;

end.

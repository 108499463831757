unit Buchungen;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl,
  VCL.TMSFNCToolBar, WEBLib.Grids, Data.DB, WEBLib.DB, Vcl.Grids, WEBLib.DBCtrls,
  XData.Web.JsonDataset, XData.Web.Dataset, VCL.TMSFNCButton, XData.Web.Client, DB, WEBLib.CDS,
  WEBLib.WebCtrls, WEBLib.ComCtrls,  UnitMain, PzsDatenmodul, WEBLib.ExtCtrls, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomComponent, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGridDatabaseAdapter, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCGrid,
  VCL.TMSFNCHTMLText, VCL.TMSFNCLabelEdit, VCL.TMSFNCPanel, VCL.TMSFNCEdit, WEBLib.REST ; //, WEBLib.Crypto;



type
  TfrmBuchungen = class(TForm)
    btnKOMMT: TTMSFNCToolBarButton;
    btnGEHT: TTMSFNCToolBarButton;
    grdZeitBuchung: TDBGrid;
    dsZeitBuchung: TDataSource;
    XDataWebClient: TXDataWebClient;
    mLog: TMemo;
    conBuchungen: TClientConnection;
    tabBuchungen: TClientDataSet;
    WebDataSource1: TDataSource;
    WebButton2: TButton;
    tabZeitBuchung: TXDataWebDataSet;
    tabZeitBuchungMA_NR: TIntegerField;
    tabZeitBuchungMA_NAME: TStringField;
    tabZeitBuchungMA_VNAME: TStringField;
    tabZeitBuchungBU_TISTAMP: TStringField;
    tabZeitBuchungANWABW_BEZ: TStringField;
    tabZeitBuchungID_BU_DAT: TIntegerField;
    tabZeitBuchungFREIFELD: TStringField;
    aFileUpload: TFileUpload;
    aListBox: TListBox;
    lbDecryptedText: TLabel;
    lbEncryptedText: TLabel;
    edDecryptedText: TEdit;
    edEncryptedText: TEdit;
    btEncrypt: TButton;
    btDecrypt: TButton;
    edtTemp: TEdit;
    XDataWebDataSet1: TXDataWebDataSet;
    btnGP: TTMSFNCToolBarButton;
    btnKP: TTMSFNCToolBarButton;
    btnBuchungEdit: TTMSFNCToolBarButton;
    pnlStatus: TPanel;
    lbZeitbuchungen: TLabel;
    tabZeitBuchungANWABW_NR: TIntegerField;
    btnBuchungDel: TTMSFNCToolBarButton;
    WebPanel1: TPanel;
    btnBuNavPrev: TTMSFNCToolBarButton;
    btnBuNavNext: TTMSFNCToolBarButton;
    btnBuNavInfo: TTMSFNCToolBarButton;
    btnNavPrev: TTMSFNCToolBarButton;
    btnNavNext: TTMSFNCToolBarButton;
    btnNavInfo: TTMSFNCToolBarButton;
    tabMitarbeiter: TXDataWebDataSet;
    tabMitarbeiterMA_NR: TIntegerField;
    tabMitarbeiterMA_NAME: TStringField;
    tabMitarbeiterMA_VNAME: TStringField;
    tabMitarbeiterMA_PERSNR: TStringField;
    dsMitarbeiter: TDataSource;
    grdMitarbeiter: TDBGrid;
    edtMitarbeiter: TTMSFNCEdit;
    btnEntfMaFilter: TTMSFNCToolBarButton;
    procedure TMSFNCButton1Click(Sender: TObject);
    procedure XDataWebClientLoad(Response: TXDataClientResponse);
    procedure WebFormCreate(Sender: TObject);
    [async] procedure btnBuchenClick(Sender: TObject); async;
    [async] procedure Bu_Laden( aID : Integer ); async;
    [async] procedure Ma_Laden( aID : Integer ); async;
    procedure WebButton2Click(Sender: TObject);
    procedure conBuchungenGetDataPayload(Sender: TObject; Dataset: TDataSet; urlType: TURLType; var aPayLoad: JSValue);
    procedure conBuchungenDataReceived(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; var AResponse: string);
    procedure conBuchungenProcessMetaData(Sender: TObject; Dataset: TDataSet; FieldDefs: TFieldDefs; aMetaData: TJSObject);
    procedure tabBuchungenAfterOpen(DataSet: TDataSet);
    [async]  procedure aFileUploadGetFileAsBase64(Sender: TObject; AFileIndex: Integer; ABase64: string); async;
    [async]  procedure aFileUploadDroppedFiles(Sender: TObject; AFileList: TStringList); async;
    procedure aFileUploadUploadFileComplete(Sender: TObject; AFileIndex: Integer);
    procedure aFileUploadUploadFileProgress(Sender: TObject; AFileIndex: Integer; APosition,  ATotalSize: LongInt);
    [async] procedure WebButton5Click(Sender: TObject); async;
    [async] procedure WebButton6Click(Sender: TObject); async;
    [async] procedure btnBuchungEditClick(Sender: TObject); async;
    [async] procedure btnBuchungDelClick(Sender: TObject); async;
    procedure btEncryptClick(Sender: TObject);
    procedure btDecryptClick(Sender: TObject);
     [async] procedure WebFormShow(Sender: TObject); async;
    procedure btnLadenClick(Sender: TObject);
    procedure grdZeitBuchungDblClick(Sender: TObject);
    procedure Do_BuLaden(Sender: TObject);
    procedure grdMitarbeiterDblClick(Sender: TObject);
    procedure btnEntfMaFilterClick(Sender: TObject);
    procedure Do_MaLaden(Sender: TObject);
    procedure btnNavInfoClick(Sender: TObject);

  private
    bFirst : Boolean;
    iBuRec_Von : Integer;
    iBuRec_Bis : Integer;
    iMaRec_Von : Integer;
    iMaRec_Bis : Integer;

    [async] procedure Set_Benutzer; async;
    procedure Buchungen_Laden;
    function  Hex(ABuffer: TJSArrayBuffer): string;

  public

  protected procedure LoadDFMValues; override; end;

var frmBuchungen: TfrmBuchungen;

implementation

{$R *.dfm}

uses Bcl.Utils, CP.Func.AES , BuchungEdit ;

//================================================================================================//

procedure TfrmBuchungen.TMSFNCButton1Click(Sender: TObject);

  procedure OnSuccess(Response: TXDataClientResponse);
  begin
    mLog.Lines.Add('OnSuccess') ;
    XDataWebDataSet1.Close;
    XDataWebDataSet1.SetJsonData(Response.Result);
    XDataWebDataSet1.Open;

  end;

begin
  XDataWebClient.List('MA_DAT','', @OnSuccess )

end;

//================================================================================================//

procedure TfrmBuchungen.Ma_Laden( aID : Integer );
var Response: TXDataClientResponse;
    ClientConn: TXDataWebClient;
    Blob: JSValue;
    sDaten, sSQL, sTmp, sKopfZeile: String;
    aSL : TStringList;
    aStream : TStream;
    aTMP :  TJSObject;
    i : Integer;
    aArray : TArray<string>;
begin

  frmMainForm.LogAction('Start: Ma_Laden',false);


  btnNavInfo.Enabled := False;

  ClientConn := TXDataWebClient.Create(nil);
  ClientConn.Connection := frmMainForm.XDataConn;

  sSQL := 'SELECT  MA_NR, MA_PERSNR, MA_NAME, MA_VNAME '
         +'FROM MA_DAT WHERE (FIR_MAND=1 AND MA_AUSGESCHIE <> 1)  '
         +'AND (MA_EXTERN=0 or MA_EXTERN IS NULL) '
         +'ORDER BY MA_NAME, MA_VNAME ';


  Response :=
   await( ClientConn.RawInvokeAsync('ISystemService.GetDaten', [ sSQL, 'CSV',iMaRec_Von,iMaRec_Bis] ) );

  Blob := Response.Result;
  asm sDaten = await Blob.text(); end;

  ClientConn.Free;

  //------------------------ Daten jetzt noch entschlüsseln
  edtTemp.Text := sDaten;

  frmMainForm.LogAction('   1 : Ma_Laden',false);

  TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(edtTemp.Text),
    procedure(const AEncrypted: string)
      begin

        aSL := TStringList.Create;
        aSL.Text := AEncrypted; // mLog.Lines.Text;

        tabMitarbeiter.Close;
        tabMitarbeiter.Open;

        sKopfZeile := aSL[0];
        aArray := sKopfZeile.Split([';']);
        btnNavInfo.Text := aArray[3]+'-'+aArray[4]+' ['+aArray[2]+']';

        For i := 2 to aSL.Count -1 Do
            Begin
             sTmp := aSL[i];
              aArray := sTmp.Split([';']);
              tabMitarbeiter.Append;
              tabMitarbeiter.FieldByName('MA_NR').AsInteger     := StrToIntDef(aArray[0],0);
              tabMitarbeiter.FieldByName('MA_PERSNR').AsString  := aArray[1];
              tabMitarbeiter.FieldByName('MA_NAME').AsString    := aArray[2];
              tabMitarbeiter.FieldByName('MA_VNAME').AsString   := aArray[3];
              tabMitarbeiter.Post;
            End;

        aSL.Free;

        If aID = 0 then tabMitarbeiter.First;
        If aID > 0 then
          If not tabMitarbeiter.Locate('MA_NR',aID,[]) then tabMitarbeiter.First;

        btnNavNext.Enabled := (tabMitarbeiter.RecordCount=frmMainForm.g_RecAnzahl );
        btnNavInfo.Enabled := True;

        frmMainForm.LogAction('Ende: Ma_Laden',False);

        If not bFirst then
          Begin
             Set_Benutzer;
             Bu_Laden(0);
          End;

      end,
    procedure(const AError: string)
     begin
      ShowMessage(AError);
     end);

end;

//================================================================================================//

procedure TfrmBuchungen.Bu_Laden( aID : Integer );
var Response: TXDataClientResponse;
    ClientConn: TXDataWebClient;
    Blob: JSValue;
    sDaten, sSQL, sTmp, sKopfZeile: String;
    aSL : TStringList;
    aStream : TStream;
    aTMP :  TJSObject;
    i : Integer;
    aArray : TArray<string>;
begin

  frmMainForm.LogAction('Start: Bu_Laden',false);

  btnNavInfo.Enabled     := False;
  btnBuchungEdit.Enabled := False;
  btnBuchungDel.Enabled  := False;

//  If edtMitarbeiter.Tag > 0 then
//       edtMitarbeiter.Text := tabMitarbeiterMA_NAME.AsString+', '+tabMitarbeiterMA_VNAME.AsString
//  Else edtMitarbeiter.Text := '---';

  ClientConn := TXDataWebClient.Create(nil);
  ClientConn.Connection := frmMainForm.XDataConn;

  sSQL := 'SELECT M.MA_NAME, M.MA_VNAME, B.BU_TISTAMP, A.ANWABW_BEZ, B.ID_BU_DAT, B.MA_NR, B.BU_ANWABW '
           +'FROM MA_DAT M, BU_DAT B, ANWABW A '
           +'WHERE M.FIR_MAND=B.FIR_MAND AND M.MA_NR = B.MA_NR  AND B.BU_ANWABW = A.ANWABW_NR '
           +'AND B.BU_TISTAMP > current_timestamp -200 ';
  If edtMitarbeiter.Tag >0 then sSQL := sSQL + ' AND B.MA_NR='+IntToStr(edtMitarbeiter.Tag);

  sSQL := sSQL +'  ORDER BY B.BU_TISTAMP DESC ';

  Response :=
   await( ClientConn.RawInvokeAsync('ISystemService.GetDaten', [ sSQL, 'CSV',iBuRec_Von,iBuRec_Bis] ) );

  Blob := Response.Result;
  asm sDaten = await Blob.text(); end;

  ClientConn.Free;

  //------------------------ Daten jetzt noch entschlüsseln
  edtTemp.Text := sDaten;

  TAESFunc.Decrypt('sjaklweq', TBclUtils.DecodeBase64Url(edtTemp.Text),
    procedure(const AEncrypted: string)
      begin

        aSL := TStringList.Create;
        aSL.Text := AEncrypted; // mLog.Lines.Text;

        tabZeitBuchung.Close;
        tabZeitBuchung.Open;

        sKopfZeile := aSL[0];
        aArray := sKopfZeile.Split([';']);
        btnBuNavInfo.Text := aArray[3]+'-'+aArray[4]+' ['+aArray[2]+']';

        For i := 2 to aSL.Count -1 Do
            Begin
             sTmp := aSL[i];
              aArray := sTmp.Split([';']);
              tabZeitBuchung.Append;
              tabZeitBuchung.FieldByName('MA_NAME').AsString    := aArray[0];
              tabZeitBuchung.FieldByName('MA_VNAME').AsString   := aArray[1];
              tabZeitBuchung.FieldByName('BU_TISTAMP').AsString := aArray[2];
              tabZeitBuchung.FieldByName('ANWABW_BEZ').AsString := aArray[3];
              tabZeitBuchung.FieldByName('ID_BU_DAT').AsInteger := StrToIntDef(aArray[4],0);
              tabZeitBuchung.FieldByName('MA_NR').AsInteger     := StrToIntDef(aArray[5],0);
              tabZeitBuchung.FieldByName('ANWABW_NR').AsInteger := StrToIntDef(aArray[6],0);
              tabZeitBuchung.FieldByName('FREIFELD').AsString := 'freifeld';
              tabZeitBuchung.Post;
            End;

        aSL.Free;

        If aID = 0 then tabZeitBuchung.First;
        If aID > 0 then
          If not tabZeitBuchung.Locate('ID_BU_DAT',aID,[]) then tabZeitBuchung.First;

        btnBuNavNext.Enabled := (tabZeitBuchung.RecordCount=frmMainForm.g_RecAnzahl );

        btnBuNavInfo.Enabled   := True;
        btnBuchungEdit.Enabled := True;
        btnBuchungDel.Enabled  := True;

      end,
    procedure(const AError: string)
     begin
      ShowMessage(AError);
     end);

  frmMainForm.LogAction('Ende: Bu_Laden',false);

end;

//================================================================================================//

procedure TfrmBuchungen.Do_BuLaden(Sender: TObject);
begin

  If Sender = btnBuNavNext then
    Begin
     iBuRec_Von := iBuRec_Von + frmMainForm.g_RecAnzahl;
    End
  Else
    Begin
     iBuRec_Von := iBuRec_Von - frmMainForm.g_RecAnzahl;
     If iBuRec_Von < 1 then iBuRec_Von := 1;
    End;
  iBuRec_Bis := iBuRec_Von + frmMainForm.g_RecAnzahl-1;

  Bu_Laden(0);

end;

//================================================================================================//

procedure TfrmBuchungen.Do_MaLaden(Sender: TObject);
begin

  If Sender = btnNavNext then
    Begin
     iMaRec_Von := iMaRec_Von + frmMainForm.g_RecAnzahl;
    End
  Else
    Begin
     iMaRec_Von := iMaRec_Von - frmMainForm.g_RecAnzahl;
     If iMaRec_Von < 1 then iMaRec_Von := 1;
    End;
  iMaRec_Bis := iMaRec_Von + frmMainForm.g_RecAnzahl-1;

  Ma_Laden(0);

end;

//================================================================================================//

procedure TfrmBuchungen.grdZeitBuchungDblClick(Sender: TObject);
begin

end;

//================================================================================================//

procedure TfrmBuchungen.Buchungen_Laden;
var sSQL, sURI : String;
begin

  sSQL := 'SELECT M.MA_NAME, M.MA_VNAME, B.BU_TISTAMP, A.ANWABW_BEZ, B.MA_NR, B.ID_BU_DAT '
         +'FROM MA_DAT M, BU_DAT B, ANWABW A '
         +'WHERE M.FIR_MAND=B.FIR_MAND AND M.MA_NR = B.MA_NR  AND B.BU_ANWABW = A.ANWABW_NR '
//         +'AND B.MA_NR='+frmMainForm.BenutzerMaNr
         +'AND B.BU_TISTAMP > current_timestamp -200 ORDER BY B.BU_TISTAMP DESC';

  sURI := 'http://localhost:2001/tms/xdata/SystemService/GetDaten?sSQL='+sSQL+'&sFormat=CSV';

  conBuchungen.Active := False ;
  conBuchungen.URI := sURI;
  conBuchungen.Active := True ;

end;

//================================================================================================//

procedure TfrmBuchungen.tabBuchungenAfterOpen(DataSet: TDataSet);
begin

//  grdBuchungen.Columns[0].Title := 'Name';
//  grdBuchungen.Columns[0].Width := 100;
//
//  grdBuchungen.Columns[1].Title := 'Vorname';
//  grdBuchungen.Columns[1].Width := 70;
//
//  grdBuchungen.Columns[2].Title := 'Zeitstempel';
//  grdBuchungen.Columns[2].Width := 120;
//
//  grdBuchungen.Columns[3].Title := 'Grund';
//  grdBuchungen.Columns[3].Width := 60;
//
//  grdBuchungen.Columns[4].Title := 'ID-MA';
//  grdBuchungen.Columns[4].Width := 30;
//
//  grdBuchungen.Columns[5].Title := 'ID';
//  grdBuchungen.Columns[5].Width := 30;
//
//
//  tabBuchungen.FieldDefs[0].Name := 'NAME';
//  tabBuchungen.FieldDefs[1].Name := 'VORNAME';
//  tabBuchungen.FieldDefs[2].Name := 'ZEITSTEMPEL';
//  tabBuchungen.FieldDefs[3].Name := 'GRUND';
//  tabBuchungen.FieldDefs[4].Name := 'MA_NR';
//  tabBuchungen.FieldDefs[5].Name := 'ID';

end;

//================================================================================================//

procedure TfrmBuchungen.btnLadenClick(Sender: TObject);
var iID : Integer;
begin

  iBuRec_Von := 1;
  iBuRec_Bis := frmMainForm.g_RecAnzahl;

  iID := tabZeitBuchung.FieldByName('ID_BU_DAT').AsInteger;
  Bu_Laden(iID);

end;

//================================================================================================//

procedure TfrmBuchungen.btnNavInfoClick(Sender: TObject);
var iID : Integer;
begin

  iMaRec_Von := 1;
  iMaRec_Bis := frmMainForm.g_RecAnzahl;

  iID := tabMitarbeiter.FieldByName('MA_NR').AsInteger;
  Ma_Laden(iID);

end;

//================================================================================================//

procedure TfrmBuchungen.WebButton2Click(Sender: TObject);
begin

  Buchungen_Laden ;

end;

//================================================================================================//

procedure TfrmBuchungen.WebButton5Click(Sender: TObject);
var
//  arr: TJSUint8Array;
//  buf: TJSArrayBuffer;
//  hash: string;
begin
//  arr := GetMessageEncoding('text_to_be_hashed');
//  buf := await(TJSArrayBuffer, window.crypto.subtle.digest('SHA-256', arr));
//  hash := Hex(buf);
//  ShowMessage(hash);

end;

procedure TfrmBuchungen.WebButton6Click(Sender: TObject);
//var sha: TWebSHAHash;
//    s : string;
begin

//  sha := TWebSHAHash.Create(ehSHA256);
//  s := Await(string, sha.Hash('AUTO'));
//  mLog.Lines.Add( s );
//  sha.Free;
//
//  sha := TWebSHAHash.Create( ehSHA256);
//  s := Await(string, sha.Hash(s));
//  mLog.Lines.Add( s );
//  sha.Free;


end;

//================================================================================================//

procedure TfrmBuchungen.grdMitarbeiterDblClick(Sender: TObject);
var iMaNr : Integer;
begin


  iMaNr := tabMitarbeiterMA_NR.AsInteger;
  If iMaNr=0 then Exit;

  edtMitarbeiter.Tag := iMaNr;
  edtMitarbeiter.Text := tabMitarbeiterMA_NAME.AsString+', '+tabMitarbeiterMA_VNAME.AsString;

  iBuRec_Von := 1;
  iBuRec_Bis := frmMainForm.g_RecAnzahl;

  Bu_Laden(0);

end;

//================================================================================================//

function TfrmBuchungen.Hex(ABuffer: TJSArrayBuffer): string;
var
  dv: TJSDataView;
  I: Integer;
  value: LongWord;
begin
  Result := '';
  dv := TJSDataView.new(ABuffer);
  for I := 0 to (dv.byteLength div 4) - 1 do
  begin
    value := dv.getUint32(I * 4);
    Result := Result + IntToHex(value, 8);
  end;
end;

//================================================================================================//

procedure TfrmBuchungen.conBuchungenDataReceived(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; var AResponse: string);
begin

  mLog.Lines.Add('WebClientConnectionDataReceived');
  mLog.Lines.Add(AResponse);

end;

//================================================================================================//

procedure TfrmBuchungen.conBuchungenGetDataPayload(Sender: TObject; Dataset: TDataSet;
  urlType: TURLType; var aPayLoad: JSValue);
begin

 mLog.Lines.Add('WebClientConnectionGetDataPayload');
 mLog.Lines.Add( Dataset.Name );

end;

//================================================================================================//

procedure TfrmBuchungen.conBuchungenProcessMetaData(Sender: TObject; Dataset: TDataSet;
  FieldDefs: TFieldDefs; aMetaData: TJSObject);
begin

 mLog.Lines.Add('WebClientConnectionProcessMetaData');

end;

//================================================================================================//

procedure TfrmBuchungen.aFileUploadDroppedFiles(Sender: TObject; AFileList: TStringList);
var i : Integer;
begin

  aFileUpload.Cursor := crHourGlass;

 aListBox.Items.Clear;
// for i := 0 to aFileList.Count-1 Do
//   aListBox.Items.Add(  TFile(aFileUpload.Files[i]).Name ) ;

  for i := 0 to aFileList.Count-1 Do
    await(  aFileUpload.Files[i].GetFileAsBase64 ) ;


  aFileUpload.Cursor := crDefault;

end;

//================================================================================================//

procedure TfrmBuchungen.aFileUploadGetFileAsBase64(Sender: TObject; AFileIndex: Integer; ABase64: string);
var Response: TXDataClientResponse;
    sDateiName : String;
    aFile : TFile;
begin

  //mLog.Lines.Add( ABase64 );

  aFile :=  aFileUpload.Files[AFileIndex];
  sDateiName := aFile.Name;

  mLog.Lines.Add('Start '+sDateiName);

  Response := await( XDataWebClient.RawInvokeAsync('ISystemService.SendDatei', [ ABase64, sDateiName,1  ] ));

  mLog.Lines.Add(Response.ResponseText);

  aListBox.Items.Add('OK - '+ aFile.Name ) ;


end;

//================================================================================================//

procedure TfrmBuchungen.aFileUploadUploadFileComplete(Sender: TObject; AFileIndex: Integer);
begin

  aListBox.Items.Add('UploadUploadFileComplete - '+ TFile(aFileUpload.Files[AFileIndex]).Name ) ;

end;

//================================================================================================//

procedure TfrmBuchungen.aFileUploadUploadFileProgress(Sender: TObject; AFileIndex: Integer;
  APosition, ATotalSize: LongInt);
begin

  mLog.Lines.Add('UploadFileProgress ' +IntToStr(APosition));

end;

//================================================================================================//

procedure TfrmBuchungen.WebFormCreate(Sender: TObject);
begin

  bFirst := False;

  tabZeitBuchung.Connection   := frmMainForm.XDataConn;
  XDataWebClient.Connection   := frmMainForm.XDataConn;
  XDataWebDataSet1.Connection := frmMainForm.XDataConn;

  iBuRec_Von := 1;
  iBuRec_Bis := frmMainForm.g_RecAnzahl;

  iMaRec_Von := 1;
  iMaRec_Bis := frmMainForm.g_RecAnzahl;


end;

//================================================================================================//

procedure TfrmBuchungen.WebFormShow(Sender: TObject);
begin

  Ma_Laden(0);

end;

//================================================================================================//

procedure TfrmBuchungen.Set_Benutzer;
var iMaNr : Integer;
begin

  frmMainForm.LogAction('Set_Benutzer... ',false);

  If bFirst then Exit;

  iMaNr := StrToIntDef( frmMainForm.BenutzerMaNr,0 );

  If tabMitarbeiter.Locate('MA_NR', iMaNr,[]) then
    Begin
      edtMitarbeiter.Tag := iMaNr;
      edtMitarbeiter.Text := tabMitarbeiterMA_NAME.AsString+', '+tabMitarbeiterMA_VNAME.AsString
    End;

  bFirst := True;

end;

//================================================================================================//

procedure TfrmBuchungen.btDecryptClick(Sender: TObject);
begin

  TAESFunc.Decrypt('sjaklweqjowqgedsakdtDSADsddqwo', TBclUtils.DecodeBase64Url(edEncryptedText.Text),
    procedure(const AEncrypted: string)
    begin
      edDecryptedText.Text := AEncrypted;
    end,
    procedure(const AError: string)
    begin
      ShowMessage(AError);
    end);

end;

//================================================================================================//

procedure TfrmBuchungen.btEncryptClick(Sender: TObject);
begin

  TAESFunc.Encrypt('sjaklweqjowqgedsakdtDSADsddqwo', edDecryptedText.Text,
    procedure(const ABytes: TBytes)
    begin
      edEncryptedText.Text := TBclUtils.EncodeBase64Url(ABytes);
    end,
    procedure(const AError: string)
    begin
      ShowMessage(AError);
    end);

end;

//================================================================================================//

procedure TfrmBuchungen.btnBuchenClick(Sender: TObject);
var Response: TXDataClientResponse;
    iMaNr, iGrund : Integer;
begin

  //iMaNr := StrToIntDef( frmMainForm.BenutzerMaNr,0 );
  iMaNr := edtMitarbeiter.Tag;
  If iMaNr = 0 then
    Begin
      Showmessage('Es ist kein Mitarbeiter ausgewählt..');
      Exit;
    End;

  If Sender= btnGP    then iGrund := 1;
  If Sender= btnKP    then iGrund := 2;
  If Sender= btnKOMMT then iGrund := 3;
  If Sender= btnGEHT  then iGrund := 4;

  Response := await( XDataWebClient.RawInvokeAsync('ISystemService.BuchungIns', [ iMaNr, iGrund ] ));

  Bu_Laden(0);

  mLog.Lines.Add('Neue ID: '+Response.ResponseText);

end;

//================================================================================================//

procedure TfrmBuchungen.btnBuchungDelClick(Sender: TObject);
var Response: TXDataClientResponse;
    iID : Integer;
begin

  iID  :=  tabZeitBuchungID_BU_DAT.AsInteger;

  If iID=0 then Exit;

  Response := await( XDataWebClient.RawInvokeAsync('ISystemService.BuchungDel', [ iID ] ));

  tabZeitBuchung.Delete;

end;

//================================================================================================//

procedure TfrmBuchungen.btnBuchungEditClick(Sender: TObject);
 var sMaName, sZuname, sVorname, sZeitStempel : String;
     dtZP : TDateTime;
     iID, iMaNr, iGrund : Integer;
     Response: TXDataClientResponse;
begin

  iID    := tabZeitBuchungID_BU_DAT.AsInteger;
  iMaNr  := tabZeitBuchungMA_NR.AsInteger;
  iGrund := tabZeitBuchungANWABW_NR.AsInteger;

  If iID=0 then Exit;

  sMaName :=  tabZeitBuchungMA_NAME.AsString+', '
            + tabZeitBuchungMA_VNAME.AsString;

  frmBuchungEdit := TfrmBuchungEdit.Create(Self);
  frmBuchungEdit.Caption := 'Buchung bearbeiten: '+sMaName;
  frmBuchungEdit.Popup := True;
  frmBuchungEdit.Border := fbDialog;

  await( dmPzs.Fill_AnwAbw(frmBuchungEdit.cbxGrund,True)  );

  // used to manage Back button handling to close subform
  //  window.location.hash := 'subform';

  // load file HTML template + controls
  await( TfrmBuchungEdit, frmBuchungEdit.Load() );

  // nach dem Laden des Formulars die Felder befüllen...
  frmBuchungEdit.edtZuname.Text  := tabZeitBuchungMA_NAME.AsString;
  frmBuchungEdit.edtVorname.Text := tabZeitBuchungMA_VNAME.AsString;
  sZeitStempel := tabZeitBuchungBU_TISTAMP.AsString;
  Try
    dtZP := StrToDateTime( sZeitStempel );
  Except
    Showmessage('Fehler beim Umwandeln des Zeitstempels...'+sZeitStempel);
  End;
  frmBuchungEdit.edtDatum.Date    := Trunc( dtZP );
  frmBuchungEdit.edtZeit.DateTime := Frac( dtZP );



 // dmPzs.FillCBX_Mit_AnwAbw( frmBuchungEdit.cbxGrund, True) ;


  Case iGrund Of
    3 : frmBuchungEdit.cbxGrund.ItemIndex := 0;
    1 : frmBuchungEdit.cbxGrund.ItemIndex := 1;
    2 : frmBuchungEdit.cbxGrund.ItemIndex := 2;
    4 : frmBuchungEdit.cbxGrund.ItemIndex := 3;
  End;



  Try
    // excute form and wait for close
    await( TModalResult, frmBuchungEdit.Execute);

    // Änderungen speichern
    If frmBuchungEdit.ModalResult = mrOK then
      Begin

        dtZP := Trunc(frmBuchungEdit.edtDatum.Date) + Frac( frmBuchungEdit.edtZeit.DateTime );
        await(tabZeitBuchung.Edit);
        tabZeitBuchung.FieldByName('BU_TISTAMP').AsString := DateTimeToStr(dtZP);

        Case frmBuchungEdit.cbxGrund.ItemIndex Of
          0 : iGrund := 3;
          1 : iGrund := 1;
          2 : iGrund := 2;
          3 : iGrund := 4;
        End;
        tabZeitBuchung.FieldByName('ANWABW_NR').AsInteger := iGrund;
        tabZeitBuchung.FieldByName('ANWABW_BEZ').AsString := dmPzs.Get_AnwAbw_Bez(iGrund);

        await(tabZeitBuchung.Post);

        //in die Datenbank übernehmen
        Response := await( XDataWebClient.RawInvokeAsync('ISystemService.BuchungUpd', [ iID, iGrund, DateTimeToStr(dtZP) ] ));

      End;

  Finally
    frmBuchungEdit.Free;
    mLog.Lines.Add('frmBuchungEdit.free')
  End;


// Daten_Laden(0);
 //Daten_Laden( tabMaDat.FieldByName('MA_NR').AsInteger );

end;

//================================================================================================//

procedure TfrmBuchungen.btnEntfMaFilterClick(Sender: TObject);
begin

  edtMitarbeiter.Tag := 0;
  edtMitarbeiter.Text := '---';

  Bu_Laden(0);

end;

//================================================================================================//

procedure TfrmBuchungen.XDataWebClientLoad(Response: TXDataClientResponse);
var Mab: TJSObject;
begin

  // Both lines below are equivalent.
//  Mab := TJSObject(Response.Result);
//  Mab := Response.ResultAsObject;

//  mLog.Lines.Clear;
//  mLog.Lines.Add( Response.ResponseText );


//    tabTest.Close;
//    tabTest.SetJsonData(Response.Result);
//    tabTest.Open;



end;

procedure TfrmBuchungen.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbDecryptedText := TLabel.Create(Self);
  lbEncryptedText := TLabel.Create(Self);
  btnKOMMT := TTMSFNCToolBarButton.Create(Self);
  btnGEHT := TTMSFNCToolBarButton.Create(Self);
  grdZeitBuchung := TDBGrid.Create(Self);
  mLog := TMemo.Create(Self);
  WebButton2 := TButton.Create(Self);
  aFileUpload := TFileUpload.Create(Self);
  aListBox := TListBox.Create(Self);
  edDecryptedText := TEdit.Create(Self);
  edEncryptedText := TEdit.Create(Self);
  btEncrypt := TButton.Create(Self);
  btDecrypt := TButton.Create(Self);
  edtTemp := TEdit.Create(Self);
  btnGP := TTMSFNCToolBarButton.Create(Self);
  btnKP := TTMSFNCToolBarButton.Create(Self);
  btnBuchungEdit := TTMSFNCToolBarButton.Create(Self);
  pnlStatus := TPanel.Create(Self);
  lbZeitbuchungen := TLabel.Create(Self);
  btnBuchungDel := TTMSFNCToolBarButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  btnBuNavPrev := TTMSFNCToolBarButton.Create(Self);
  btnBuNavNext := TTMSFNCToolBarButton.Create(Self);
  btnBuNavInfo := TTMSFNCToolBarButton.Create(Self);
  btnNavPrev := TTMSFNCToolBarButton.Create(Self);
  btnNavNext := TTMSFNCToolBarButton.Create(Self);
  btnNavInfo := TTMSFNCToolBarButton.Create(Self);
  grdMitarbeiter := TDBGrid.Create(Self);
  edtMitarbeiter := TTMSFNCEdit.Create(Self);
  btnEntfMaFilter := TTMSFNCToolBarButton.Create(Self);
  dsZeitBuchung := TDataSource.Create(Self);
  XDataWebClient := TXDataWebClient.Create(Self);
  conBuchungen := TClientConnection.Create(Self);
  tabBuchungen := TClientDataSet.Create(Self);
  WebDataSource1 := TDataSource.Create(Self);
  tabZeitBuchung := TXDataWebDataSet.Create(Self);
  tabZeitBuchungMA_NR := TIntegerField.Create(Self);
  tabZeitBuchungMA_NAME := TStringField.Create(Self);
  tabZeitBuchungMA_VNAME := TStringField.Create(Self);
  tabZeitBuchungBU_TISTAMP := TStringField.Create(Self);
  tabZeitBuchungANWABW_BEZ := TStringField.Create(Self);
  tabZeitBuchungID_BU_DAT := TIntegerField.Create(Self);
  tabZeitBuchungANWABW_NR := TIntegerField.Create(Self);
  tabZeitBuchungFREIFELD := TStringField.Create(Self);
  XDataWebDataSet1 := TXDataWebDataSet.Create(Self);
  tabMitarbeiter := TXDataWebDataSet.Create(Self);
  tabMitarbeiterMA_NR := TIntegerField.Create(Self);
  tabMitarbeiterMA_NAME := TStringField.Create(Self);
  tabMitarbeiterMA_VNAME := TStringField.Create(Self);
  tabMitarbeiterMA_PERSNR := TStringField.Create(Self);
  dsMitarbeiter := TDataSource.Create(Self);

  lbDecryptedText.BeforeLoadDFMValues;
  lbEncryptedText.BeforeLoadDFMValues;
  btnKOMMT.BeforeLoadDFMValues;
  btnGEHT.BeforeLoadDFMValues;
  grdZeitBuchung.BeforeLoadDFMValues;
  mLog.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  aFileUpload.BeforeLoadDFMValues;
  aListBox.BeforeLoadDFMValues;
  edDecryptedText.BeforeLoadDFMValues;
  edEncryptedText.BeforeLoadDFMValues;
  btEncrypt.BeforeLoadDFMValues;
  btDecrypt.BeforeLoadDFMValues;
  edtTemp.BeforeLoadDFMValues;
  btnGP.BeforeLoadDFMValues;
  btnKP.BeforeLoadDFMValues;
  btnBuchungEdit.BeforeLoadDFMValues;
  pnlStatus.BeforeLoadDFMValues;
  lbZeitbuchungen.BeforeLoadDFMValues;
  btnBuchungDel.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  btnBuNavPrev.BeforeLoadDFMValues;
  btnBuNavNext.BeforeLoadDFMValues;
  btnBuNavInfo.BeforeLoadDFMValues;
  btnNavPrev.BeforeLoadDFMValues;
  btnNavNext.BeforeLoadDFMValues;
  btnNavInfo.BeforeLoadDFMValues;
  grdMitarbeiter.BeforeLoadDFMValues;
  edtMitarbeiter.BeforeLoadDFMValues;
  btnEntfMaFilter.BeforeLoadDFMValues;
  dsZeitBuchung.BeforeLoadDFMValues;
  XDataWebClient.BeforeLoadDFMValues;
  conBuchungen.BeforeLoadDFMValues;
  tabBuchungen.BeforeLoadDFMValues;
  WebDataSource1.BeforeLoadDFMValues;
  tabZeitBuchung.BeforeLoadDFMValues;
  tabZeitBuchungMA_NR.BeforeLoadDFMValues;
  tabZeitBuchungMA_NAME.BeforeLoadDFMValues;
  tabZeitBuchungMA_VNAME.BeforeLoadDFMValues;
  tabZeitBuchungBU_TISTAMP.BeforeLoadDFMValues;
  tabZeitBuchungANWABW_BEZ.BeforeLoadDFMValues;
  tabZeitBuchungID_BU_DAT.BeforeLoadDFMValues;
  tabZeitBuchungANWABW_NR.BeforeLoadDFMValues;
  tabZeitBuchungFREIFELD.BeforeLoadDFMValues;
  XDataWebDataSet1.BeforeLoadDFMValues;
  tabMitarbeiter.BeforeLoadDFMValues;
  tabMitarbeiterMA_NR.BeforeLoadDFMValues;
  tabMitarbeiterMA_NAME.BeforeLoadDFMValues;
  tabMitarbeiterMA_VNAME.BeforeLoadDFMValues;
  tabMitarbeiterMA_PERSNR.BeforeLoadDFMValues;
  dsMitarbeiter.BeforeLoadDFMValues;
  try
    Name := 'frmBuchungen';
    Width := 1137;
    Height := 587;
    Cursor := crHandPoint;
    Color := 16378331;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    lbDecryptedText.SetParentComponent(Self);
    lbDecryptedText.Name := 'lbDecryptedText';
    lbDecryptedText.Left := 792;
    lbDecryptedText.Top := 412;
    lbDecryptedText.Width := 54;
    lbDecryptedText.Height := 15;
    lbDecryptedText.Caption := 'Decrypted';
    lbDecryptedText.Color := 16378331;
    lbDecryptedText.HeightPercent := 100.000000000000000000;
    lbDecryptedText.Visible := False;
    lbDecryptedText.WidthPercent := 100.000000000000000000;
    lbEncryptedText.SetParentComponent(Self);
    lbEncryptedText.Name := 'lbEncryptedText';
    lbEncryptedText.Left := 792;
    lbEncryptedText.Top := 455;
    lbEncryptedText.Width := 53;
    lbEncryptedText.Height := 15;
    lbEncryptedText.Caption := 'Encrypted';
    lbEncryptedText.Color := 16378331;
    lbEncryptedText.HeightPercent := 100.000000000000000000;
    lbEncryptedText.Visible := False;
    lbEncryptedText.WidthPercent := 100.000000000000000000;
    btnKOMMT.SetParentComponent(Self);
    btnKOMMT.Name := 'btnKOMMT';
    btnKOMMT.Left := 798;
    btnKOMMT.Top := 112;
    btnKOMMT.Width := 153;
    btnKOMMT.Height := 60;
    btnKOMMT.Cursor := crHandPoint;
    btnKOMMT.ParentDoubleBuffered := False;
    btnKOMMT.DoubleBuffered := True;
    btnKOMMT.Font.Charset := DEFAULT_CHARSET;
    btnKOMMT.Font.Color := clWindowText;
    btnKOMMT.Font.Height := -16;
    btnKOMMT.Font.Name := 'Segoe UI';
    btnKOMMT.Font.Style := [];
    btnKOMMT.ParentColor := True;
    btnKOMMT.TabOrder := 0;
    SetEvent(btnKOMMT, Self, 'OnClick', 'btnBuchenClick');
    btnKOMMT.Text := 'Kommt';
    btnKOMMT.ControlIndex := 0;
    btnGEHT.SetParentComponent(Self);
    btnGEHT.Name := 'btnGEHT';
    btnGEHT.Left := 968;
    btnGEHT.Top := 180;
    btnGEHT.Width := 153;
    btnGEHT.Height := 60;
    btnGEHT.Cursor := crHandPoint;
    btnGEHT.ParentDoubleBuffered := False;
    btnGEHT.DoubleBuffered := True;
    btnGEHT.Font.Charset := DEFAULT_CHARSET;
    btnGEHT.Font.Color := clWindowText;
    btnGEHT.Font.Height := -16;
    btnGEHT.Font.Name := 'Segoe UI';
    btnGEHT.Font.Style := [];
    btnGEHT.ParentColor := True;
    btnGEHT.TabOrder := 1;
    SetEvent(btnGEHT, Self, 'OnClick', 'btnBuchenClick');
    btnGEHT.Text := 'Geht';
    btnGEHT.ControlIndex := 0;
    grdZeitBuchung.SetParentComponent(Self);
    grdZeitBuchung.Name := 'grdZeitBuchung';
    grdZeitBuchung.Left := 335;
    grdZeitBuchung.Top := 112;
    grdZeitBuchung.Width := 451;
    grdZeitBuchung.Height := 435;
    grdZeitBuchung.Cursor := crHandPoint;
    grdZeitBuchung.Anchors := [akLeft,akTop,akBottom];
    grdZeitBuchung.Columns.Clear;
    with grdZeitBuchung.Columns.Add do
    begin
      DataField := 'MA_NAME';
      Title := 'Zuname';
      Width := 99;
    end;
    with grdZeitBuchung.Columns.Add do
    begin
      DataField := 'MA_VNAME';
      Title := 'Vorname';
      Width := 83;
    end;
    with grdZeitBuchung.Columns.Add do
    begin
      Alignment := taCenter;
      DataField := 'BU_TISTAMP';
      Title := 'Zeitstempel';
      Width := 118;
    end;
    with grdZeitBuchung.Columns.Add do
    begin
      DataField := 'ANWABW_BEZ';
      Title := 'Buchungsgrund';
      Width := 127;
    end;
    grdZeitBuchung.DataSource := dsZeitBuchung;
    grdZeitBuchung.ElementFont := efCSS;
    grdZeitBuchung.FixedFont.Charset := DEFAULT_CHARSET;
    grdZeitBuchung.FixedFont.Color := clWindowText;
    grdZeitBuchung.FixedFont.Height := -12;
    grdZeitBuchung.FixedFont.Name := 'Segoe UI';
    grdZeitBuchung.FixedFont.Style := [];
    grdZeitBuchung.FixedCols := 0;
    grdZeitBuchung.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goColSizing,goColMoving,goRowSelect,goFixedRowDefAlign];
    grdZeitBuchung.TabOrder := 2;
    grdZeitBuchung.HeightPercent := 100.000000000000000000;
    grdZeitBuchung.WidthPercent := 100.000000000000000000;
    SetEvent(grdZeitBuchung, Self, 'OnDblClick', 'btnBuchungEditClick');
    grdZeitBuchung.ColWidths[0] := 99;
    grdZeitBuchung.ColWidths[1] := 83;
    grdZeitBuchung.ColWidths[2] := 118;
    grdZeitBuchung.ColWidths[3] := 127;
    mLog.SetParentComponent(Self);
    mLog.Name := 'mLog';
    mLog.Left := 798;
    mLog.Top := 265;
    mLog.Width := 324;
    mLog.Height := 89;
    mLog.Anchors := [akLeft,akTop,akRight];
    mLog.HeightPercent := 100.000000000000000000;
    mLog.Lines.BeginUpdate;
    try
      mLog.Lines.Clear;
      mLog.Lines.Add('');
    finally
      mLog.Lines.EndUpdate;
    end;
    mLog.SelLength := 0;
    mLog.SelStart := 2;
    mLog.WidthPercent := 100.000000000000000000;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 32;
    WebButton2.Top := 467;
    WebButton2.Width := 47;
    WebButton2.Height := 66;
    WebButton2.Caption := 'Open';
    WebButton2.ChildOrder := 10;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.Visible := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    aFileUpload.SetParentComponent(Self);
    aFileUpload.Name := 'aFileUpload';
    aFileUpload.Left := 794;
    aFileUpload.Top := 353;
    aFileUpload.Width := 327;
    aFileUpload.Height := 58;
    aFileUpload.Cursor := crHandPoint;
    aFileUpload.Caption := 'Datei w'#228'hlen';
    aFileUpload.ChildOrder := 17;
    aFileUpload.DragCaption := 'Hierher ziehen';
    aFileUpload.Multifile := True;
    aFileUpload.ShowPicture := False;
    aFileUpload.Visible := False;
    SetEvent(aFileUpload, Self, 'OnDroppedFiles', 'aFileUploadDroppedFiles');
    SetEvent(aFileUpload, Self, 'OnGetFileAsBase64', 'aFileUploadGetFileAsBase64');
    SetEvent(aFileUpload, Self, 'OnUploadFileComplete', 'aFileUploadUploadFileComplete');
    SetEvent(aFileUpload, Self, 'OnUploadFileProgress', 'aFileUploadUploadFileProgress');
    aListBox.SetParentComponent(Self);
    aListBox.Name := 'aListBox';
    aListBox.Left := 792;
    aListBox.Top := 406;
    aListBox.Width := 284;
    aListBox.Height := 51;
    aListBox.HeightPercent := 100.000000000000000000;
    aListBox.ItemHeight := 15;
    aListBox.WidthPercent := 100.000000000000000000;
    aListBox.ItemIndex := -1;
    edDecryptedText.SetParentComponent(Self);
    edDecryptedText.Name := 'edDecryptedText';
    edDecryptedText.Left := 792;
    edDecryptedText.Top := 482;
    edDecryptedText.Width := 284;
    edDecryptedText.Height := 22;
    edDecryptedText.ChildOrder := 1;
    edDecryptedText.HeightPercent := 100.000000000000000000;
    edDecryptedText.Visible := False;
    edDecryptedText.WidthPercent := 100.000000000000000000;
    edEncryptedText.SetParentComponent(Self);
    edEncryptedText.Name := 'edEncryptedText';
    edEncryptedText.Left := 792;
    edEncryptedText.Top := 525;
    edEncryptedText.Width := 284;
    edEncryptedText.Height := 22;
    edEncryptedText.ChildOrder := 1;
    edEncryptedText.HeightPercent := 100.000000000000000000;
    edEncryptedText.Visible := False;
    edEncryptedText.WidthPercent := 100.000000000000000000;
    btEncrypt.SetParentComponent(Self);
    btEncrypt.Name := 'btEncrypt';
    btEncrypt.Left := 751;
    btEncrypt.Top := 515;
    btEncrypt.Width := 82;
    btEncrypt.Height := 32;
    btEncrypt.Caption := 'Encrypt';
    btEncrypt.ChildOrder := 4;
    btEncrypt.HeightPercent := 100.000000000000000000;
    btEncrypt.Visible := False;
    btEncrypt.WidthPercent := 100.000000000000000000;
    SetEvent(btEncrypt, Self, 'OnClick', 'btEncryptClick');
    btDecrypt.SetParentComponent(Self);
    btDecrypt.Name := 'btDecrypt';
    btDecrypt.Left := 1016;
    btDecrypt.Top := 508;
    btDecrypt.Width := 60;
    btDecrypt.Height := 25;
    btDecrypt.Caption := 'Decrypt';
    btDecrypt.ChildOrder := 4;
    btDecrypt.HeightPercent := 100.000000000000000000;
    btDecrypt.Visible := False;
    btDecrypt.WidthPercent := 100.000000000000000000;
    SetEvent(btDecrypt, Self, 'OnClick', 'btDecryptClick');
    edtTemp.SetParentComponent(Self);
    edtTemp.Name := 'edtTemp';
    edtTemp.Left := 972;
    edtTemp.Top := 48;
    edtTemp.Width := 121;
    edtTemp.Height := 22;
    edtTemp.ChildOrder := 23;
    edtTemp.HeightPercent := 100.000000000000000000;
    edtTemp.Text := 'edtTemp';
    edtTemp.Visible := False;
    edtTemp.WidthPercent := 100.000000000000000000;
    btnGP.SetParentComponent(Self);
    btnGP.Name := 'btnGP';
    btnGP.Left := 798;
    btnGP.Top := 180;
    btnGP.Width := 153;
    btnGP.Height := 60;
    btnGP.Cursor := crHandPoint;
    btnGP.ParentDoubleBuffered := False;
    btnGP.DoubleBuffered := True;
    btnGP.Font.Charset := DEFAULT_CHARSET;
    btnGP.Font.Color := clWindowText;
    btnGP.Font.Height := -16;
    btnGP.Font.Name := 'Segoe UI';
    btnGP.Font.Style := [];
    btnGP.ParentColor := True;
    btnGP.TabOrder := 12;
    SetEvent(btnGP, Self, 'OnClick', 'btnBuchenClick');
    btnGP.AutoOptionsMenuText := 'Geht in Pause';
    btnGP.Text := 'Geht in Pause';
    btnGP.ControlIndex := 0;
    btnKP.SetParentComponent(Self);
    btnKP.Name := 'btnKP';
    btnKP.Left := 968;
    btnKP.Top := 112;
    btnKP.Width := 153;
    btnKP.Height := 60;
    btnKP.Cursor := crHandPoint;
    btnKP.ParentDoubleBuffered := False;
    btnKP.DoubleBuffered := True;
    btnKP.Font.Charset := DEFAULT_CHARSET;
    btnKP.Font.Color := clWindowText;
    btnKP.Font.Height := -16;
    btnKP.Font.Name := 'Segoe UI';
    btnKP.Font.Style := [];
    btnKP.ParentColor := True;
    btnKP.TabOrder := 13;
    SetEvent(btnKP, Self, 'OnClick', 'btnBuchenClick');
    btnKP.Text := 'Kommt aus Pause';
    btnKP.ControlIndex := 0;
    btnBuchungEdit.SetParentComponent(Self);
    btnBuchungEdit.Name := 'btnBuchungEdit';
    btnBuchungEdit.Left := 335;
    btnBuchungEdit.Top := 75;
    btnBuchungEdit.Width := 92;
    btnBuchungEdit.Height := 30;
    btnBuchungEdit.Cursor := crHandPoint;
    btnBuchungEdit.ParentDoubleBuffered := False;
    btnBuchungEdit.DoubleBuffered := True;
    btnBuchungEdit.Font.Charset := DEFAULT_CHARSET;
    btnBuchungEdit.Font.Color := clWindowText;
    btnBuchungEdit.Font.Height := -16;
    btnBuchungEdit.Font.Name := 'Segoe UI';
    btnBuchungEdit.Font.Style := [];
    btnBuchungEdit.ParentColor := True;
    btnBuchungEdit.TabOrder := 14;
    SetEvent(btnBuchungEdit, Self, 'OnClick', 'btnBuchungEditClick');
    btnBuchungEdit.Text := #196'ndern';
    btnBuchungEdit.Bitmaps.Clear;
    with btnBuchungEdit.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Buchungen.btnBuchungEdit.Bitmaps.Bitmap.png');
      BitmapName := 'UPD';
    end;
    btnBuchungEdit.BitmapVisible := True;
    btnBuchungEdit.ControlIndex := 0;
    pnlStatus.SetParentComponent(Self);
    pnlStatus.Name := 'pnlStatus';
    pnlStatus.Left := 0;
    pnlStatus.Top := 0;
    pnlStatus.Width := 1137;
    pnlStatus.Height := 35;
    pnlStatus.Align := alTop;
    pnlStatus.ChildOrder := 11;
    pnlStatus.Color := 16370864;
    pnlStatus.TabOrder := 15;
    lbZeitbuchungen.SetParentComponent(pnlStatus);
    lbZeitbuchungen.Name := 'lbZeitbuchungen';
    lbZeitbuchungen.Left := 16;
    lbZeitbuchungen.Top := 6;
    lbZeitbuchungen.Width := 142;
    lbZeitbuchungen.Height := 23;
    lbZeitbuchungen.Caption := 'Zeitbuchungen';
    lbZeitbuchungen.Color := 16378331;
    lbZeitbuchungen.Font.Charset := DEFAULT_CHARSET;
    lbZeitbuchungen.Font.Color := clBlack;
    lbZeitbuchungen.Font.Height := -19;
    lbZeitbuchungen.Font.Name := 'Tahoma';
    lbZeitbuchungen.Font.Style := [fsBold];
    lbZeitbuchungen.HeightPercent := 100.000000000000000000;
    lbZeitbuchungen.ParentFont := False;
    lbZeitbuchungen.WidthPercent := 100.000000000000000000;
    btnBuchungDel.SetParentComponent(Self);
    btnBuchungDel.Name := 'btnBuchungDel';
    btnBuchungDel.Left := 433;
    btnBuchungDel.Top := 75;
    btnBuchungDel.Width := 92;
    btnBuchungDel.Height := 30;
    btnBuchungDel.Cursor := crHandPoint;
    btnBuchungDel.ParentDoubleBuffered := False;
    btnBuchungDel.DoubleBuffered := True;
    btnBuchungDel.Font.Charset := DEFAULT_CHARSET;
    btnBuchungDel.Font.Color := clWindowText;
    btnBuchungDel.Font.Height := -16;
    btnBuchungDel.Font.Name := 'Segoe UI';
    btnBuchungDel.Font.Style := [];
    btnBuchungDel.ParentColor := True;
    btnBuchungDel.TabOrder := 16;
    SetEvent(btnBuchungDel, Self, 'OnClick', 'btnBuchungDelClick');
    btnBuchungDel.Text := 'L'#246'schen';
    btnBuchungDel.Bitmaps.Clear;
    with btnBuchungDel.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Buchungen.btnBuchungDel.Bitmaps.Bitmap.png');
      BitmapName := 'UPD';
    end;
    btnBuchungDel.BitmapVisible := True;
    btnBuchungDel.ControlIndex := 0;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 564;
    WebPanel1.Width := 1137;
    WebPanel1.Height := 23;
    WebPanel1.Align := alBottom;
    WebPanel1.ChildOrder := 11;
    WebPanel1.Color := 16370864;
    WebPanel1.TabOrder := 17;
    btnBuNavPrev.SetParentComponent(Self);
    btnBuNavPrev.Name := 'btnBuNavPrev';
    btnBuNavPrev.Left := 535;
    btnBuNavPrev.Top := 75;
    btnBuNavPrev.Width := 33;
    btnBuNavPrev.Height := 30;
    btnBuNavPrev.Cursor := crHandPoint;
    btnBuNavPrev.ParentDoubleBuffered := False;
    btnBuNavPrev.DoubleBuffered := True;
    btnBuNavPrev.Font.Charset := DEFAULT_CHARSET;
    btnBuNavPrev.Font.Color := clWindowText;
    btnBuNavPrev.Font.Height := -11;
    btnBuNavPrev.Font.Name := 'Segoe UI';
    btnBuNavPrev.Font.Style := [];
    btnBuNavPrev.ParentColor := True;
    btnBuNavPrev.TabOrder := 18;
    SetEvent(btnBuNavPrev, Self, 'OnClick', 'Do_BuLaden');
    btnBuNavPrev.Text := '';
    btnBuNavPrev.Bitmaps.Clear;
    with btnBuNavPrev.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Buchungen.btnBuNavPrev.Bitmaps.Bitmap.png');
    end;
    btnBuNavPrev.BitmapVisible := True;
    btnBuNavPrev.ControlIndex := 0;
    btnBuNavNext.SetParentComponent(Self);
    btnBuNavNext.Name := 'btnBuNavNext';
    btnBuNavNext.Left := 570;
    btnBuNavNext.Top := 75;
    btnBuNavNext.Width := 33;
    btnBuNavNext.Height := 30;
    btnBuNavNext.Cursor := crHandPoint;
    btnBuNavNext.ParentDoubleBuffered := False;
    btnBuNavNext.DoubleBuffered := True;
    btnBuNavNext.Font.Charset := DEFAULT_CHARSET;
    btnBuNavNext.Font.Color := clWindowText;
    btnBuNavNext.Font.Height := -11;
    btnBuNavNext.Font.Name := 'Segoe UI';
    btnBuNavNext.Font.Style := [];
    btnBuNavNext.ParentColor := True;
    btnBuNavNext.TabOrder := 19;
    SetEvent(btnBuNavNext, Self, 'OnClick', 'Do_BuLaden');
    btnBuNavNext.Text := '';
    btnBuNavNext.Bitmaps.Clear;
    with btnBuNavNext.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Buchungen.btnBuNavNext.Bitmaps.Bitmap.png');
    end;
    btnBuNavNext.BitmapVisible := True;
    btnBuNavNext.ControlIndex := 0;
    btnBuNavInfo.SetParentComponent(Self);
    btnBuNavInfo.Name := 'btnBuNavInfo';
    btnBuNavInfo.Left := 606;
    btnBuNavInfo.Top := 75;
    btnBuNavInfo.Width := 94;
    btnBuNavInfo.Height := 30;
    btnBuNavInfo.Hint := 'Daten laden';
    btnBuNavInfo.ParentDoubleBuffered := False;
    btnBuNavInfo.DoubleBuffered := True;
    btnBuNavInfo.Font.Charset := DEFAULT_CHARSET;
    btnBuNavInfo.Font.Color := clWindowText;
    btnBuNavInfo.Font.Height := -11;
    btnBuNavInfo.Font.Name := 'Segoe UI';
    btnBuNavInfo.Font.Style := [];
    btnBuNavInfo.ParentColor := True;
    btnBuNavInfo.TabOrder := 20;
    SetEvent(btnBuNavInfo, Self, 'OnClick', 'btnLadenClick');
    btnBuNavInfo.Text := '1 - 50 [254]';
    btnBuNavInfo.ControlIndex := 0;
    btnNavPrev.SetParentComponent(Self);
    btnNavPrev.Name := 'btnNavPrev';
    btnNavPrev.Left := 8;
    btnNavPrev.Top := 40;
    btnNavPrev.Width := 33;
    btnNavPrev.Height := 30;
    btnNavPrev.Cursor := crHandPoint;
    btnNavPrev.ParentDoubleBuffered := False;
    btnNavPrev.DoubleBuffered := True;
    btnNavPrev.Font.Charset := DEFAULT_CHARSET;
    btnNavPrev.Font.Color := clWindowText;
    btnNavPrev.Font.Height := -11;
    btnNavPrev.Font.Name := 'Segoe UI';
    btnNavPrev.Font.Style := [];
    btnNavPrev.ParentColor := True;
    btnNavPrev.TabOrder := 21;
    SetEvent(btnNavPrev, Self, 'OnClick', 'Do_MaLaden');
    btnNavPrev.Text := '';
    btnNavPrev.Bitmaps.Clear;
    with btnNavPrev.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Buchungen.btnNavPrev.Bitmaps.Bitmap.png');
    end;
    btnNavPrev.BitmapVisible := True;
    btnNavPrev.ControlIndex := 0;
    btnNavNext.SetParentComponent(Self);
    btnNavNext.Name := 'btnNavNext';
    btnNavNext.Left := 43;
    btnNavNext.Top := 40;
    btnNavNext.Width := 33;
    btnNavNext.Height := 30;
    btnNavNext.Cursor := crHandPoint;
    btnNavNext.ParentDoubleBuffered := False;
    btnNavNext.DoubleBuffered := True;
    btnNavNext.Font.Charset := DEFAULT_CHARSET;
    btnNavNext.Font.Color := clWindowText;
    btnNavNext.Font.Height := -11;
    btnNavNext.Font.Name := 'Segoe UI';
    btnNavNext.Font.Style := [];
    btnNavNext.ParentColor := True;
    btnNavNext.TabOrder := 22;
    SetEvent(btnNavNext, Self, 'OnClick', 'Do_MaLaden');
    btnNavNext.Text := '';
    btnNavNext.Bitmaps.Clear;
    with btnNavNext.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Buchungen.btnNavNext.Bitmaps.Bitmap.png');
    end;
    btnNavNext.BitmapVisible := True;
    btnNavNext.ControlIndex := 0;
    btnNavInfo.SetParentComponent(Self);
    btnNavInfo.Name := 'btnNavInfo';
    btnNavInfo.Left := 79;
    btnNavInfo.Top := 40;
    btnNavInfo.Width := 94;
    btnNavInfo.Height := 30;
    btnNavInfo.Hint := 'Daten laden';
    btnNavInfo.ParentDoubleBuffered := False;
    btnNavInfo.DoubleBuffered := True;
    btnNavInfo.Font.Charset := DEFAULT_CHARSET;
    btnNavInfo.Font.Color := clWindowText;
    btnNavInfo.Font.Height := -11;
    btnNavInfo.Font.Name := 'Segoe UI';
    btnNavInfo.Font.Style := [];
    btnNavInfo.ParentColor := True;
    btnNavInfo.TabOrder := 23;
    SetEvent(btnNavInfo, Self, 'OnClick', 'btnNavInfoClick');
    btnNavInfo.Text := '1 - 50 [254]';
    btnNavInfo.ControlIndex := 0;
    grdMitarbeiter.SetParentComponent(Self);
    grdMitarbeiter.Name := 'grdMitarbeiter';
    grdMitarbeiter.Left := 8;
    grdMitarbeiter.Top := 78;
    grdMitarbeiter.Width := 321;
    grdMitarbeiter.Height := 469;
    grdMitarbeiter.Cursor := crHandPoint;
    grdMitarbeiter.Anchors := [akLeft,akTop,akBottom];
    grdMitarbeiter.Columns.Clear;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'MA_PERSNR';
      Title := 'Pers.-Nr';
      Width := 71;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'MA_NAME';
      Title := 'Zuname';
      Width := 139;
    end;
    with grdMitarbeiter.Columns.Add do
    begin
      DataField := 'MA_VNAME';
      Title := 'Vorname';
      Width := 94;
    end;
    grdMitarbeiter.DataSource := dsMitarbeiter;
    grdMitarbeiter.ElementFont := efCSS;
    grdMitarbeiter.FixedFont.Charset := DEFAULT_CHARSET;
    grdMitarbeiter.FixedFont.Color := clWindowText;
    grdMitarbeiter.FixedFont.Height := -12;
    grdMitarbeiter.FixedFont.Name := 'Segoe UI';
    grdMitarbeiter.FixedFont.Style := [];
    grdMitarbeiter.FixedCols := 0;
    grdMitarbeiter.Font.Charset := DEFAULT_CHARSET;
    grdMitarbeiter.Font.Color := clWindowText;
    grdMitarbeiter.Font.Height := -13;
    grdMitarbeiter.Font.Name := 'Segoe UI';
    grdMitarbeiter.Font.Style := [];
    grdMitarbeiter.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goColSizing,goColMoving,goRowSelect,goFixedRowDefAlign];
    grdMitarbeiter.ParentFont := False;
    grdMitarbeiter.TabOrder := 24;
    grdMitarbeiter.HeightPercent := 100.000000000000000000;
    grdMitarbeiter.WidthPercent := 100.000000000000000000;
    SetEvent(grdMitarbeiter, Self, 'OnDblClick', 'grdMitarbeiterDblClick');
    grdMitarbeiter.ColWidths[0] := 71;
    grdMitarbeiter.ColWidths[1] := 139;
    grdMitarbeiter.ColWidths[2] := 94;
    edtMitarbeiter.SetParentComponent(Self);
    edtMitarbeiter.Name := 'edtMitarbeiter';
    edtMitarbeiter.Left := 335;
    edtMitarbeiter.Top := 43;
    edtMitarbeiter.Width := 329;
    edtMitarbeiter.Height := 29;
    edtMitarbeiter.Alignment := taCenter;
    edtMitarbeiter.AutoSize := False;
    edtMitarbeiter.Font.Charset := DEFAULT_CHARSET;
    edtMitarbeiter.Font.Color := clWindowText;
    edtMitarbeiter.Font.Height := -16;
    edtMitarbeiter.Font.Name := 'Segoe UI';
    edtMitarbeiter.Font.Style := [fsBold];
    edtMitarbeiter.ParentColor := True;
    edtMitarbeiter.ParentFont := False;
    edtMitarbeiter.ReadOnly := True;
    edtMitarbeiter.TabOrder := 25;
    edtMitarbeiter.Text := '---';
    edtMitarbeiter.Lookup.Separator := ';';
    edtMitarbeiter.ValidChars := '1234567890';
    btnEntfMaFilter.SetParentComponent(Self);
    btnEntfMaFilter.Name := 'btnEntfMaFilter';
    btnEntfMaFilter.Left := 668;
    btnEntfMaFilter.Top := 42;
    btnEntfMaFilter.Width := 32;
    btnEntfMaFilter.Height := 30;
    btnEntfMaFilter.Cursor := crHandPoint;
    btnEntfMaFilter.ParentDoubleBuffered := False;
    btnEntfMaFilter.DoubleBuffered := True;
    btnEntfMaFilter.Font.Charset := DEFAULT_CHARSET;
    btnEntfMaFilter.Font.Color := clWindowText;
    btnEntfMaFilter.Font.Height := -16;
    btnEntfMaFilter.Font.Name := 'Segoe UI';
    btnEntfMaFilter.Font.Style := [];
    btnEntfMaFilter.ParentColor := True;
    btnEntfMaFilter.TabOrder := 26;
    SetEvent(btnEntfMaFilter, Self, 'OnClick', 'btnEntfMaFilterClick');
    btnEntfMaFilter.Text := '';
    btnEntfMaFilter.TextVisible := False;
    btnEntfMaFilter.Bitmaps.Clear;
    with btnEntfMaFilter.Bitmaps.Add do
    begin
      Bitmap.LoadFromFile('Buchungen.btnEntfMaFilter.Bitmaps.Bitmap.png');
      BitmapName := 'UPD';
    end;
    btnEntfMaFilter.BitmapVisible := True;
    btnEntfMaFilter.ControlIndex := 0;
    dsZeitBuchung.SetParentComponent(Self);
    dsZeitBuchung.Name := 'dsZeitBuchung';
    dsZeitBuchung.DataSet := tabZeitBuchung;
    dsZeitBuchung.Left := 496;
    dsZeitBuchung.Top := 248;
    XDataWebClient.SetParentComponent(Self);
    XDataWebClient.Name := 'XDataWebClient';
    SetEvent(XDataWebClient, Self, 'OnLoad', 'XDataWebClientLoad');
    XDataWebClient.Left := 436;
    XDataWebClient.Top := 408;
    conBuchungen.SetParentComponent(Self);
    conBuchungen.Name := 'conBuchungen';
    conBuchungen.Active := False;
    conBuchungen.Headers.BeginUpdate;
    try
      conBuchungen.Headers.Clear;
      conBuchungen.Headers.Add('PASSWORD=TESTERLE');
    finally
      conBuchungen.Headers.EndUpdate;
    end;
    conBuchungen.Delimiter := ';';
    conBuchungen.Password := 'test';
    conBuchungen.SkipFirstCSVLine := True;
    conBuchungen.URI := 'http://localhost:2001/tms/xdata/SystemService/Get_Daten?sSQL=SELECT';
    SetEvent(conBuchungen, Self, 'OnDataReceived', 'conBuchungenDataReceived');
    SetEvent(conBuchungen, Self, 'OnGetDataPayload', 'conBuchungenGetDataPayload');
    SetEvent(conBuchungen, Self, 'OnProcessMetaData', 'conBuchungenProcessMetaData');
    conBuchungen.Left := 126;
    conBuchungen.Top := 448;
    tabBuchungen.SetParentComponent(Self);
    tabBuchungen.Name := 'tabBuchungen';
    tabBuchungen.Active := True;
    tabBuchungen.Connection := conBuchungen;
    tabBuchungen.AfterOpen := tabBuchungenAfterOpen;
    tabBuchungen.Left := 216;
    tabBuchungen.Top := 448;
    WebDataSource1.SetParentComponent(Self);
    WebDataSource1.Name := 'WebDataSource1';
    WebDataSource1.DataSet := tabBuchungen;
    WebDataSource1.Left := 168;
    WebDataSource1.Top := 504;
    tabZeitBuchung.SetParentComponent(Self);
    tabZeitBuchung.Name := 'tabZeitBuchung';
    tabZeitBuchung.Left := 496;
    tabZeitBuchung.Top := 176;
    tabZeitBuchungMA_NR.SetParentComponent(tabZeitBuchung);
    tabZeitBuchungMA_NR.Name := 'tabZeitBuchungMA_NR';
    tabZeitBuchungMA_NR.Alignment := taCenter;
    tabZeitBuchungMA_NR.DisplayLabel := 'MaNr';
    tabZeitBuchungMA_NR.DisplayWidth := 6;
    tabZeitBuchungMA_NR.FieldName := 'MA_NR';
    tabZeitBuchungMA_NR.Required := True;
    tabZeitBuchungMA_NAME.SetParentComponent(tabZeitBuchung);
    tabZeitBuchungMA_NAME.Name := 'tabZeitBuchungMA_NAME';
    tabZeitBuchungMA_NAME.DisplayLabel := 'Zuname';
    tabZeitBuchungMA_NAME.DisplayWidth := 25;
    tabZeitBuchungMA_NAME.FieldName := 'MA_NAME';
    tabZeitBuchungMA_NAME.Required := True;
    tabZeitBuchungMA_NAME.Size := 30;
    tabZeitBuchungMA_VNAME.SetParentComponent(tabZeitBuchung);
    tabZeitBuchungMA_VNAME.Name := 'tabZeitBuchungMA_VNAME';
    tabZeitBuchungMA_VNAME.DisplayLabel := 'Vorname';
    tabZeitBuchungMA_VNAME.DisplayWidth := 20;
    tabZeitBuchungMA_VNAME.FieldName := 'MA_VNAME';
    tabZeitBuchungMA_VNAME.Size := 30;
    tabZeitBuchungBU_TISTAMP.SetParentComponent(tabZeitBuchung);
    tabZeitBuchungBU_TISTAMP.Name := 'tabZeitBuchungBU_TISTAMP';
    tabZeitBuchungBU_TISTAMP.DisplayLabel := 'Zeitstempel';
    tabZeitBuchungBU_TISTAMP.DisplayWidth := 15;
    tabZeitBuchungBU_TISTAMP.FieldName := 'BU_TISTAMP';
    tabZeitBuchungANWABW_BEZ.SetParentComponent(tabZeitBuchung);
    tabZeitBuchungANWABW_BEZ.Name := 'tabZeitBuchungANWABW_BEZ';
    tabZeitBuchungANWABW_BEZ.DisplayLabel := 'Buchungsgrund';
    tabZeitBuchungANWABW_BEZ.DisplayWidth := 20;
    tabZeitBuchungANWABW_BEZ.FieldName := 'ANWABW_BEZ';
    tabZeitBuchungANWABW_BEZ.Size := 30;
    tabZeitBuchungID_BU_DAT.SetParentComponent(tabZeitBuchung);
    tabZeitBuchungID_BU_DAT.Name := 'tabZeitBuchungID_BU_DAT';
    tabZeitBuchungID_BU_DAT.Alignment := taCenter;
    tabZeitBuchungID_BU_DAT.DisplayLabel := 'ID';
    tabZeitBuchungID_BU_DAT.DisplayWidth := 6;
    tabZeitBuchungID_BU_DAT.FieldName := 'ID_BU_DAT';
    tabZeitBuchungANWABW_NR.SetParentComponent(tabZeitBuchung);
    tabZeitBuchungANWABW_NR.Name := 'tabZeitBuchungANWABW_NR';
    tabZeitBuchungANWABW_NR.FieldName := 'ANWABW_NR';
    tabZeitBuchungFREIFELD.SetParentComponent(tabZeitBuchung);
    tabZeitBuchungFREIFELD.Name := 'tabZeitBuchungFREIFELD';
    tabZeitBuchungFREIFELD.DisplayLabel := 'Freifeld';
    tabZeitBuchungFREIFELD.DisplayWidth := 20;
    tabZeitBuchungFREIFELD.FieldName := 'FREIFELD';
    XDataWebDataSet1.SetParentComponent(Self);
    XDataWebDataSet1.Name := 'XDataWebDataSet1';
    XDataWebDataSet1.EntitySetName := 'ABSCHLUSS';
    XDataWebDataSet1.Left := 328;
    XDataWebDataSet1.Top := 368;
    tabMitarbeiter.SetParentComponent(Self);
    tabMitarbeiter.Name := 'tabMitarbeiter';
    tabMitarbeiter.Left := 96;
    tabMitarbeiter.Top := 184;
    tabMitarbeiterMA_NR.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_NR.Name := 'tabMitarbeiterMA_NR';
    tabMitarbeiterMA_NR.Alignment := taCenter;
    tabMitarbeiterMA_NR.DisplayLabel := 'MaNr';
    tabMitarbeiterMA_NR.DisplayWidth := 6;
    tabMitarbeiterMA_NR.FieldName := 'MA_NR';
    tabMitarbeiterMA_NR.Required := True;
    tabMitarbeiterMA_NAME.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_NAME.Name := 'tabMitarbeiterMA_NAME';
    tabMitarbeiterMA_NAME.DisplayLabel := 'Zuname';
    tabMitarbeiterMA_NAME.DisplayWidth := 25;
    tabMitarbeiterMA_NAME.FieldName := 'MA_NAME';
    tabMitarbeiterMA_NAME.Required := True;
    tabMitarbeiterMA_NAME.Size := 30;
    tabMitarbeiterMA_VNAME.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_VNAME.Name := 'tabMitarbeiterMA_VNAME';
    tabMitarbeiterMA_VNAME.DisplayLabel := 'Vorname';
    tabMitarbeiterMA_VNAME.DisplayWidth := 20;
    tabMitarbeiterMA_VNAME.FieldName := 'MA_VNAME';
    tabMitarbeiterMA_VNAME.Size := 30;
    tabMitarbeiterMA_PERSNR.SetParentComponent(tabMitarbeiter);
    tabMitarbeiterMA_PERSNR.Name := 'tabMitarbeiterMA_PERSNR';
    tabMitarbeiterMA_PERSNR.FieldName := 'MA_PERSNR';
    dsMitarbeiter.SetParentComponent(Self);
    dsMitarbeiter.Name := 'dsMitarbeiter';
    dsMitarbeiter.DataSet := tabMitarbeiter;
    dsMitarbeiter.Left := 192;
    dsMitarbeiter.Top := 240;
  finally
    lbDecryptedText.AfterLoadDFMValues;
    lbEncryptedText.AfterLoadDFMValues;
    btnKOMMT.AfterLoadDFMValues;
    btnGEHT.AfterLoadDFMValues;
    grdZeitBuchung.AfterLoadDFMValues;
    mLog.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    aFileUpload.AfterLoadDFMValues;
    aListBox.AfterLoadDFMValues;
    edDecryptedText.AfterLoadDFMValues;
    edEncryptedText.AfterLoadDFMValues;
    btEncrypt.AfterLoadDFMValues;
    btDecrypt.AfterLoadDFMValues;
    edtTemp.AfterLoadDFMValues;
    btnGP.AfterLoadDFMValues;
    btnKP.AfterLoadDFMValues;
    btnBuchungEdit.AfterLoadDFMValues;
    pnlStatus.AfterLoadDFMValues;
    lbZeitbuchungen.AfterLoadDFMValues;
    btnBuchungDel.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    btnBuNavPrev.AfterLoadDFMValues;
    btnBuNavNext.AfterLoadDFMValues;
    btnBuNavInfo.AfterLoadDFMValues;
    btnNavPrev.AfterLoadDFMValues;
    btnNavNext.AfterLoadDFMValues;
    btnNavInfo.AfterLoadDFMValues;
    grdMitarbeiter.AfterLoadDFMValues;
    edtMitarbeiter.AfterLoadDFMValues;
    btnEntfMaFilter.AfterLoadDFMValues;
    dsZeitBuchung.AfterLoadDFMValues;
    XDataWebClient.AfterLoadDFMValues;
    conBuchungen.AfterLoadDFMValues;
    tabBuchungen.AfterLoadDFMValues;
    WebDataSource1.AfterLoadDFMValues;
    tabZeitBuchung.AfterLoadDFMValues;
    tabZeitBuchungMA_NR.AfterLoadDFMValues;
    tabZeitBuchungMA_NAME.AfterLoadDFMValues;
    tabZeitBuchungMA_VNAME.AfterLoadDFMValues;
    tabZeitBuchungBU_TISTAMP.AfterLoadDFMValues;
    tabZeitBuchungANWABW_BEZ.AfterLoadDFMValues;
    tabZeitBuchungID_BU_DAT.AfterLoadDFMValues;
    tabZeitBuchungANWABW_NR.AfterLoadDFMValues;
    tabZeitBuchungFREIFELD.AfterLoadDFMValues;
    XDataWebDataSet1.AfterLoadDFMValues;
    tabMitarbeiter.AfterLoadDFMValues;
    tabMitarbeiterMA_NR.AfterLoadDFMValues;
    tabMitarbeiterMA_NAME.AfterLoadDFMValues;
    tabMitarbeiterMA_VNAME.AfterLoadDFMValues;
    tabMitarbeiterMA_PERSNR.AfterLoadDFMValues;
    dsMitarbeiter.AfterLoadDFMValues;
  end;
end;

end.

